/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import YouTube from 'react-youtube';
//import { init } from "_config/ApiConfig";
//import ToggleVisibilityNavigation from "components/ToggleVisibility/ToggleVisibilityNavigation.js"

//import SectionConect from "views/ConectPage/Sections/SectionConect.js";//A REMETTRE POUR CREER UN ACCES PRIVE
//import SectionQuestionnaire from "views/QuestionnairePage/Sections/SectionQuestionnaire.js";//A REMETTRE POUR CREER UN ACCES PRIVE


//import SectionSearchGroup from "views/PresentationPage/Sections/SectionSearchGroup.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import "./style.css";
//init();

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    //scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    targetScroll.scrollIntoView({ block: 'start' });
  };

  const video1 = useRef(null);
  const video2 = useRef(null);
  const video3 = useRef(null);
  const video4 = useRef(null);
  const video5 = useRef(null);
  const video6 = useRef(null);
  const video7 = useRef(null);
  const video8 = useRef(null);
  const video9 = useRef(null);
  const video10 = useRef(null);
  const video11 = useRef(null);


  const onPlayVideo1 = () => {
    if (video2.current) {
      video2.current.internalPlayer.pauseVideo();
    }
    if (video3.current) {
      video3.current.internalPlayer.pauseVideo();
    }
    if (video4.current) {
      video4.current.internalPlayer.pauseVideo();
    }
    if (video5.current) {
      video5.current.internalPlayer.pauseVideo();
    }
    if (video6.current) {
      video6.current.internalPlayer.pauseVideo();
    }
  };

  const onPlayVideo2 = () => {
    if (video1.current) {
      video1.current.internalPlayer.pauseVideo();
    }
    if (video3.current) {
      video3.current.internalPlayer.pauseVideo();
    }
    if (video4.current) {
      video4.current.internalPlayer.pauseVideo();
    }
    if (video5.current) {
      video5.current.internalPlayer.pauseVideo();
    }
    if (video6.current) {
      video6.current.internalPlayer.pauseVideo();
    }
  };
  const onPlayVideo3 = () => {
    if (video1.current) {
      video1.current.internalPlayer.pauseVideo();
    }
    if (video2.current) {
      video2.current.internalPlayer.pauseVideo();
    }
    if (video4.current) {
      video4.current.internalPlayer.pauseVideo();
    }
    if (video5.current) {
      video5.current.internalPlayer.pauseVideo();
    }
    if (video6.current) {
      video6.current.internalPlayer.pauseVideo();
    }
  };

  const onPlayVideo4 = () => {
    if (video1.current) {
      video1.current.internalPlayer.pauseVideo();
    }
    if (video2.current) {
      video2.current.internalPlayer.pauseVideo();
    }
    if (video3.current) {
      video3.current.internalPlayer.pauseVideo();
    }
    if (video5.current) {
      video5.current.internalPlayer.pauseVideo();
    }
    if (video6.current) {
      video6.current.internalPlayer.pauseVideo();
    }
  };

  const onPlayVideo5 = () => {
    if (video1.current) {
      video1.current.internalPlayer.pauseVideo();
    }
    if (video2.current) {
      video2.current.internalPlayer.pauseVideo();
    }
    if (video3.current) {
      video3.current.internalPlayer.pauseVideo();
    }
    if (video4.current) {
      video4.current.internalPlayer.pauseVideo();
    }
    if (video6.current) {
      video6.current.internalPlayer.pauseVideo();
    }
  };
  const onPlayVideo6 = () => {
    if (video1.current) {
      video1.current.internalPlayer.pauseVideo();
    }
    if (video2.current) {
      video2.current.internalPlayer.pauseVideo();
    }
    if (video3.current) {
      video3.current.internalPlayer.pauseVideo();
    }
    if (video4.current) {
      video4.current.internalPlayer.pauseVideo();
    }
    if (video5.current) {
      video5.current.internalPlayer.pauseVideo();
    }
  };

  const onPlayVideo7 = () => {
   
  };
  const onPlayVideo8 = () => {
   
  };
  const onPlayVideo9 = () => {
   
  };
  const onPlayVideo10 = () => {
   
  };
  const onPlayVideo11 = () => {
   
  };

 // https://www.youtube.be/embed/YLqPyNuiBRs?version=3&loop=2&rel=0&controls=1&showinfo=0
  const options = {
    height: '390',
    width: '100',
    playerVars: {
      autoplay: 1,
      controls: 2,
      showinfo: 0,
      modestbranding: 1,
      loop: 0,
      reel: 0,
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /*   React.useEffect(() => {  
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }); */

  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      {/* <Parallax
        image={require("assets/img/competence.png")}
        className={classes.parallax}
      > */}
        <div className={classes.container}>
        <div className='video-container'>
  <YouTube ref={video1} videoId="hfBYz96AV_c" options={options} onPlay={onPlayVideo1} />
  </div>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.shadowForText}>
                  BILAN DE COMPETENCES
                </h1>
                <h2 className={classNames(classes.title, classes.shadowForText)}>
                  Commencez le questionnaire !</h2>
                {/* <h3 className={classNames(classes.title, classes.shadowForSmallText)}>Connectez-vous</h3> */}
                {/*  <ToggleVisibilityNavigation /> */}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>              
               <Card>
                  <CardHeader
                    color="info"
                    
                    className={classes.cardHeader}
                  >

<div className='video-container'>
  <YouTube ref={video1} videoId="hfBYz96AV_c" options={options} onPlay={onPlayVideo1} />
  </div>

           {/*  <YouTube ref={video2} videoId="0YmIAWPHcDM" options={options} onPlay={onPlayVideo2} />
            <YouTube ref={video3} videoId="YFRvxPGbW5Y" options={options} onPlay={onPlayVideo3} />
            <YouTube ref={video4} videoId="St7xhukaZWk" options={options} onPlay={onPlayVideo4} />
            <YouTube ref={video5} videoId="laBsKnntcZQ" options={options} onPlay={onPlayVideo5} />
            <YouTube ref={video6} videoId="2DvaYRkUvpo" options={options} onPlay={onPlayVideo6} />

            <YouTube ref={video7} videoId="Vw1POVESZGA" options={options} onPlay={onPlayVideo7} />
            <YouTube ref={video8} videoId="hRfGu65DW7U" options={options} onPlay={onPlayVideo8} />
            <YouTube ref={video9} videoId="OQFRHTsQP_k" options={options} onPlay={onPlayVideo9} />
            <YouTube ref={video10} videoId="SBTDnWBUZik" options={options} onPlay={onPlayVideo10} />
            <YouTube ref={video11} videoId="Gc8oDVdq0bk" options={options} onPlay={onPlayVideo11} /> */}










          
          </CardHeader>
          </Card>
          </GridItem>
          </GridContainer>
        </div>
    {/*   </Parallax> */}
      <br /><br /><br /><br />
      {/*    <SectionQuestionnaire />
 

     <SectionSearchGroup /> */}

      <Footer
        className={classes.footer}
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/"
                    className={classes.block}
                  >
                    BILAN DE COMPTENCES                  </a>
                </ListItem>

                {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , {" "} <a
                href="https://www.artwebprod.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tous droits réservés
              </a>
            </div>
          </div>
        }
      />
    </div>
  );
}
