import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { Link } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';


// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Accordion from "components/Accordion/Accordion.js";
import SyntheseProfessionnelRecap from "views/InteretProfessionnelPage/SyntheseProfessionnelRecap.js";

import NavPills from "components/NavPills/NavPills.js";
//import styled from 'styled-components';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { makeStyles } from "@material-ui/core/styles";
import AttachFile from "@material-ui/icons/AttachFile";

// @material-ui/icons
//import Check from "@material-ui/icons/Check";
//import Warning from "@material-ui/icons/Warning";
//import ArrowForwardIcon from '@material-ui/icons/ArrowDownwardOutlined';

//import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
//import AddIcon from "@material-ui/icons/Add";
//import Gesture from "@material-ui/icons/Gesture";
//import AccountCircle from "@material-ui/icons/AccountCircle";// core components
//import Clearfix from "components/Clearfix/Clearfix.js";
//import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";


//import Button from "components/CustomButtons/Button.js";
import image from "assets/img/bg7.jpg";
//const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(signupPageStyle);


export default function InteretsPro() {

  const classes = useStyles();
  const history = useHistory();
  const [isRecordCount1, setRecordCount1] = useState(0);
  const [isRecordCount2, setRecordCount2] = useState(0);
  const [isRecordCount3, setRecordCount3] = useState(0);
  const [isRecordCount4, setRecordCount4] = useState(0);

  const checkPortefeuillePro = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetSavePortefeuilleProfessionnel.php`, {
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
            if (res.data.success === true) {
            setRecordCount1(res.data.RecordCount1);
            setRecordCount2(res.data.RecordCount2);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const checkSynthese = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveSynthese.php`, {
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setRecordCount3(res.data.RecordCount3);
            setRecordCount4(res.data.RecordCount4);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
    checkPortefeuillePro();
    checkSynthese();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        {isRecordCount1 > 0 && isRecordCount2 > 0 && isRecordCount3 > 0 && isRecordCount4 > 0 ? (<>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes.cardSignup}>
                  <NavPills
                    alignCenter
                    color="info"
                    tabs={[
                      {
                        tabButton: "Plan d'action",
                      }
                    ]}
                  />
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        <div className={classes.title}>
                          <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}> Vous avez pris le temps de réfléchir à votre désir de reconversion et soigneusement répondu aux questions à se poser en amont ?  Il est temps de penser aux actions à mettre en place et de continuer à mûrir tranquillement votre projet.

                          </h4>
                          <SnackbarContent
                            style={{ backgroundColor: "#af2cc5" }}
                            message="Etapes"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem>
                        <div>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            - Compte tenu des éléments précédents, il faut à présent définir les grandes étapes de votre/vos projet(s).</p>

                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            - Cette étape sera votre feuille de route pour la réalisation de votre/vos projet(s).</p>

                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            - Voici une liste non exhaustive pour élaborer votre plan d’action : </p>

                          <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>1.	Recherche d'informations :</h4>

                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            <ul>
                              <li>
                                Renseignez-vous sur les perspectives d'emploi dans les domaines qui vous intéressent.
                              </li>
                              <li>
                                Utiliser des ressources telles que les sites web spécialisés, les réseaux professionnels, les salons de l'emploi, etc.
                              </li>
                            </ul>
                          </p>
                          <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>2.	Formation ou acquisition de compétences :</h4>

                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            <ul>
                              <li>
                                Envisager des programmes de formation, des cours en ligne, des certifications ou des études universitaires pour acquérir les compétences nécessaires à votre nouvelle carrière.
                              </li>
                              <li>
                                Recherchez un organisme pouvant vous accompagner dans le financement de la formation.
                              </li>
                              <li>
                                Rencontrer des organismes de formation.
                              </li>
                            </ul>
                          </p>
                          <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>3.	Établir un réseau professionnel :</h4>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            <ul>
                              <li>
                                Rencontrer des personnes travaillant dans le domaine qui vous intéresse.
                              </li>
                              <li>
                                Participer à des événements de réseautage, des conférences ou des ateliers.
                              </li>
                              <li>
                                Utiliser les médias sociaux professionnels comme LinkedIn pour élargir votre réseau.
                              </li>
                            </ul>
                          </p>

                          <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>4.	Préparer vos outils de recherche d'emploi :</h4>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            <ul>
                              <li>
                                Mettre à jour votre CV pour mettre en valeur vos compétences et expériences pertinentes.
                              </li>
                              <li>
                                Créer un profil LinkedIn attrayant et complet.
                              </li>
                              <li>
                                Rédiger des lettres de motivation adaptées aux postes auxquels vous postulez.
                              </li>
                            </ul>
                          </p>
                          <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>5.	Recherche d'opportunités professionnelles :</h4>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            <ul>
                              <li>
                                Rechercher des offres d'emploi dans votre domaine cible.
                              </li>
                              <li>
                                Utiliser des sites web d'emploi, contacter des agences de recrutement spécialisées, et explorer les offres sur les réseaux sociaux professionnels.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <hr></hr>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.title}>
                          <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Modèle de Plan d'Action Professionnel :</h4>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Voici un modèle en annexe de plan d'action professionnel sous forme de tableau.<br></br>Ce modèle vous aidera à structurer les étapes nécessaires pour atteindre vos objectifs professionnels.</p>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>1 - Définir les étapes.</p>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>2 - Détailler au maximum les étapes.</p>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>3 - Intégrer une chronologie aux étapes à réaliser.</p>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>4 - Associer des temps de réalisation aux différentes étapes.</p>
                          <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>5- Vous pouvez vous utiliser ce tableau comme modèle et créer votre propre plan d’action.
                            <br></br>
                            <a className={classNames(classes.titleBlue, classes.shadowForSmallText)} href="https://bdc.aimezvous.fr/annexes/Plan-Action.pdf" target="_blank" >
                              <span className={classNames(classes.titleWarning, classes.shadowForSmallText)}>ANNEXE-PLAN D'ACTION</span>
                              <AttachFile /></a> </h5>
                        </div>
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Explications des colonnes :</h4>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                          <ol>
                            <li>
                              Objectif Professionnel : Définir l'objectif spécifique que vous souhaitez atteindre (exemple : devenir développeur web).
                            </li>
                            <li>
                              Étapes : Décomposer l'objectif en étapes concrètes et séquentielles.
                            </li>
                            <li>
                              Actions Spécifiques : Détail des actions précises à réaliser pour chaque étape.
                            </li>
                            <li>
                              Ressources Nécessaires : Identifier les ressources (matérielles, financières, humaines) nécessaires pour chaque action.
                            </li>
                            <li>
                              Délai : Fixer une échéance réaliste pour l'accomplissement de chaque étape.
                            </li>
                            <li>
                              Indicateurs de Succès : Définir les critères qui permettront de mesurer le succès de chaque étape (exemple : obtention d'un certificat, réalisation d'un projet).
                            </li>
                            <li>
                              Statut : Suivre l'avancement de chaque étape (en cours, terminé, pas commencé).
                            </li>
                          </ol>
                        </p>
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Utilisation du Modèle :</h4>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                          <ul>
                            <li>
                              Personnalisation : Adaptez ce modèle en fonction de vos objectifs professionnels spécifiques.
                            </li>
                            <li>
                              Suivi Régulier : Mettez à jour le statut régulièrement pour suivre vos progrès.
                            </li>
                            <li>
                              Évaluation : Utilisez les indicateurs de succès pour évaluer l'efficacité de votre plan d'action et ajuster si nécessaire.
                            </li>
                            <li>
                              Ce modèle de tableau vous offre une vue d'ensemble claire et structurée de votre plan d'action professionnel, facilitant ainsi la réalisation de vos objectifs.
                            </li>
                          </ul>
                        </p>
                      </GridItem>
                    </GridContainer>
                    <SyntheseProfessionnelRecap />
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className={classes.textCenter}>
                      <h6 className={classes.textInfo}>J'accède à la conclusion de mon bilan de compétences en cliquant sur le bouton ci-dessous...</h6>
                      <Link to="/conclusion-page">
                        <Button type="submit" size="sm" round color="info"
                          endIcon={<ArrowForwardIcon />}
                        > Conclusion</Button>
                      </Link>

                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>


        </>

        ) : (
          <>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <Card className={classes.cardSignup}>
                    <NavPills
                      alignCenter
                      color="info"
                      tabs={[
                        {
                          tabButton: "Plan d'action",
                        }
                      ]}
                    />
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={classes.title}>
                            <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}> Vous devez compléter certaines informations pour définir votre plan d'action, merci de revenir sur les onglets précédents.

                            </h4>

                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </>
        )
        }



        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};


