import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import SnackbarContent from '@material-ui/core/SnackbarContent';

// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AttachFile from "@material-ui/icons/AttachFile";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import Info from "components/Typography/Info.js";

//import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';

import styled from 'styled-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Check from "@material-ui/icons/Check";
//import Warning from "@material-ui/icons/Warning";
//import ArrowForwardIcon from '@material-ui/icons/ArrowDownwardOutlined';

//import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
//import AddIcon from "@material-ui/icons/Add";
//import Gesture from "@material-ui/icons/Gesture";
//import AccountCircle from "@material-ui/icons/AccountCircle";// core components
//import Clearfix from "components/Clearfix/Clearfix.js";
//import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";


//import Button from "components/CustomButtons/Button.js";
import image from "assets/img/bg7.jpg";
const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(signupPageStyle);

export default function InteretsPro() {

  const classes = useStyles();
  const history = useHistory();

  const [checked, setChecked] = useState([]);//
  const [checkedCap, setCheckedCap] = useState([]);

  const [isPortefeuilleCompetence, setPortefeuilleCompetence] = useState(false);

  const [isCheckVerbeAction, setCheckVerbeAction] = useState('.');

  const [isCheckSavoirFaire, setCheckSavoirFaire] = useState('.');

  const [isMessage, setMessage] = useState([]);
  const [isMessage2, setMessage2] = useState([]);

  //console.log(checked);//le tableau qui collecte les idIpfSelect

  const [count, setCount] = useState(0);//le compteur

  const handleCheckboxAddCount = () => {
    setCount(count + 1);
  };

  const handleCheckBoxRemoveCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      //handleCheckboxAddCount();
    } else {
      newChecked.splice(currentIndex, 1);
      //handleCheckBoxRemoveCount();
    }
    setChecked(newChecked);
    // console.log(currentIndex)//montre le choix coché ou nom
    console.log(newChecked)//le tableau qui collecte les idIpfSelect
  };

  const handleToggleCap = value => {
    const currentIndex = checkedCap.indexOf(value);
    const newCheckedCap = [...checkedCap];

    if (currentIndex === -1) {
      newCheckedCap.push(value);
      //handleCheckboxAddCount();
    } else {
      newCheckedCap.splice(currentIndex, 1);
      //handleCheckBoxRemoveCount();
    }
    setCheckedCap(newCheckedCap);
    // console.log(currentIndex)//montre le choix coché ou nom
    console.log(newCheckedCap)//le tableau qui collecte les idIpfSelect
  };

  const recordAllCapacite = async () => {
    /*CATALOGUE*/
    console.log(checked)
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveSelectionCapacite.php`, {
        // idIpf: idIpf,
        arrayIdCap: checked,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            // console.log('enregistré ok');
            checkVerbeAction();//on regarde si il y a des enregistrement           
          }
          else {
            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const checkVerbeAction = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveActionSavoir.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        id_cap: 1,
      })
        .then(res => {
          if (res.data.success === true) {
            setCheckVerbeAction('');
          }
          else {
            setCheckVerbeAction('Vous devez indiquer vos capacités');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const checkSaveSavoiFaire = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveActionSavoir.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        id_cap: 1,
      })
        .then(res => {
          if (res.data.success === true) {
            setMessage('Vous avez déjà renseigné ce questionnaire sur vos savoir-faire')
          }
          else {
            setMessage('');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const checkSaveSavoirEtre = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveActionSavoir.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        id_cap: 2,
      })
        .then(res => {
          if (res.data.success === true) {
            setMessage2('Vous avez déjà renseigné ce questionnaire sur vos savoir-être')
          }
          else {
            setMessage2('');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const recordAllCapacite2 = async () => {

    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveSelectionCapacite.php`, {
        // idIpf: idIpf,
        arrayIdCap: checkedCap,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            checkSavoirFaire();

          }
          else {

            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const checkSavoirFaire = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveActionSavoir.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        id_cap: 2,
      })
        .then(res => {
          if (res.data.success === true) {
            setCheckSavoirFaire('');
            setPortefeuilleCompetence(true)
          }
          else {
            setCheckSavoirFaire('Vous devez définir vos savoir-être');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const [numbers, setNumbers] = useState([
    { color: "#ffa21a", qualite: "DECIDER" },
    { color: "#00d3ee", qualite: "GERER" },
    { color: "#af2cc5", qualite: "DIRIGER" },
    { color: "#5cb860", qualite: "ADMINISTRER" },
    { color: "#f55a4e", qualite: "PRODUIRE" },
    { color: "#f78da7CD", qualite: "ORGANISER" },
    { color: "#ffa21a", qualite: "COMMUNIQUER" },
    { color: "#00d3ee", qualite: "DEVELOPPER" },
    { color: "#af2cc5", qualite: "CHERCHER" },
    { color: "#5cb860", qualite: "FORMER" },
    { color: "#f55a4e", qualite: "CONTROLER" },
    { color: "#f78da7", qualite: "CREER" },
    { color: "#f55a4e", qualite: "NEGOCIER" },
    { color: "#f78da7", qualite: "CONSEILLER" },
  ]);



  //const firstIndex = useMemo(() => numbers[0], [numbers]);

  /*const sortNumbers = () => {
   let sortedNumbers = [...numbers];
   sortedNumbers.sort((a, b) => b.value - a.value);
   setNumbers(sortedNumbers);
  // resultatInteretPro(sortedNumbers[0].letter);
// resultatInteretPro2(sortedNumbers[1].letter);

 };
 const resetInformation = () => {
   try {
     Axios.defaults.withCredentials = true;
     Axios.get(`${ApiConfig.baseUrl}/Controls/ResetInformation.php`)
       .then(res => {
         if (res.data.success === true) {
           // refreshPage();
           return;
         }
       })
   } catch (error) { throw error; }
 };*/
  // const [isInteretProfessionnel, setInteretProfessionnel] = useState([]);
  // const [isInteretProfessionnel2, setInteretProfessionnel2] = useState([]);


  //const [isTexte1, setTexte1] = useState([]);
  const [isCapactity1, setCapactity1] = useState([]);
  const [isCapactity2, setCapactity2] = useState([]);
  const [isCapactity3, setCapactity3] = useState([]);
  const [isCapactity4, setCapactity4] = useState([]);
  const [isCapactity5, setCapactity5] = useState([]);
  const [isCapactity6, setCapactity6] = useState([]);
  const [isCapactity7, setCapactity7] = useState([]);
  const [isCapactity8, setCapactity8] = useState([]);
  const [isCapactity9, setCapactity9] = useState([]);
  const [isCapactity10, setCapactity10] = useState([]);
  const [isCapactity11, setCapactity11] = useState([]);
  const [isCapactity12, setCapactity12] = useState([]);
  const [isCapactity13, setCapactity13] = useState([]);
  const [isCapactity14, setCapactity14] = useState([]);

  const [isCapaciteCap, setCapaciteCap] = useState([]);




  const resultatInteretPro = (idLetter) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInteretProfessionnel.php`, {
        idLetter: idLetter,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            //setInteretProfessionnel(res.data.interetProData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const resultatInteretPro2 = (idLetter) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInteretProfessionnel.php`, {
        idLetter: idLetter,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            //setInteretProfessionnel2(res.data.interetProData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getCapactity = async (idnumber) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetCapacite.php`, {
        id_cap: idnumber,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          console.log(res.data.query)
          if (res.data.success === true) {
            console.log(res.data.capaciteDataCap)

            //console.log(res.data.texte1)
            //setTexte1(res.data.texte1);
            setCapactity1(res.data.capaciteData1);
            setCapactity2(res.data.capaciteData2);
            setCapactity3(res.data.capaciteData3);
            setCapactity4(res.data.capaciteData4);
            setCapactity5(res.data.capaciteData5);
            setCapactity6(res.data.capaciteData6);
            setCapactity7(res.data.capaciteData7);
            setCapactity8(res.data.capaciteData8);
            setCapactity9(res.data.capaciteData9);
            setCapactity10(res.data.capaciteData10);
            setCapactity11(res.data.capaciteData11);
            setCapactity12(res.data.capaciteData12);
            setCapactity13(res.data.capaciteData13);
            setCapactity14(res.data.capaciteData14);

            setCapaciteCap(res.data.capaciteDataCap);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const smoothScroll = (e, target) => {
    var isMobile = navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );
    if (isMobile) {
      scrollGo(document.documentElement, 550, 1250);
    } else {
      e.preventDefault();
      var targetScroll = document.getElementById(target);
      scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    }
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };




  useEffect(() => {
    //sortNumbers();
    getCapactity(1);
    checkSaveSavoiFaire();
    checkSaveSavoirEtre();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardSignup}>
                <CardHeader
                  color="info"
                  signup
                  className={classes.cardHeader}
                >
                  <h4 className={classes.cardTitle}>Mes compétences professionnelles</h4>
                  <div className={classes.iframeContainer}>
                    <iframe height="350" src="https://www.youtube.com/embed/Vw1POVESZGA?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.title_}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForText)}>Cette partie est consacrée à l'élaboration de votre portefeuille de compétences professionelles acquises
                        </h4>
                        <h5 className={classNames(classes.textWarning, classes.shadowForSmallText)}>Assurez-vous d'avoir répondu à l'ensemble des questionnaires précédents (partie 1, partie 2 et partie 3 consultables dans le menu en haut à gauche), avant de poursuivre ici ...</h5>
                      </div>
                  </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                          <div className={classes.title_}>
                        <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                          1 - Revisitez votre CV ou énumérez vos expériences professionnelles en utilisant le document annexe.<br></br>
                          <a className={classNames(classes.titleBlue, classes.shadowForSmallText)} href="https://bdc.aimezvous.fr/annexes/Listez-vos-experiences-professionnelles-et-personnelles.pdf" target="_blank" >
                            <span className={classNames(classes.titleWarning, classes.shadowForSmallText)}>LISTEZ VOS EXPERIENCES PROFESSIONNELLES ET PERSONNELLES</span>
                            <AttachFile /></a><br></br><br></br>
                          2 - Prenez le temps d'explorer vos différentes expériences professionneles précedentes sur le site <a className={classNames(classes.titleBlue, classes.shadowForSmallText)} href="https://candidat.francetravail.fr/metierscope/" target="_blank" > candidat.francetravail.fr</a><br></br><br></br>

                          Utilisez le document annexe et analysez métier par métier les compétences dans l’onglet compétences de la fiche métier correspondant
                          à votre demande dans la partie savoir-faire.<br></br>
                          <a className={classNames(classes.titleBlue, classes.shadowForSmallText)} href="https://bdc.aimezvous.fr/annexes/TRAVAIL-SUR-LES-COMPETENCES.pdf" target="_blank" >
                            <span className={classNames(classes.titleWarning, classes.shadowForSmallText)}>TRAVAIL-SUR-LES-COMPETENCES</span>
                            <AttachFile /></a><br></br><br></br>
                          3 - Classez-les un par un dans la colonne "acquis" ou "non acquis". <br></br><br></br>
                          4 - Identifiez uniquement les verbes d'action acquis dans votre tableau.<br></br>
                          Par exemple : "réaliser", "contrôler", "anticiper" et transcrivez-les ensuite sur l’application. <br></br>
                          Pour cela, vous devez sélectionner sur cette page les verbes de compétences acquis au cours de vos expériences après avoir effectué votre analyse.

                        </h5>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>

                      <div className={classes.space30} />
                      <SnackbarContent
                        style={{ backgroundColor: "#e91e63" }}
                        message="SAVOIR-FAIRE"

                      />

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}
                        >
                          <div className={classes.spacePaddingS} />
                          {isMessage.length > 0 ? (
                            <>
                              <h4 className={classNames(classes.textWarning, classes.shadowForSmallText)}> - {isMessage}</h4>
                            </>
                          ) : (<></>)
                          }
                        </GridItem>
                      </GridContainer>
                      <div className={classes.space30} />
                      {numbers.map((number, index) => (
                        <>
                          {index <= 13 ? (<>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={12}>
                                <SnackbarContent
                                  key={index}
                                  style={{ backgroundColor: number.color }}
                                  message={`Je suis capable de ${number.qualite}`}
                                />


                                {index === 0 ? (
                                  <> <GridContainer>


                                    {isCapactity1.map((item) => (
                                      <GridItem xs={6} sm={6} md={6}>
                                        <div
                                          className={
                                            classes.checkboxAndRadio +
                                            " " +
                                            classes.checkboxAndRadioHorizontal
                                          } key={item.id_dtl}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                tabIndex={-1}
                                                onClick={() => handleToggle(item.id_dtl)}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                  checked: classes.checked,
                                                  root: classes.checkRoot
                                                }}
                                              />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label={item.libelle}
                                          />
                                          <div className={classes.spacePaddingS} ></div>
                                        </div>
                                      </GridItem>
                                    ))}

                                  </GridContainer>
                                  </>
                                )

                                  : index === 1 ? (
                                    <>
                                      <GridContainer>


                                        {isCapactity2.map((item) => (
                                          <GridItem xs={6} sm={6} md={6}>
                                            <div
                                              className={
                                                classes.checkboxAndRadio +
                                                " " +
                                                classes.checkboxAndRadioHorizontal
                                              } key={item.id_dtl}
                                            >
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => handleToggle(item.id_dtl)}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                      checked: classes.checked,
                                                      root: classes.checkRoot
                                                    }}
                                                  />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label={item.libelle}
                                              />
                                              <div className={classes.spacePaddingS} ></div>
                                            </div>
                                          </GridItem>
                                        ))}

                                      </GridContainer>
                                    </>
                                  )
                                    : index === 2 ? (
                                      <>
                                        <GridContainer>


                                          {isCapactity3.map((item) => (
                                            <GridItem xs={6} sm={6} md={6}>
                                              <div
                                                className={
                                                  classes.checkboxAndRadio +
                                                  " " +
                                                  classes.checkboxAndRadioHorizontal
                                                } key={item.id_dtl}
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      tabIndex={-1}
                                                      onClick={() => handleToggle(item.id_dtl)}
                                                      checkedIcon={<Check className={classes.checkedIcon} />}
                                                      icon={<Check className={classes.uncheckedIcon} />}
                                                      classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                      }}
                                                    />
                                                  }
                                                  classes={{ label: classes.label, root: classes.labelRoot }}
                                                  label={item.libelle}
                                                />
                                                <div className={classes.spacePaddingS} ></div>
                                              </div>
                                            </GridItem>
                                          ))}

                                        </GridContainer>
                                      </>
                                    )
                                      : index === 3 ? (
                                        <>
                                          <GridContainer>
                                            {isCapactity4.map((item) => (
                                              <GridItem xs={6} sm={6} md={6}>
                                                <div
                                                  className={
                                                    classes.checkboxAndRadio +
                                                    " " +
                                                    classes.checkboxAndRadioHorizontal
                                                  } key={item.id_dtl}
                                                >
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => handleToggle(item.id_dtl)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                          checked: classes.checked,
                                                          root: classes.checkRoot
                                                        }}
                                                      />
                                                    }
                                                    classes={{ label: classes.label, root: classes.labelRoot }}
                                                    label={item.libelle}
                                                  />
                                                  <div className={classes.spacePaddingS} ></div>
                                                </div>
                                              </GridItem>
                                            ))}

                                          </GridContainer>
                                        </>
                                      )
                                        : index === 4 ? (
                                          <>
                                            <GridContainer>


                                              {isCapactity5.map((item) => (
                                                <GridItem xs={6} sm={6} md={6}>
                                                  <div
                                                    className={
                                                      classes.checkboxAndRadio +
                                                      " " +
                                                      classes.checkboxAndRadioHorizontal
                                                    } key={item.id_dtl}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          tabIndex={-1}
                                                          onClick={() => handleToggle(item.id_dtl)}
                                                          checkedIcon={<Check className={classes.checkedIcon} />}
                                                          icon={<Check className={classes.uncheckedIcon} />}
                                                          classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                          }}
                                                        />
                                                      }
                                                      classes={{ label: classes.label, root: classes.labelRoot }}
                                                      label={item.libelle}
                                                    />
                                                    <div className={classes.spacePaddingS} ></div>
                                                  </div>
                                                </GridItem>
                                              ))}

                                            </GridContainer>
                                          </>
                                        )
                                          : index === 5 ? (
                                            <>
                                              <GridContainer>


                                                {isCapactity6.map((item) => (
                                                  <GridItem xs={6} sm={6} md={6}>
                                                    <div
                                                      className={
                                                        classes.checkboxAndRadio +
                                                        " " +
                                                        classes.checkboxAndRadioHorizontal
                                                      } key={item.id_dtl}
                                                    >
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            tabIndex={-1}
                                                            onClick={() => handleToggle(item.id_dtl)}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{
                                                              checked: classes.checked,
                                                              root: classes.checkRoot
                                                            }}
                                                          />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label={item.libelle}
                                                      />
                                                      <div className={classes.spacePaddingS} ></div>
                                                    </div>
                                                  </GridItem>
                                                ))}

                                              </GridContainer>
                                            </>
                                          )
                                            : index === 6 ? (
                                              <>
                                                <GridContainer>


                                                  {isCapactity7.map((item) => (
                                                    <GridItem xs={6} sm={6} md={6}>
                                                      <div
                                                        className={
                                                          classes.checkboxAndRadio +
                                                          " " +
                                                          classes.checkboxAndRadioHorizontal
                                                        } key={item.id_dtl}
                                                      >
                                                        <FormControlLabel
                                                          control={
                                                            <Checkbox
                                                              tabIndex={-1}
                                                              onClick={() => handleToggle(item.id_dtl)}
                                                              checkedIcon={<Check className={classes.checkedIcon} />}
                                                              icon={<Check className={classes.uncheckedIcon} />}
                                                              classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                              }}
                                                            />
                                                          }
                                                          classes={{ label: classes.label, root: classes.labelRoot }}
                                                          label={item.libelle}
                                                        />
                                                        <div className={classes.spacePaddingS} ></div>
                                                      </div>
                                                    </GridItem>
                                                  ))}

                                                </GridContainer>
                                              </>
                                            )
                                              : index === 7 ? (
                                                <>
                                                  <GridContainer>

                                                    {isCapactity8.map((item) => (
                                                      <GridItem xs={6} sm={6} md={6}>

                                                        <div
                                                          className={
                                                            classes.checkboxAndRadio +
                                                            " " +
                                                            classes.checkboxAndRadioHorizontal
                                                          } key={item.id_dtl}
                                                        >
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox
                                                                tabIndex={-1}
                                                                onClick={() => handleToggle(item.id_dtl)}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                classes={{
                                                                  checked: classes.checked,
                                                                  root: classes.checkRoot
                                                                }}
                                                              />
                                                            }
                                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                                            label={item.libelle}
                                                          />
                                                          <div className={classes.spacePaddingS} ></div>
                                                        </div>
                                                      </GridItem>
                                                    ))}

                                                  </GridContainer>
                                                </>
                                              )
                                                : index === 8 ? (
                                                  <>
                                                    <GridContainer>


                                                      {isCapactity9.map((item) => (
                                                        <GridItem xs={6} sm={6} md={6}>
                                                          <div
                                                            className={
                                                              classes.checkboxAndRadio +
                                                              " " +
                                                              classes.checkboxAndRadioHorizontal
                                                            } key={item.id_dtl}
                                                          >
                                                            <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  tabIndex={-1}
                                                                  onClick={() => handleToggle(item.id_dtl)}
                                                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                                                  icon={<Check className={classes.uncheckedIcon} />}
                                                                  classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                  }}
                                                                />
                                                              }
                                                              classes={{ label: classes.label, root: classes.labelRoot }}
                                                              label={item.libelle}
                                                            />
                                                            <div className={classes.spacePaddingS} ></div>
                                                          </div>
                                                        </GridItem>
                                                      ))}

                                                    </GridContainer>
                                                  </>
                                                )
                                                  : index === 9 ? (
                                                    <>
                                                      <GridContainer>


                                                        {isCapactity10.map((item) => (
                                                          <GridItem xs={6} sm={6} md={6}>
                                                            <div
                                                              className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                              } key={item.id_dtl}
                                                            >
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    tabIndex={-1}
                                                                    onClick={() => handleToggle(item.id_dtl)}
                                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                                    classes={{
                                                                      checked: classes.checked,
                                                                      root: classes.checkRoot
                                                                    }}
                                                                  />
                                                                }
                                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                                label={item.libelle}
                                                              />
                                                              <div className={classes.spacePaddingS} ></div>
                                                            </div>
                                                          </GridItem>
                                                        ))}

                                                      </GridContainer>
                                                    </>
                                                  )
                                                    : index === 10 ? (
                                                      <>
                                                        <GridContainer>


                                                          {isCapactity11.map((item) => (
                                                            <GridItem xs={6} sm={6} md={6}>
                                                              <div
                                                                className={
                                                                  classes.checkboxAndRadio +
                                                                  " " +
                                                                  classes.checkboxAndRadioHorizontal
                                                                } key={item.id_dtl}
                                                              >
                                                                <FormControlLabel
                                                                  control={
                                                                    <Checkbox
                                                                      tabIndex={-1}
                                                                      onClick={() => handleToggle(item.id_dtl)}
                                                                      checkedIcon={<Check className={classes.checkedIcon} />}
                                                                      icon={<Check className={classes.uncheckedIcon} />}
                                                                      classes={{
                                                                        checked: classes.checked,
                                                                        root: classes.checkRoot
                                                                      }}
                                                                    />
                                                                  }
                                                                  classes={{ label: classes.label, root: classes.labelRoot }}
                                                                  label={item.libelle}
                                                                />
                                                                <div className={classes.spacePaddingS} ></div>
                                                              </div>
                                                            </GridItem>
                                                          ))}

                                                        </GridContainer>
                                                      </>
                                                    )
                                                      : index === 11 ? (
                                                        <>
                                                          <GridContainer>

                                                            {isCapactity12.map((item) => (
                                                              <GridItem xs={6} sm={6} md={6}>

                                                                <div
                                                                  className={
                                                                    classes.checkboxAndRadio +
                                                                    " " +
                                                                    classes.checkboxAndRadioHorizontal
                                                                  } key={item.id_dtl}
                                                                >
                                                                  <FormControlLabel
                                                                    control={
                                                                      <Checkbox
                                                                        tabIndex={-1}
                                                                        onClick={() => handleToggle(item.id_dtl)}
                                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                                        classes={{
                                                                          checked: classes.checked,
                                                                          root: classes.checkRoot
                                                                        }}
                                                                      />
                                                                    }
                                                                    classes={{ label: classes.label, root: classes.labelRoot }}
                                                                    label={item.libelle}
                                                                  />
                                                                  <div className={classes.spacePaddingS} ></div>
                                                                </div>
                                                              </GridItem>
                                                            ))}

                                                          </GridContainer>
                                                        </>
                                                      )
                                                        : index === 12 ? (
                                                          <>
                                                            <GridContainer>


                                                              {isCapactity13.map((item) => (
                                                                <GridItem xs={6} sm={6} md={6}>
                                                                  <div
                                                                    className={
                                                                      classes.checkboxAndRadio +
                                                                      " " +
                                                                      classes.checkboxAndRadioHorizontal
                                                                    } key={item.id_dtl}
                                                                  >
                                                                    <FormControlLabel
                                                                      control={
                                                                        <Checkbox
                                                                          tabIndex={-1}
                                                                          onClick={() => handleToggle(item.id_dtl)}
                                                                          checkedIcon={<Check className={classes.checkedIcon} />}
                                                                          icon={<Check className={classes.uncheckedIcon} />}
                                                                          classes={{
                                                                            checked: classes.checked,
                                                                            root: classes.checkRoot
                                                                          }}
                                                                        />
                                                                      }
                                                                      classes={{ label: classes.label, root: classes.labelRoot }}
                                                                      label={item.libelle}
                                                                    />
                                                                    <div className={classes.spacePaddingS} ></div>
                                                                  </div>
                                                                </GridItem>
                                                              ))}

                                                            </GridContainer>
                                                          </>
                                                        )
                                                          : index === 13 ? (
                                                            <>
                                                              <GridContainer>


                                                                {isCapactity14.map((item) => (
                                                                  <GridItem xs={6} sm={6} md={6}>
                                                                    <div
                                                                      className={
                                                                        classes.checkboxAndRadio +
                                                                        " " +
                                                                        classes.checkboxAndRadioHorizontal
                                                                      } key={item.id_dtl}
                                                                    >
                                                                      <FormControlLabel
                                                                        control={
                                                                          <Checkbox
                                                                            tabIndex={-1}
                                                                            onClick={() => handleToggle(item.id_dtl)}
                                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                                            classes={{
                                                                              checked: classes.checked,
                                                                              root: classes.checkRoot
                                                                            }}
                                                                          />
                                                                        }
                                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                                        label={item.libelle}
                                                                      />
                                                                      <div className={classes.spacePaddingS} ></div>
                                                                    </div>
                                                                  </GridItem>
                                                                ))}

                                                              </GridContainer>
                                                            </>
                                                          )

                                                            : (
                                                              <></>
                                                            )


                                }
                              </GridItem>
                            </GridContainer>

                          </>) : (<></>)}
                        </>
                      ))}

                    </GridItem>
                   
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>

                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}

                    >

                      <div className={classes.spacePaddingS} ><hr></hr></div>
                      <div className={classes.spacePaddingS} ></div>
                     
                      <Button
                        color="primary"
                        size="sm"
                        round
                        onClick={() => recordAllCapacite()}
                      >
                        J'enregistre mes verbes d'action
                      </Button>
                      <br />
                      <h6 className={classes.textWarning}>{isCheckVerbeAction}</h6>
                    </GridItem>
                  </GridContainer>
                  <div className={classes.spacePaddingS} ><hr></hr></div>
                  <SnackbarContent
                    style={{ backgroundColor: "#e91e63" }}
                    message="SAVOIR-ETRE"

                  />

<GridContainer>
                        <GridItem xs={12} sm={12} md={12}
                        >
                          <div className={classes.spacePaddingS} />
                          {isMessage2.length > 0 ? (
                            <>
                              <h4 className={classNames(classes.textWarning, classes.shadowForSmallText)}> - {isMessage2}</h4>
                            </>
                          ) : (<></>)
                          }
                        </GridItem>
                      </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.title_}>
                        <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                          Choisissez les savoir-être qui vous correspondent.<br></br>
                          Pour ce faire, pensez à un exemple concret vécu pour chaque savoir-être
                          sélectionné afin de confirmer qu'il est bien acquis.
                        </h5>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                      <div className={classes.spacePaddingS} ><hr></hr></div>

                      <div className={classes.spacePaddingS} ></div>


                      <GridContainer>
                        {isCapaciteCap.map((item) => (
                          <GridItem xs={12} sm={6} md={4} >
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              } key={item.id_dtl}
                            >
                              <Card blog>
                                <CardHeader image>
                                  <div className="zoom-on-hover imgShadow"
                                    style={{
                                      backgroundImage: `url(${ApiConfig.baseUrl}/images/savoiretre/${item.image})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center center',
                                      opacity: "1"
                                    }}
                                  >
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                  <Info>
                                    <h4 className={classes.textbolder}>{item.texte}</h4>
                                  </Info>
                                </CardBody>
                                <CardFooter>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => handleToggleCap(item.id_dtl)}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot
                                        }}
                                      />
                                    }
                                    classes={{ label: classes.label, root: classes.labelRoot }}
                                    label={item.libelle}
                                  />
                                </CardFooter>

                              </Card>
                            </div>
                          </GridItem>
                        ))}
                      </GridContainer>
                      <br></br>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>

                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}

                        >
                          <Button
                            color="primary"
                            size="sm"
                            round
                            onClick={() => recordAllCapacite2()}
                          >
                            J'enregistre mes savoir-être
                          </Button>
                          <br />
                          <h6 className={classes.textWarning}>{isCheckSavoirFaire}</h6>
                          {isPortefeuilleCompetence === true && isCheckVerbeAction == '' ? (
                            <>
                              <div className={classes.spacePaddingS} ><hr></hr></div>
                              <Link to="/syntheseGlobale-page">
                                <Button round color="info" size="sm"
                                  endIcon={<ArrowForwardIcon />}
                                >
                                  Mon portefeuille de compétences
                                </Button>
                              </Link></>

                          ) : (<></>
                          )}
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <br></br>


                </CardBody>

              </Card>
            </GridItem>
          </GridContainer>

        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};


