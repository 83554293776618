/*eslint-disable*/
import React from "react";
// react plugin for creating date-time-picker
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Accordion from "components/Accordion/Accordion.js";

import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(javascriptStyles);

export default function SectionDetailProfil() {

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {sessionStorage.getItem("secondLetter") === 'R' ?
        (
          <>
            <GridContainer>
              <GridItem xs={12} sm={8} md={8}>
                <h5 className={classes.title}>DETAIL DE VOTRE PROFIL SECONDAIRE</h5>
                <Accordion
                  active={0}
                  activeColor="rose"
                  collapses={[
                    {
                      title: "LE REALISTE",
                      content:
                        "Le réaliste aime le concret. Habile de ses mains, il est débrouillard, méticuleux et sérieux"
                    },
                    {
                      title: "Le Profil",
                      content:
                        "Les personnes qui ont un profil réaliste préfèrent utiliser leurs aptitudes physiques dans leur travail et réaliser des travaux de précision et de logique. Ils comprennent facilement la mécanique et les outils plus techniques. Ils sont à l'aise dans des environnements non conventionnels et concrets. Ils sont heureux sur des chantiers, dans la rue ou dans des laboratoires. Les réalistes sont endurants et ont besoin de dépenser leur énergie. Tant qu'il y a de l'action, ils performeront en contexte de travail d'équipe."
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
            <div className={classes.space50} />
          </>
        ) : sessionStorage.getItem("secondLetter") === 'I' ?
          (
            <>
              <GridContainer>
                <GridItem xs={12} sm={8} md={8}>
                  <h5 className={classes.title}>DETAIL DE VOTRE PROFIL SECONDAIRE</h5>
                  <Accordion
                    active={0}
                    activeColor="rose"
                    collapses={[
                      {
                        title: "L'INVESTIGATEUR",
                        content:
                          "L'investigateur est un curieux insatiable. Toujours à la recherche de solutions, il aime réfléchir avant d'agir ou de prendre une décision."
                      },
                      {
                        title: "Le Profil",
                        content:
                          "Les investigateurs sont stimulés par la recherche et la réflexion. Méthodiques et posés, ils aiment étudier, apprendre et acquérir de l'information. Ils sont souvent d'une nature assez réservée et aiment passer des heures à analyser des problèmes sous tous leurs angles. Ils sont dotés d'un esprit analytique très fort. Même s'ils sont rarement bavards, leur esprit est toujours en alerte. Ils sont parfois détachés des réalités concrètes, mais sont définitivement tournés vers l'avenir et vers les nouvelles perspectives. Les investigateurs ont besoin d'assouvir leur soif de savoir en permanence et de mettre à profit leur esprit curieux et méthodique."

                      }
                    ]}
                  />
                </GridItem>
              </GridContainer>
              <div className={classes.space50} />
            </>
          ) : sessionStorage.getItem("secondLetter") === 'A' ?
            (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={8} md={8}>
                    <h5 className={classes.title}>DETAIL DE VOTRE PROFIL SECONDAIRE</h5>
                    <Accordion
                      active={0}
                      activeColor="rose"
                      collapses={[
                        {
                          title: "L'ARTISTE",
                          content:
                            "L'artiste est un électron libre. Il rejette les conventions et a besoin d'exprimer sa créativité."
                        },
                        {
                          title: "Le Profil",
                          content:
                            "Les gens au profil artistique sont des personnes créatives qui ont beaucoup d'idée. Elles sont souvent très visuelles et ont un don pour l'esthétique. Ce sont des personnalités indépendantes et anticonformistes. Les artistes aiment faire comme bon leur semble, sans contraintes et sans jugement. Pour s'épanouir, ils ont besoin d'un contexte dans lequel ils peuvent exprimer leurs émotions ou leurs idées. Ils sont sensibles au monde qui les entoure et ont un excellent sens de l'observation. Ils aiment contribuer à l'idée d'un monde meilleur grâce à leurs idées. Bien qu'ils soient souvent un peu réservés et perdus dans leurs idées, ils peuvent être d'excellent leader quand vient le temps de mener à bout leurs idées. Leur côté rêveur ne les empêche pas d'être disciplinés, car ils sont prêts à tout pour obtenir ce qu'ils veulent."
                        }
                      ]}
                    />
                  </GridItem>
                </GridContainer>
                <div className={classes.space50} />
              </>

            ) : sessionStorage.getItem("secondLetter") === 'S' ?
              (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <h5 className={classes.title}>DETAIL DE VOTRE PROFIL SECONDAIRE</h5>
                      <Accordion
                        active={0}
                        activeColor="rose"
                        collapses={[
                          {
                            title: "LE SOCIAL",
                            content:
                              "Le social s'épanouit au contact des autres. Il aime aider les gens, leur enseigner de nouvelles choses, les divertir et les conseiller."
                          },
                          {
                            title: "Le Profil",
                            content:
                              "Les personnes qui ont un profil social ont besoin d'être en relation avec d'autres personnes. Ils aiment communiquer et le font à merveille. Ils cherchent à être constamment en situation d'échange avec les autres. Ils ont un caractère sociable, altruiste, optimiste et avenant. Ils performent donc dans les métiers de soutien, d'accompagnement, d'aide et de soin. Ils savent comprendre et anticiper les besoins des autres grâce à leur grande capacité d'écoute et d'observation. Passionnés par l'être humain et ses comportements, ils savent comment dialoguer, rassurer et accompagner enfants, adolescents et adultes. Les sociaux sont aussi très à l'aise lorsqu'il s'agit de prendre la parole en public et d'exprimer leurs idées."
                          }
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                  <div className={classes.space50} />
                </>

              ) : sessionStorage.getItem("secondLetter") === 'E' ?
                (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={8} md={8}>
                        <h5 className={classes.title}>DETAIL DE VOTRE PROFIL SECONDAIRE</h5>
                        <Accordion
                          active={0}
                          activeColor="rose"
                          collapses={[
                            {
                              title: "L'ENTREPRENANT",
                              content:
                                "L'entreprenant est sûr de lui et aime convaincre. Persuasif et audacieux, il a de fortes capacités de leader."
                            },
                            {
                              title: "Le Profil",
                              content:
                                "L'entreprenant est un vendeur né. Ce type de personnalité est très confiant et aime influencer et diriger. Les entreprenants sont dotées d'une grande audace et leur débrouillardise leur permet toujours de parvenir à leurs fins. Ces profils sont également très organisés, ce qui leur est d'une grande aide lorsqu'ils doivent monter différents projets. Fins stratèges, ils sont doués en relations humaines, car ils sont capables d'interpréter les réactions de leurs interlocuteurs et de s'adapter à leur profil. Ils aiment les défis et sont stimulés par les environnements compétitifs et prestigieux. Les profils entreprenants ne craignent pas de prendre des risques. Cela fait partie de leur stratégie"
                            }
                          ]}
                        />

                      </GridItem>
                    </GridContainer>
                    <div className={classes.space50} />
                  </>

                ) : sessionStorage.getItem("secondLetter") === 'C' ?
                  (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={8} md={8}>
                          <h5 className={classes.title}>DETAIL DE VOTRE PROFIL SECONDAIRE</h5>
                          <Accordion
                            active={0}
                            activeColor="rose"
                            collapses={[
                              {
                                title: "LE CONVENTIONNEL",
                                content:
                                  "Le conventionnel est doté d'une organisation sans failles. Précis et méticuleux, il est particulièrement doué lorsqu'il s'agit d'appliquer des procédures ou de faire respecter des règles."
                              },
                              {
                                title: "Le Profil",
                                content:
                                  "Le conventionnel est plutôt de nature réservé. Il aime l'ordre, l'organisation et la constance. Travailleur, il s'accommode de tâches simples et apprécie les consignes claires. Il ne recherche pas l'aventure. Au contraire, il est déstabilisé par les imprévus et les changements. Les profils conventionnels ont un esprit ordonné et méthodique. Ils sont doués pour classer, calculer et gérer. Ils sont très respectueux de l'autorité et sont de bons exécutants. Leur côté réservé est un atout : il leur permet de travailler avec efficacité, ponctualité et rigueur. Ce sont des personnes de confiance, fiables et prudentes. On peut compter sur elles."
                              }
                            ]}
                          />

                        </GridItem>
                      </GridContainer>
                      <div className={classes.space50} />
                    </>
                  ) : (
                    <></>
                  )
      }
  </div>
  );
}
