/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
//import Search from "@material-ui/icons/Search";

// @material-ui/icons

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Radio from "@material-ui/core/Radio";
import conectPageStyle from "assets/jss/material-kit-pro-react/views/conectPageStyle.js";

const useStyles = makeStyles(conectPageStyle);
//import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

//const useStyles = makeStyles(profilePageStyle);

export default function SectionAptitude() {

  const history = useHistory();
  const [selectedChoice, setSelectedChoice] = useState([]);// de 1 a 6
  const [selectedItem, setSelectedItem] = useState([]); // de 1 a 19
  
  sessionStorage.setItem(selectedItem, selectedChoice);//je collecte en session les differentes reponses de 1 a 6 pour chaque question

  const [isMessage, setMessage] = useState('');
  //console.log("Question"+selectedItem+"Choix"+selectedChoice)

  const [isValeurPro, setValeurPro] = useState([]);
 
  const [isResultat, setResultat] = useState(false);
  const [isShowSelected, setShowSelected] = useState([]);//idValeurPro et valRep

  const getValpro = async () => {

    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetValeurProfessionelleData.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          setValeurPro(res.data.valproData);//rempli la liste
          //console.log(res.data.new_id_code);
          //console.log(res.data.query);
          sessionStorage.setItem("1", 0);
          sessionStorage.setItem("2", 0);
          sessionStorage.setItem("3", 0);
          sessionStorage.setItem("4", 0);
          sessionStorage.setItem("5", 0);
          sessionStorage.setItem("6", 0);
          sessionStorage.setItem("7", 0);
          sessionStorage.setItem("8", 0);
          sessionStorage.setItem("9", 0);
          sessionStorage.setItem("10", 0);
          sessionStorage.setItem("11", 0);
          sessionStorage.setItem("12", 0);
          sessionStorage.setItem("13", 0);
          sessionStorage.setItem("14", 0);
          sessionStorage.setItem("15", 0);
          sessionStorage.setItem("16", 0);
          sessionStorage.setItem("17", 0);
          sessionStorage.setItem("18", 0);
          sessionStorage.setItem("19", 0);
          return;
        })
    } catch (error) { throw error; }
  };


  const putInformation = () => {
   /*  if (sessionStorage.getItem("1") == 0){
      setMessage("merci d'évaluer la question 1");
      return false;
    }
    else if (sessionStorage.getItem("2") == 0){
      setMessage("merci d'évaluer la question 2");
      return false;
    }
    else if (sessionStorage.getItem("3") == 0){
      setMessage("merci d'évaluer la question 3");
      return false;
    }
    else if (sessionStorage.getItem("4") == 0){
      setMessage("merci d'évaluer la question 4");
      return false;
    }
    else if (sessionStorage.getItem("5") == 0){
      setMessage("merci d'évaluer la question 5");
      return false;
    }
    else if (sessionStorage.getItem("6") == 0){
      setMessage("merci d'évaluer la question 6");
      return false;
    }
    else if (sessionStorage.getItem("7") == 0){
      setMessage("merci d'évaluer la question 7");
      return false;
    }
    else if (sessionStorage.getItem("8") == 0){
      setMessage("merci d'évaluer la question 8");
      return false;
    }
    else if (sessionStorage.getItem("9") == 0){
      setMessage("merci d'évaluer la question 9");
      return false;
    }
    else if (sessionStorage.getItem("10") == 0){
      setMessage("merci d'évaluer la question 10");
      return false;
    }
    else if (sessionStorage.getItem("11") == 0){
      setMessage("merci d'évaluer la question 11");
      return false;
    }
    else if (sessionStorage.getItem("12") == 0){
      setMessage("merci d'évaluer la question 12");
      return false;
    }
    else if (sessionStorage.getItem("13") == 0){
      setMessage("merci d'évaluer la question 13");
      return false;
    }
    else if (sessionStorage.getItem("14") == 0){
      setMessage("merci d'évaluer la question 14");
      return false;
    }
    else if (sessionStorage.getItem("15") == 0){
      setMessage("merci d'évaluer la question 15");
      return false;
    }
    else if (sessionStorage.getItem("16") == 0){
      setMessage("merci d'évaluer la question 16");
      return false;
    }
    else if (sessionStorage.getItem("17") == 0){
      setMessage("merci d'évaluer la question 17");
      return false;
    }
    else if (sessionStorage.getItem("18") == 0){
      setMessage("merci d'évaluer la question 18");
      return false;
    }
    else if (sessionStorage.getItem("19") == 0){
      setMessage("merci d'évaluer la question 19");
      return false;
    }
    else{
      setMessage("");
    } */
     try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SaveValeurProfessionnelle.php`, {
        // IdCode: idCode,
        question1: sessionStorage.getItem("1"),
        question2: sessionStorage.getItem("2"),
        question3: sessionStorage.getItem("3"),
        question4: sessionStorage.getItem("4"),
        question5: sessionStorage.getItem("5"),
        question6: sessionStorage.getItem("6"),
        question7: sessionStorage.getItem("7"),
        question8: sessionStorage.getItem("8"),
        question9: sessionStorage.getItem("9"),
        question10: sessionStorage.getItem("10"),
        question11: sessionStorage.getItem("11"),
        question12: sessionStorage.getItem("12"),
        question13: sessionStorage.getItem("13"),
        question14: sessionStorage.getItem("14"),
        question15: sessionStorage.getItem("15"),
        question16: sessionStorage.getItem("16"),
        question17: sessionStorage.getItem("17"),
        question18: sessionStorage.getItem("18"),
        question19: sessionStorage.getItem("19"),
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),

      })
        .then(res => {

          if (res.data.success === true) {
           // setResultat(res.data.reponseValeurProData);

            setResultat(true);
            return;
          }
          else {
            setMessage('certaines questions ne sont pas renseignées !')

            //console.log('erreur')
            return;
          }
        })
    } catch (error) { throw error; }
  };

  const showSelected = () => {
   
     try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveValeurProfessionnelle.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),

      })
        .then(res => {//idValeurPro et valRep
         console.log(res.data.showreponseValeurProData)
         console.log(res.data.query)
          if (res.data.success === true) {
            setShowSelected(res.data.showreponseValeurProData);
            return;
          }
          else {
            console.log('erreur');
          //  setShowSelected();  
            return;
          }
        })
    } catch (error) { throw error; }
  };

  const gotoMotivation= () => {
    history.push("/motivation-page");
   };

  const classes = useStyles();
  useEffect(() => {
    getValpro();//CHARGE LA LISTE ET ATTEND LES CHOIX 
    showSelected();
  }, []);

  return (
    <div>
      <div className={classes.container}>


        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader
                color="info"
                signup
                className={classes.cardHeader}
              >
                <h4 className={classes.cardTitle}>Mes valeurs professionnelles</h4>
                <div className={classes.iframeContainer}>

                    <iframe height="350" src="https://www.youtube.com/embed/St7xhukaZWk?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                 
                </div>
                </CardHeader>
              <CardBody signup>

                <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Attribuez un degré d'importance à chaque valeur professionnelle, de 1 à 6, où 1 signifie "pas du tout important" et 6 signifie "très important".
                  
                  <br></br> Cochez la case correspondant à l’importance que vous accordez à chacune de ces propositions.</h5>
                  {isShowSelected.length > 0 ?
                    (<>
                   {isShowSelected.map((item) => (
                    <div>
                      <h5 className={classes.textBlue}>{item.titre} A la question {item.idValeur} votre évaluation était de {item.valRep}</h5>
                     
                    </div>
                      ))}
                      <h6>Vous devez évaluer chaque question par 1 à 6, vos réponses sont automatiquement enregistrées</h6>

                      </>
                    ) :(<>
                    
                    <h6>Merci d'évaluer les 21 questions ci-dessous</h6>

                    </>)}
                <br></br>
                {isValeurPro.map((item) => (
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    } key={item.idValeurPro}
                  >
                    <h5 className={classes.textBlue}>{item.titre} - question {item.idValeurPro}</h5>
                    <h6>{item.question}</h6>
                    <p>
                    <div className="zoom-on-hover imgShadow"
                              style={{
                                backgroundImage: `url(${ApiConfig.baseUrl}/images/valeurpro/${item.image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                opacity: "1"
                              }}
                            >
                              <a href={item.lien_metier}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => { saveItemMetier(item.id_met) }}
                              >
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                              </a>
                            </div>
<hr></hr>
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name={item.idValeurPro}
                          value={1}
                          onClick={() => { setSelectedItem(item.idValeurPro); setSelectedChoice(1) }}
                        />
                        1
                      </label>
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name={item.idValeurPro}
                          value={2}
                          onClick={() => { setSelectedItem(item.idValeurPro); setSelectedChoice(2) }}
                        />
                        2
                      </label>
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name={item.idValeurPro}
                          value={3}
                          onClick={() => { setSelectedItem(item.idValeurPro); setSelectedChoice(3) }}
                        />
                        3
                      </label>
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name={item.idValeurPro}
                          value={4}
                          onClick={() => { setSelectedItem(item.idValeurPro); setSelectedChoice(4) }}
                        />
                        4
                      </label>
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name={item.idValeurPro}
                          value={5}
                          onClick={() => { setSelectedItem(item.idValeurPro); setSelectedChoice(5) }}
                        />
                        5
                      </label>
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name={item.idValeurPro}
                          value={6}
                          onClick={() => { setSelectedItem(item.idValeurPro); setSelectedChoice(6) }}
                        />
                        6
                      </label>
                      <hr></hr>
                    </p>
                    <div className={classes.spacePaddingS} ></div>
                  </div>
                ))}


                
                  {isResultat === false ?
                    <>
                    <div className={classes.textCenter}>
                      <h6 className={classes.textInfo}>J'ai bien relu mes réponses et je confirme mes choix en cliquant sur suivant</h6>
                      <Button color="info" size="sm"
                        onClick={e => {
                          putInformation();
                        }}
                      >Suivant
                      </Button>
                      <div className={classes.spacePaddingS} />
                      <h6 className={classes.textWarning}>{isMessage}</h6>
                      </div>
                    </>
                    :
                    <>  <br />
                       {/* {isResultat.map((item) => (
                        <>                  
                        {item.titreValeurpro+" : "+item.texteValeurpro+" : "+item.valRep}
                            <div className={classes.spacePaddingS} ></div>
                    </>
                    ))} */} 
                    {isResultat === true ?
                    (<>
                    <div className={classes.textCenter}> 
                        <Button type="submit" size="sm" round color="primary"
                         onClick={() => gotoMotivation()}
                         >La suite avec mes Motivations</Button>
                         </div> 
                    </>):(<></>)}           
                  </>                      
                  }
               
                <div className={classes.spacePaddingS} ></div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </div>
    </div>
  );
}
