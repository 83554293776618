/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


//import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
//import Search from "@material-ui/icons/Search";

// @material-ui/icons

//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";


import FormControlLabel from "@material-ui/core/FormControlLabel";

//import Radio from "@material-ui/core/Radio";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionInteretActivite() {

  const history = useHistory();

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ block: 'start' });
  };

  const [checked, setChecked] = useState([]);//
  const [idCode, setIdCode] = useState(1);// Par default pour charger la premiere liste
  const [isCaractere, setCaractere] = useState('');
  const [isResultat, setResultat] = useState(false);

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    //console.log(currentIndex)
    sessionStorage.setItem("setNewChecked", newChecked);

  };

  const [isInteretActivite, setInteretActivite] = useState([]);

  const getInteretActivite = async (idCode) => {

    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInteretActiviteData.php`, {
        IdCode: idCode,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          setInteretActivite(res.data.interetActiviteData);//rempli la liste
          //console.log(res.data.new_id_code);
          //console.log(res.data.query);
          setIdCode(res.data.new_id_code);//id +1
          setCaractere(res.data.caractere);
          return;
        })
    } catch (error) { throw error; }
  };


  const putInformation = (idCode) => {

    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInteretActiviteData.php`, {
        IdCode: idCode,
        newCheckedArray: sessionStorage.getItem("setNewChecked"),
        newCaractere: isCaractere,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {

          if (res.data.success === true) {
            if (res.data.new_id_code == 1000) {//afficher le resultat du comptage et masquer le bouton suivant pour eviter les enregistrement multiple
             
              sessionStorage.setItem("SectionRiasec", 2);
              history.push("/riasec-page");

              //console.log(res.data.resultM1);
             // setResultat(res.data.resultM1);
            }
            else {

              setInteretActivite(res.data.interetActiviteData);//rempli la liste
              //console.log(res.data.query);
              setIdCode(res.data.new_id_code);//id +1
              setCaractere(res.data.caractere);
              sessionStorage.removeItem("setNewChecked");//vide la variale
              setChecked([]);//vide le tableau
              //console.log(res.data.new_id_code);
              smoothScrollTarget("FormSearch");
              
            }
            return;
          }
          else {
            /* console.log(res.data.query);
            console.log(res.data.new_id_code); */
            console.log('erreur')//ici annonce la fin du questionnaire
            return;
          }
        })
    } catch (error) { throw error; }
  };

  const classes = useStyles();
  useEffect(() => {
    getInteretActivite(idCode);//CHARGE LA LISTE ET ATTEND LES CHOIX dans newChecked
  /*   window.scrollTo(0, 0);
    document.body.scrollTop = 0; */
  }, []);

  return (
    <div>
      <div className={classes.container_}>
        <Card>
          <CardBody signup>
            <h5 className={classNames(classes.titleBlue, classes.shadowForText)}>
              1/4-	Intérêts et activités</h5>
            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Voici une liste d’activités.<br></br>Faites une croix dans les cases qui se rapportent aux activités que vous aimez ou que vous aimeriez pratiquer.<br></br>Il est important de ne pas faire de croix si l’activité vous déplaît ou vous laisse indifférent(e).<br></br>Pour remplir cette partie, considérez que vous avez les capacités, les connaissances et la formation nécessaires pour effectuer ces activités.<br></br>Tenez compte uniquement de vos goûts.</p>
            <br></br>
            {isInteretActivite.map((item) => (
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                } key={item.id}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(item.id)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.textGrayLabel, root: classes.labelRoot }}
                  label={item.question}
                />
                <hr></hr>
              </div>

            ))}
          </CardBody>
        </Card>
        <Card>
          <CardBody signup>
            <div className={classes.textCenter}>
              {isResultat === false ?
                <>
                  <h6 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>J'ai bien relu mes réponses et je confirme mes choix en cliquant sur suivant</h6>
                  <Button color="info" round
                    onClick={e => {
                      putInformation(idCode);
                    }}
                  >Suivant
                  </Button>
                  <div className={classes.spacePaddingS} />

                </>
                :
                <>
                  {/*  <h6>{isResultat}</h6> */}
                </>
              }
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
