import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { Link } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import Subject from "@material-ui/icons/Subject";


// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
/* import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Accordion from "components/Accordion/Accordion.js";


import SectionProfil from "views/PresentationPage/Sections/SectionDetailProfil.js";
import SectionProfilSecondaire from "views/PresentationPage/Sections/SectionDetailProfilSecondaire.js";

import SectionPricing from "views/PricingPage/Sections/SectionAbonnement.js"; */

import NavPills from "components/NavPills/NavPills.js";
//import styled from 'styled-components';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { makeStyles } from "@material-ui/core/styles";
import AttachFile from "@material-ui/icons/AttachFile";

// @material-ui/icons
//import Check from "@material-ui/icons/Check";
//import Warning from "@material-ui/icons/Warning";
//import ArrowForwardIcon from '@material-ui/icons/ArrowDownwardOutlined';

//import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
//import AddIcon from "@material-ui/icons/Add";
//import Gesture from "@material-ui/icons/Gesture";
//import AccountCircle from "@material-ui/icons/AccountCircle";// core components
//import Clearfix from "components/Clearfix/Clearfix.js";
//import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";


//import Button from "components/CustomButtons/Button.js";
import image from "assets/img/bg7.jpg";
//const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(signupPageStyle);


export default function InteretsPro() {

  const classes = useStyles();
  const history = useHistory();

  const [isRecordCount1, setRecordCount1] = useState(0);
  const [isRecordCount2, setRecordCount2] = useState(0);
  const [isRecordCount3, setRecordCount3] = useState(0);
  const [isRecordCount4, setRecordCount4] = useState(0);

  const checkPortefeuillePro = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetSavePortefeuilleProfessionnel.php`, {
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setRecordCount1(res.data.RecordCount1);
            setRecordCount2(res.data.RecordCount2);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const checkSynthese = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveSynthese.php`, {
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setRecordCount3(res.data.RecordCount3);
            setRecordCount4(res.data.RecordCount4);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
    checkPortefeuillePro();
    checkSynthese();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        {isRecordCount1 > 0 && isRecordCount2 > 0 && isRecordCount3 > 0 && isRecordCount4 > 0 ? (
          <>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <Card className={classes.cardSignup}>
                    <NavPills
                      alignCenter
                      color="info"
                      tabs={[
                        {
                          tabButton: "Formation",
                          tabContent: (
                            <>
                              <div className={classes.iframeContainer}>
                                <iframe height="350" src="https://www.youtube.com/embed/SBTDnWBUZik?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Formation" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                              </div>

                            </>
                          )
                        }
                      ]}
                    />
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={classes.space30} />
                          <div className={classes.title}>
                            <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                            </h4>
                            <SnackbarContent
                              style={{ backgroundColor: "#af2cc5" }}
                              message="BESOIN EN FORMATION"
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <h5 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Une formation peut être nécessaire dans l’élaboration de votre projet professionnel.</h5>
                          <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Son choix est déterminé par le domaine professionnel ou le poste visé, mais prenez également en compte les possibilités d’évolution. </h5>

                          <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Vous avez réalisé vos enquêtes sur le/les poste(s) visé(s), existe-t-il des écarts entre les compétences requises et celles que vous maitrisez ? Cela se traduit alors par un besoin en formation. </h5>

                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={classes.space30} />
                          <div className={classes.title}>
                            <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>LES DIFFÉRENTS CURSUS DE FORMATION :</h4>

                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>La formation initiale : il s’agit de la formation obtenue durant son cycle d’étude.</p>
                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>La formation continue : elle s’oppose à la formation initiale, et désigne la formation suivie par toute personne ayant terminé sa formation initiale.<br></br>Elle constitue une obligation nationale inscrite dans le code du travail.</p>

                            <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>LES DIFFÉRENTS TYPES DE FORMATION :</h4>
                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Il existe une multitude de formations possibles : universitaires, certifiantes, qualifiantes, diplômantes, titres professionnels, licences, masters, BTS, CAP, fonction publique.</p>
                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Outre la nature même de la formation, il faut également tenir compte de son mode de suivi : présentiel, sur temps ou hors temps de travail, à distance, en ligne, mixte.</p>

                            <h5 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>
                              Enfin, on peut distinguer 3 grands types de formation :</h5>
                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>

                              - Les formations sanctionnées par un titre/diplôme/certification référencés sur le Répertoire national de la certification professionnelle (RNCP).<br></br>

                              - Les formations préparant à un diplôme de l’éducation nationale.<br></br>

                              - Les formations qualifiantes concernant un domaine spécifique.<br></br>
                            </p>
                            <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>COMMENT RECHERCHER UNE FORMATION :</h4>

                            <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>La recherche va principalement être déterminée par le thème, le secteur professionnel visé.<br></br>

                              Pour cela, vous pouvez vous appuyer sur les sites généralistes suivants :<br></br>


                              <div><h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>ONISEP
                                <a href="https://www.onisep.fr"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                ><Button simple color="danger">
                                    <Subject /> ONISEP
                                  </Button>
                                </a></h4>
                              </div>

                              <div><h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>RNCP
                                <a href="https://certificationprofessionnelle.fr"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                ><Button simple color="danger">
                                    <Subject /> RNCP
                                  </Button>
                                </a></h4>
                              </div>

                              <div><h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>CIDJ
                                <a href="https://www.cidj.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                ><Button simple color="danger">
                                    <Subject /> CIDJ
                                  </Button>
                                </a></h4>
                              </div>

                              <div><h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>CPF
                                <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                ><Button simple color="danger">
                                    <Subject /> CPF
                                  </Button>
                                </a></h4>
                              </div>

                              <br></br>
                              Vous pouvez également vous adressez aux différents OPCO (Opérateurs de compétences) des différentes branches professionnelles.<br></br><br></br>
                              Enfin, il est aussi possible d’effectuer votre recherche en fonction du financement, en consultant des sites spécialisés ou en vous appuyant sur des ouvrages de référence.<br></br>
                            </p>
                          </div>
                          <div className={classes.spacePaddingS} ><hr></hr></div>
                          <Link to="/financement-page">
                            <Button round color="info" size="sm"
                              endIcon={<ArrowForwardIcon />}
                            >
                              Financement
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>                  
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </>

        ) : (
          <>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <Card className={classes.cardSignup}>
                    <NavPills
                      alignCenter
                      color="info"
                      tabs={[
                        {
                          tabButton: "Plan d'action",
                        }
                      ]}
                    />
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={classes.title}>
                            <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}> Vous devez compléter certaines informations pour accéder à la formation professionnelle, merci de revenir sur les onglets précédents.
                            </h4>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </>
        )
        }
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div >
  );
};


