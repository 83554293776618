import React, { useEffect, useState, useMemo } from 'react';
//import Axios from 'axios';
//import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
//import styled from 'styled-components';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { makeStyles } from "@material-ui/core/styles";
//import AttachFile from "@material-ui/icons/AttachFile";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import image from "assets/img/bg7.jpg";
//const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(signupPageStyle);


export default function InteretsPro() {

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardSignup}>
                <NavPills
                  alignCenter
                  color="info"
                  tabs={[
                    {
                      tabButton: "Financement",
                      tabContent: (
                        <>
                          <div className={classes.iframeContainer}>

                            <iframe height="350" src="https://www.youtube.com/embed/Gc8oDVdq0bk?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Financement" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                          </div>

                        </>
                      )
                    }
                  ]}
                />

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title}>
                        <SnackbarContent
                          style={{ backgroundColor: "#af2cc5" }}
                          message="Financement de la formation continue"
                        />
                      </div>
                    </GridItem>
                  </GridContainer>


                  <GridContainer>
                    <GridItem>
                      <div>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                          Il existe de nombreux dispositifs de financement selon chaque situation (salarié, demandeur d’emploi, etc…).<br></br>

                          Ce document a pour vocation de vous aider et vous guider dans la recherche de financement.</p>
                        <hr></hr>
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>POUR LES SALARIÉS :</h4>

                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>CPF (Compte Personnel de Formation) :<br></br>
                          <br></br>
                          <ul>
                            <li>
                              Le CPF remplace depuis le 1er janvier 2015 le DIF (Droit Individuel à la Formation).
                            </li>
                            <li>
                              Le CPF est un compte en heures qui permet à chacun (salarié comme demandeur d’emploi) de suivre une action de formation.<br></br>Ce compte est alimenté à raison de 24 heures par an les cinq premières années, et 12 heures les deux années et demie suivantes.<br></br>La limite maximale est de 150 heures.
                            </li><li>
                              Le CPF peut toutefois être abondé par l’employeur, la branche professionnelle, tout organisme ainsi que le salarié lui-même.<br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https://www.moncompteformation.gouv.fr/espace-prive/html/" target="_blank">Lien : moncompteformation.gouv.fr</a>
                            </li>
                          </ul>
                          Projet de transition professionnelle :<br></br>
                          <br></br>
                          <ul>
                            <li>
                              Ce dispositif remplace le CIF (Congé Individuel de Formation) depuis le 1er janvier 2019.
                              <br></br>
                              Il s’agit d’une modalité particulière de mobilisation du CPF permettant aux salariés souhaitant changer de métier de financer des formations certifiantes en lien avec leur projet.<br></br>
                            </li>
                            <li>
                              Dans ce cadre, le salarié peut bénéficier d’un droit à congé et d’un maintien de sa rémunération pendant la durée de l’action de formation.
                              <br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https://www.transitionspro.fr/nos-dispositifs/projet-de-transition-professionnelle/" target="_blank">Lien : transitionspro.fr</a>
                            </li>
                            <li>
                              Pour cela, il est nécessaire de rencontrer le conseiller en évolution professionnel.
                              <br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/conseil" target="_blank">Lien : moncompteformation.gouv.fr</a>
                            </li>
                          </ul>

                          Financement Conseil régional :<br></br><br></br>
                          <ul>
                            <li>
                              Dans certaines régions, les salariés peuvent bénéficier d'une aide financière pour suivre une formation universitaire hors temps de travail<br></br>(par exemple le DAEU : Diplôme d’Accès aux Études Universitaires),<br></br>ou une formation à distance.
                              <br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https:///www.daeu.fr" target="_blank">Lien : daeu.fr</a>
                            </li>
                            <li>
                              Plan de développement des compétences (à l'initiative de l’employeur)
                            </li>
                            <li>
                              Plan de développement des compétences (à l'initiative de l’employeur)
                            </li>
                            <li>
                              Le coût de la formation est alors pris en charge par l’entreprise.
                            </li>
                          </ul>
                        </p>
                        <hr></hr>
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>POUR LES DEMANDEURS D’EMPLOI :</h4>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>France Travail :<br></br><br></br>
                          <ul>
                            <li>
                              Le financement de la formation concerne les demandeurs d'emploi indemnisés au titre du chômage et dont le projet de formation, destiné à favoriser le retour à l'emploi, est validé par le Pôle Emploi.
                              <br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https:///www.francetravail.fr/accueil/" target="_blank">Lien : francetravail.fr</a>
                            </li>
                          </ul>

                          Financement Conseil régional :<br></br><br></br>
                          <ul>
                            <li>
                              Les dispositifs d’aide peuvent varier d’une région à l’autre, chaque région définit sa propre politique de financement.
                            </li>
                          </ul>
                          Contrat de professionnalisation :<br></br><br></br>
                          <ul>
                            <li>
                              Il permet aux jeunes de 16 ans à 25 ans révolus et aux demandeurs d’emploi de 26 ans et plus d’acquérir une qualification professionnelle et favorise l’insertion ou la réinsertion professionnelle.
                              <br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https://www.service-public.fr/particuliers/vosdroits/F15478/" target="_blank">Lien : service-public.fr</a>
                            </li>
                          </ul>
                        </p>
                        <hr></hr>
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>POUR LES NON-SALARIÉS :</h4>

                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Pour les non-salariés (professions libérales, agriculteurs, artisans, commerçants, travailleurs indépendants, etc…) les conditions d’accès à la formation professionnelle continue sont fixées par des règles particulières.<br></br><br></br>

                          Dans le régime général, elles s’appuient sur trois fonds d’assurance formation :<br></br>
                          <ul>
                          <li>
                          FIP-PL :<br></br>
                          Fonds interprofessionnel de formation des professions libérales
                          <br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href=" https://www.fifpl.fr" target="_blank">Lien : fifpl.fr</a>
                          </li>
                          <li>
                          AGEFICE :<br></br>
                          Association de gestion du financement de la formation des chefs d’entreprise
                          <br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https://communication-agefice.fr" target="_blank">Lien : communication-agefice.fr</a>
                          </li>
                          <li>
                          FAP-PL :<br></br>
                          Fonds d’assurance formation des professions médicales libérales
                          <br></br>
                              <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https://www.fafpm.org" target="_blank">Lien : fafpm.org</a>
                          </li>
                          </ul></p>
                          <hr></hr>
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>POUR LES PERSONNES EN SITUATION DE HANDICAP :</h4>

                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Les personnes handicapées peuvent solliciter l’AGEFIPH (fonds pour l’insertion professionnelle des personnes handicapées)
                          
                           <br></br>
                          <a className={classNames(classes.titleWarning, classes.shadowForSmallText)} href="https://www.agefiph.fr" target="_blank">Lien : agefiph.fr</a>
                          </p>

                        <hr></hr>
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>AUTRES :</h4>

                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Certaines caisses de retraite (notamment cadres) peuvent parfois prendre en charge une partie du coût de la formation en fonction de critères qui leur sont propres.<br></br>

                          Il est aussi possible de financer soi-même une formation.</p>
                      </div>
                    </GridItem>
                  </GridContainer>
                 {/*  <div className={classes.spacePaddingS} ><hr></hr></div>
              <Link to="/planAction-page">
                <Button round color="info" size="sm"
                  endIcon={<ArrowForwardIcon />}
                >
                  Plan d'action
                </Button>
              </Link> */}
                </CardBody>
              </Card>            
           
                  <Card>
                  <CardBody>
                  <div className={classes.textCenter}>
                     <h6 className={classes.textInfo}>J'accede au plan d'action professionnel en cliquant sur le bouton ci-dessous...</h6>
                     <Link to="/planAction-page">
                        <Button type="submit" size="sm" round color="info"
                          endIcon={<ArrowForwardIcon />}
                        > Plan d'action</Button>
                                       </Link>                        
                             
                  </div>
                </CardBody>
                </Card>
                </GridItem>
              </GridContainer>
        </div>

        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};


