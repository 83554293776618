/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import ArrowForwardIconUp from '@material-ui/icons/ArrowUpwardOutlined';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import AttachFile from "@material-ui/icons/AttachFile";
import presentationStyle from "assets/jss/material-kit-pro-react/views/conectPageStyle.js";
import image from "assets/img/bg12.jpg";


const useStyles = makeStyles(presentationStyle);

export default function SynthesePage() {

  const classes = useStyles();
  const history = useHistory();

  const [isSyntheseMetierPro, setSyntheseMetierPro] = useState([]);

  const [isSyntheseValeurPro, setSyntheseValeurPro] = useState([]);

  const [isSyntheseMotivation, setSyntheseMotivation] = useState([]);

  const [isMessage, setMessage] = useState('');
  const [isMessageValeur, setMessageValeur] = useState('');
  const [isMessageMotivation, setMessageMotivation] = useState('');

  const [isValeurSelected, setValeurSelected] = useState([]);

  const [isMotivationSelected, setMotivationSelected] = useState([]);

  const [istDatePlus30jours, setDatePlus30jours] = useState(2);

  const [isValeurRecordCount, setValeurRecordCount] = useState(0); 
  const [isMotivationRecordCount, setMotivationRecordCount] = useState(0); 

  const [message, resultCheckReponse] = useState([]);

  const showValeurSelected = () => {

    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveValeurSelected.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),

      })
        .then(res => {
            if (res.data.success === true) {
            setValeurSelected(res.data.showSelectValeurSaveData);
            setValeurRecordCount(res.data.valeurRecordCount);

            return;
          }
          else {
            console.log('erreur');
            return;
          }
        })
    } catch (error) { throw error; }
  };

       const showMotivationSelected = () => {
          try {
            Axios.defaults.withCredentials = true;
            Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveMotivationSelected.php`, {
              session_id: sessionStorage.getItem("session_id"),
              id_ind: sessionStorage.getItem("id_ind"),
      
            })
              .then(res => {
           
                if (res.data.success === true) {
                  setMotivationSelected(res.data.showSelectMotivationSaveData);
                  setMotivationRecordCount(res.data.motivationRecordCount);
                  return;
                }
                else {
                  console.log('erreur');
                  return;
                }
              })
          } catch (error) { throw error; }
        };


  const getSynthese = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetSynthese.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setMessage('');
            setMessageValeur('');
            setMessageMotivation('');
            setSyntheseMetierPro(res.data.synthesemetierData);
            setDatePlus30jours(res.data.datePlus30jours);
            if (res.data.RecordCount > 0) {
              setSyntheseValeurPro(res.data.synthesevaleurproData);
            }
            else {
              setMessageValeur("Vous ne pouvez pas encore accéder à votre synthèse il manque des informations concernant vos valeurs professionnelles");//catalogueProfessionnel-page
            }
            if (res.data.RecordCount > 0) {
              setSyntheseMotivation(res.data.synthesemotivationData);
            }
            else {
              setMessageMotivation("Vous ne pouvez pas encore accéder à votre synthèse il manque des informations concernant vos motivations professionnelles");//catalogueProfessionnel-page
            }
            return;
          }
          else {
            setMessage("Vous ne pouvez pas encore accéder à votre synthèse il manque des informations");// interetProfessionnel-page         
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const setSelectedItem = async (valeursPro) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveChoixValeurMotivation.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        valeursPro: valeursPro,
        keep: 1,
        requestvaleur: 1
      })
        .then(res => {
          if (res.data.success === true) {
            console.log('ok');
          }
          else {
            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const retSelectedItem = async (valeursPro) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveChoixValeurMotivation.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        valeursPro: valeursPro,
        keep: 2,
        requestvaleur: 1
      })
        .then(res => {
          if (res.data.success === true) {
            console.log('ok');
          }
          else {
            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const setSelectedItem2 = async (motivationsPro) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveChoixValeurMotivation.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        motivationsPro: motivationsPro,
        keep: 1,
        requestmtv: 1
      })
        .then(res => {
          if (res.data.success === true) {
            console.log('ok');
          }
          else {
            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const retSelectedItem2 = async (motivationsPro) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveChoixValeurMotivation.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        motivationsPro: motivationsPro,
        keep: 2,
        requestmtv: 1
      })
        .then(res => {
          if (res.data.success === true) {
            console.log('ok');
          }
          else {
            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

    const gotoSynthesePro = async () => {
          try {
            Axios.defaults.withCredentials = true;
            await Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveFinalValeurMotivation.php`, {
              session_id: sessionStorage.getItem("session_id"),
              id_ind: sessionStorage.getItem("id_ind"),
              
            })
              .then(res => {
                if (res.data.success === true) {
                  history.push("/syntheseMetierValeurMotivation-page");
                }
                else {
                  resultCheckReponse('Vous devez répondre par oui ou par non pour chacune des questions avant de finaliser votre synthèse');          
                }
                return;
              })
          } catch (error) { throw error; }
        };
        

  React.useEffect(() => {
    window.scrollTo(0, 0)
    getSynthese();
    showValeurSelected();
    showMotivationSelected();
  }, [])

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <CardHeader
                  color="info"
                  signup
                  className={classes.cardHeader}
                >
                  <h4 className={classes.cardTitle}>Travail sur les valeurs et les motivations</h4>
                  <div className={classes.iframeContainer}>

                    <iframe height="350" src="https://www.youtube.com/embed/2DvaYRkUvpo?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                  </div>
                </CardHeader>
                <CardBody signup>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} className={classes.textCenter_}>
                     
                        {isMessage !== '' ? (<>
                          <h5 className={classNames(classes.textWarning, classes.shadowForSmallText)}>{isMessage}</h5>
                          <Link to="/interetProfessionnel-page">
                            <Button
                              color="danger"
                              size="sm"
                              endIcon={<ArrowForwardIconUp />}
                              className={classNames(classes.navButton, classes.shadowForButton)}
                              round
                            >
                              retour
                            </Button>
                          </Link>
                        </>
                        )
                          : (
                            <></>
                          )
                        }
                         {isMessageValeur !== '' || isMessageMotivation  !== '' ? (<>
                          <h5 className={classNames(classes.textWarning, classes.shadowForSmallText)}>Vous ne pouvez pas encore accéder à votre synthèse il manque des informations concernant vos valeurs et / ou vos motivations professionnelles</h5>
                          <Link to="/catalogueProfessionnel-page">
                            <Button
                              color="danger"
                              size="sm"
                              endIcon={<ArrowForwardIconUp />}
                              className={classNames(classes.navButton, classes.shadowForButton)}
                              round
                            >
                              retour
                            </Button>
                          </Link>
                        </>
                        )
                          : (
                            <></>
                          )
                        }
                            <h3 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                        <div className={classes.spacePaddingS} />

                        Liste des métiers retenus</h3>
                      <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                        Vous venez de définir vos valeurs et vos motivations.<br></br>
                        Pour chaque projet ci-dessous, prenez le temps d'analyser et de rechercher si le projet y répond favorablement ou non.<br></br>
                        Menez votre enquête métiers en vous aidant de l'annexe pour orienter vos recherches.<br></br>


                        <a className={classNames(classes.titleBlue, classes.shadowForSmallText)} href="https://bdc.aimezvous.fr/annexes/ANNEXE-ENQUETES-METIERS.pdf" target="_blank" >
                          <span className={classNames(classes.titleWarning, classes.shadowForSmallText)}> ANNEXE-ENQUETES-METIERS</span>
                          <AttachFile /></a><br></br>
                        N'hésitez pas à utiliser plusieurs sources, notamment sur internet.</h5>


                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              {isSyntheseMetierPro.map((item) => (
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  } key={item.id_met}
                >
                  <Card>
                    <CardHeader>
                      <div className="zoom-on-hover imgShadow"
                        style={{
                          backgroundImage: `url(${ApiConfig.baseUrl}/images/metiers/${item.image})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          opacity: "1"
                        }}
                      >
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>

                      </div>
                    </CardHeader>
                    <CardBody signup>
                      <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>METIER IDENTIFIE :</h4>


                      <h5 className={classNames(classes.titleGray, classes.shadowForSmallText)}>{item.metier}</h5>

                      {isValeurSelected.length > 0 ?
                            (<>
                              {isValeurSelected.map((itemVs) => (
                                <div>{item.id_met === itemVs.id_metSave ?
                                  <h5 className={classes.textBlue}>{item.titre} Pour la valeur : {itemVs.texte_valeurproSave}
                                   <span  className={classes.textWarning}> vous avez répondu {itemVs.keep_vifSave == 1 ? (<>oui</>) : (<>non</>)}</span>{/* {itemVs.keep_vifSave} */}</h5>
                                  :
                                  <></>
                                }
                                </div>
                              ))}
                            </>
                            ) : (<>
                            </>)}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <hr></hr>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Valeurs identifiées :</h5>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Est-ce que ce choix de métier satisfait ou pourrait satisfaire cette valeur ?</h5>
                            </GridItem>
                          </GridContainer>


                          
                         {/*  <br></br> */}
                          {isSyntheseValeurPro.map((itemV) => (
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              } key={item.id_met + 'V' + itemV.id_valeurpro}
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <h6 className={classNames(classes.inputIconsColor, classes.shadowForSmallText)}>{itemV.titre_valeurpro} : <br></br>{itemV.texte_valeurpro}</h6>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <div className={classes.spacePaddingS} ></div>
                                  <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                    <input
                                      type="radio"
                                      name={item.id_met + 'V' + itemV.id_valeurpro}
                                      value={1}
                                      onClick={() => setSelectedItem(item.id_met + 'V' + itemV.id_valeurpro)}
                                    />
                                    oui
                                  </label>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <div className={classes.spacePaddingS} ></div>
                                  <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                    <input
                                      type="radio"
                                      name={item.id_met + 'V' + itemV.id_valeurpro}
                                      value={2}
                                      onClick={() => retSelectedItem(item.id_met + 'V' + itemV.id_valeurpro)}
                                    />
                                    non
                                  </label>
                                </GridItem>
                              </GridContainer>
                              <div className={classes.spacePaddingS} ></div>
                            </div>
                          )
                          )}

{isMotivationSelected.length > 0 ?
                            (<>
                            <hr></hr>
                            <h5 className={classNames(classes.titleGray, classes.shadowForSmallText)}>Motivations</h5>
                              {isMotivationSelected.map((itemMs) => (
                                <div>{item.id_met === itemMs.id_metSave ?
                                  <h5 className={classes.textBlue}>{item.titre} Concernant la motivation : {itemMs.texte_motivationSave}
                                   <span  className={classes.textWarning}> vous avez répondu {itemMs.keep_mifSave == 1 ? (<>oui</>) : (<>non</>)}</span>{/* {itemMs.keep_mifSave} */}</h5>
                                  :
                                  <></>
                                }
                                </div>
                              ))}
                            </>
                            ) : (<>
                            </>)}
                          <hr></hr>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Motivations identifiées :</h5>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Est-ce que ce choix de métier satisfait ou pourrait satisfaire cette motivation ?</h5>
                            </GridItem>
                          </GridContainer>
                          {isSyntheseMotivation.map((itemM) => (
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              } key={item.id_met + 'M' + itemM.id_mtv}
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <h6 className={classNames(classes.inputIconsColor, classes.shadowForSmallText)}>{itemM.texte_mtv}</h6>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                    <input
                                      type="radio"
                                      name={item.id_met + 'M' + itemM.id_mtv}
                                      value={1}
                                      onClick={() => setSelectedItem2(item.id_met + 'M' + itemM.id_mtv)}
                                    />
                                    oui
                                  </label>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                    <input
                                      type="radio"
                                      name={item.id_met + 'M' + itemM.id_mtv}
                                      value={2}
                                      onClick={() => retSelectedItem2(item.id_met + 'M' + itemM.id_mtv)} />
                                    non
                                  </label>
                                </GridItem>
                              </GridContainer>
                              <div className={classes.spacePaddingS} ></div>
                            </div>
                          )
                          )}
                        </GridItem>
                      </GridContainer>
                      <div className={classes.spacePaddingS} ></div>
                    </CardBody>
                  </Card>
                </div>
              )
              )}
              <div className={classes.spacePaddingS} ></div>

            </GridItem>
          </GridContainer>

        

          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <CardBody signup>
                <div className={classes.spacePaddingS} />
                <div className={classes.textCenter}>
                    {istDatePlus30jours===2 ? 
                    <h6 className={classes.textInfo}>Vous disposez de 1 semaine au minimum pour réaliser votre analyse</h6>
                  
                  : (<>
                     <h6 className={classes.textInfo}>J'ai bien relu mes réponses et je confirme mes choix en cliquant sur le bouton ci-dessous...</h6>
                        <Button size="sm" round color="primary"
                          onClick={() => gotoSynthesePro()}
                        >Je continu vers la synthèse</Button>
                         <br />
                         <h6 className={classes.textWarning}>{message}</h6>
                    </>)}
                   
                  </div>
                  <div className={classes.spacePaddingS} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {/* <h6 className={classNames(classes.textWarning, classes.shadowForText)}>
            A l’issue de ce questionnaire, il faut que le bénéficiaire puisse prendre conscience du ou des projets qui respectent le plus les valeurs et les motivations et lui proposer de garder les projets concernés.
          </h6> */}
        </div>

        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
