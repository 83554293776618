/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
//import Code from "@material-ui/icons/Code";
//import Group from "@material-ui/icons/Group";
//import Face from "@material-ui/icons/Face";
//import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
//import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
//import CustomInput from "components/CustomInput/CustomInput.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(signupPageStyle);

export default function EvolutionPage() {
  const history = useHistory();
  const classes = useStyles();


  const [selectedChoice2, setSelectedChoice2] = useState([]); //reponse selectionnee

  const [selectedChoice3, setSelectedChoice3] = useState([]); //reponse selectionnee

  const [selectedChoice4, setSelectedChoice4] = useState([]); //reponse selectionnee

  const [selectedChoice5, setSelectedChoice5] = useState([]); //reponse selectionnee

  const [checked, setChecked] = useState([]);

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    //console.log(newChecked)

    sessionStorage.setItem("setCheckedEvol", newChecked);

  };

  const [message, resultCheckRequest] = useState([]);


  const submitQuestionnaireEvolution = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/StartQuestionnaire_bilan.php`, {
        checkedEvolArray: sessionStorage.getItem("setCheckedEvol"),
        choice_q2: selectedChoice2,
        choice_q3: selectedChoice3,
        choice_q4: selectedChoice4,
        choice_q5: selectedChoice5,
        selectBilan: 2,//Evolution
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            console.log(res.data.session_id)
            console.log(res.data.query)
            //history.push("/");//provisoire
          }
          else {
            resultCheckRequest('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  /*   useEffect(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }); */

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <form className={classes.form} onSubmit={submitQuestionnaireEvolution}>

            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={10}>
                <Card>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}                  >
                    <h4 className={classes.cardTitle}>BILAN EVOLUTION</h4>
                    <div className={classes.iframeContainer}>
                      <iframe height="350" src="https://www.youtube.com/embed/2f-vW0lZ474?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={5} md={5}>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="Pourquoi souhaitez-vous changer de rôle dans votre entreprise ou évoluer dans votre domaine  ?"
                          description=""
                          icon={Timeline}
                          iconColor="info"
                        />
                        <h5 className={classNames(classes.titleBlue, classes.shadowForText)}>Choisissez-en autant que vous voulez.</h5>
                        <div className={classes.spacePaddingS} ></div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_1"}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => handleToggle(1)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                              />
                            }
                            classes={{ label: classes.label, root: classes.labelRoot }}
                            label={"A - Pour un meilleur salaire"}
                          />
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_2"}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => handleToggle(2)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                              />
                            }
                            classes={{ label: classes.label, root: classes.labelRoot }}
                            label={"B - Pour acquérir de nouvelles connaissances"}
                          />
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_3"}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => handleToggle(3)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                              />
                            }
                            classes={{ label: classes.label, root: classes.labelRoot }}
                            label={"C - Pour plus de responsabilités"}
                          />
                          <div className={classes.spacePaddingS} ></div>
                        </div>

                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_4"}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => handleToggle(4)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                              />
                            }
                            classes={{ label: classes.label, root: classes.labelRoot }}
                            label={"D - J’aime mon entreprise, mais je n’aime plus mon rôle actuel"}
                          />
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_5"}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => handleToggle(5)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                              />
                            }
                            classes={{ label: classes.label, root: classes.labelRoot }}
                            label={"E - autre"}
                          />
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="Depuis combien de temps souhaitez-vous ce changement ?"
                          description=""
                          icon={Timeline}
                          iconColor="info"
                        />
                        <div className={classes.spacePaddingS} ></div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={1}
                              onClick={() => { setSelectedChoice2(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>A - Quelques semaines</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={2}
                              onClick={() => { setSelectedChoice2(2) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>B - Quelques mois</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={3}
                              onClick={() => { setSelectedChoice2(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>C - Environ une année</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={4}
                              onClick={() => { setSelectedChoice2(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>D - Plusieurs années</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={5} md={5}>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="Si vous parveniez à changer de rôle ou évoluer, à quel point pensez-vous que votre vie s’améliorerait ?"
                          description=""
                          icon={Timeline}
                          iconColor="info"
                        />
                        <h5 className={classNames(classes.titleBlue, classes.shadowForText)}>Peu de changement, 0, Amélioration importante, 10</h5>
                        <div className={classes.spacePaddingS} ></div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_0"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={0}
                              onClick={() => { setSelectedChoice3(0) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>0</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={1}
                              onClick={() => { setSelectedChoice3(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>1</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={2}
                              onClick={() => { setSelectedChoice3(2) }}
                            />{""} 2
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={3}
                              onClick={() => { setSelectedChoice3(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>3</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={4}
                              onClick={() => { setSelectedChoice3(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>4</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_5"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={5}
                              onClick={() => { setSelectedChoice3(5) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>5</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_6"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={6}
                              onClick={() => { setSelectedChoice3(6) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>6</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_7"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={7}
                              onClick={() => { setSelectedChoice3(7) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>7</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_8"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={8}
                              onClick={() => { setSelectedChoice3(8) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>8</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_9"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={9}
                              onClick={() => { setSelectedChoice3(9) }}
                            />{""} 9
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_10"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={10}
                              onClick={() => { setSelectedChoice3(10) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>10</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="Depuis combien de temps travaillez-vous ?"
                          description=""
                          icon={Timeline}
                          iconColor="info"
                        />
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q4_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire4"
                              value={1}
                              onClick={() => { setSelectedChoice4(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>A - Moins de 2 ans</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q4_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire4"
                              value={2}
                              onClick={() => { setSelectedChoice4(2) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>B - 2 à 4 ans</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q4_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire4"
                              value={3}
                              onClick={() => { setSelectedChoice4(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>C - 5 à 10 ans</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q4_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire4"
                              value={4}
                              onClick={() => { setSelectedChoice4(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>D - Plus de 10 ans</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="Quel est votre niveau d’études ?"
                          description=""
                          icon={Timeline}
                          iconColor="info"
                        />
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={1}
                              onClick={() => { setSelectedChoice5(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>A - Sans diplôme</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={2}
                              onClick={() => { setSelectedChoice5(2) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>B - CAP</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={3}
                              onClick={() => { setSelectedChoice5(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>C - BEP</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={4}
                              onClick={() => { setSelectedChoice5(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>D - Baccalauréat</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_5"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={5}
                              onClick={() => { setSelectedChoice5(5) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>E - Bac +2</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_6"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={6}
                              onClick={() => { setSelectedChoice5(6) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>F - Licence</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_7"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={7}
                              onClick={() => { setSelectedChoice5(7) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>G - Master 1</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_8"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={4}
                              onClick={() => { setSelectedChoice5(8) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>H - Master 2</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_9"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={9}
                              onClick={() => { setSelectedChoice5(9) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>I - Doctorat</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div className={classes.spacePaddingS}></div>

                      </GridItem>
                    </GridContainer>

                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <Card>
                  <CardBody signup>
                    <div className={classes.spacePaddingS} />
                    <div className={classes.textCenter}>
                      <Button type="submit" size="sm" round color="primary">
                        Continuons
                      </Button>
                      <div className={classes.spacePaddingS} />
                      <h6 className={classes.textWarning}>{message}</h6>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </form>
        </div>


        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
