import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import SnackbarContent from '@material-ui/core/SnackbarContent';

//import SnackbarContent from "components/Snackbar/SnackbarContent.js";


// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Accordion from "components/Accordion/Accordion.js";


//import SectionProfil from "views/PresentationPage/Sections/SectionDetailProfil.js";
//import SectionProfilSecondaire from "views/PresentationPage/Sections/SectionDetailProfilSecondaire.js";

//import SectionPricing from "views/PricingPage/Sections/SectionAbonnement.js";

import NavPills from "components/NavPills/NavPills.js";
//import styled from 'styled-components';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { makeStyles } from "@material-ui/core/styles";
import AttachFile from "@material-ui/icons/AttachFile";

// @material-ui/icons
//import Check from "@material-ui/icons/Check";
//import Warning from "@material-ui/icons/Warning";
//import ArrowForwardIcon from '@material-ui/icons/ArrowDownwardOutlined';

//import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
//import AddIcon from "@material-ui/icons/Add";
//import Gesture from "@material-ui/icons/Gesture";
//import AccountCircle from "@material-ui/icons/AccountCircle";// core components
//import Clearfix from "components/Clearfix/Clearfix.js";
//import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";


//import Button from "components/CustomButtons/Button.js";
import image from "assets/img/bg7.jpg";
//const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(signupPageStyle);


export default function InteretsPro() {

  const classes = useStyles();
  const history = useHistory();

  const today = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(Date.now());


  const [selectedChoice1, setSelectedChoice1] = useState([]); //reponse selectionnee

  const [selectedChoice2, setSelectedChoice2] = useState([]); //reponse selectionnee

  const [selectedChoice3, setSelectedChoice3] = useState([]); //reponse selectionnee

  const [selectedChoice4, setSelectedChoice4] = useState([]); //reponse selectionnee

  const [selectedChoice5, setSelectedChoice5] = useState([]); //reponse selectionnee

  const [selectedChoice6, setSelectedChoice6] = useState([]); //reponse selectionnee

  const [selectedChoice7, setSelectedChoice7] = useState([]); //reponse selectionnee

  const [selectedChoice8, setSelectedChoice8] = useState([]); //reponse selectionnee

  const [selectedChoice9, setSelectedChoice9] = useState([]); //reponse selectionnee

  const [message, resultCheckRequest] = useState([]);


  const submitQuestionnaireSatisfaction = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      if (selectedChoice1.length === 0) {
        resultCheckRequest('information manquante question 1');
        return false;
      }
      if (selectedChoice2.length === 0) {
        resultCheckRequest('information manquante question 2');
        return false;
      }
      if (selectedChoice3.length === 0) {
        resultCheckRequest('information manquante question 3');
        return false;
      }
      if (selectedChoice4.length === 0) {
        resultCheckRequest('information manquante question 4');
        return false;
      }
      if (selectedChoice5.length === 0) {
        resultCheckRequest('information manquante question 5');
        return false;
      }
      if (selectedChoice6.length === 0) {
        resultCheckRequest('information manquante question 6');
        return false;
      }
      if (selectedChoice7.length === 0) {
        resultCheckRequest('information manquante question 7');
        return false;
      }
      if (selectedChoice8.length === 0) {
        resultCheckRequest('information manquante question 8');
        return false;
      }
      if (selectedChoice9.length === 0) {
        resultCheckRequest('information manquante question 9');
        return false;
      }

      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/Questionnaire_satisfaction.php`, {
        choice_q1: selectedChoice1,
        choice_q2: selectedChoice2,
        choice_q3: selectedChoice3,
        choice_q4: selectedChoice4,
        choice_q5: selectedChoice5,
        choice_q6: selectedChoice6,
        choice_q7: selectedChoice7,
        choice_q8: selectedChoice8,
        choice_q9: selectedChoice9,
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
           
            console.log(res.data.query)
            resultCheckRequest('Merci !');
          }
          else {
            resultCheckRequest('Erreur !');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardSignup}>
                <NavPills
                  alignCenter
                  color="info"
                  tabs={[
                    {
                      tabButton: "Conclusion",
                      tabContent: (
                        <>
                          <div className={classes.iframeContainer}>
                            <iframe height="350" src="https://www.youtube.com/embed/OQFRHTsQP_k?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Conclusion" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                          </div>
                        </>
                      )
                    }
                  ]}
                />

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Conclusion</h4>
                        <SnackbarContent
                          style={{ backgroundColor: "#af2cc5" }}
                          message="Questionnaire de Satisfaction pour le Bilan de Compétences"
                        />
                      </div>
                    </GridItem>
                  </GridContainer>

                  <form className={classes.form} onSubmit={submitQuestionnaireSatisfaction}>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Nous vous remercions d'avoir choisi notre service de bilan de compétences.<br></br>
                        Afin d'améliorer constamment la qualité de nos prestations, nous vous invitons à répondre à ce questionnaire de satisfaction.<br></br>
                        Vos réponses nous sont précieuses pour mieux répondre à vos attentes.</h5>
                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Informations Générales",
                            content:
                              (
                                <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>
                                  Date de réalisation du bilan de compétences : {today}
                                </h4>
                              )
                          }
                        ]}
                      />
                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Évaluation Globale",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                                  1.	Comment évalueriez-vous votre satisfaction générale à l'égard du bilan de compétences ?
                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q1_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire1"
                                        value={1}
                                        onClick={() => { setSelectedChoice1(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Très satisfait</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q1_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire1"
                                        value={2}
                                        onClick={() => { setSelectedChoice1(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Satisfait</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q1_3"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire1"
                                        value={3}
                                        onClick={() => { setSelectedChoice1(3) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Neutre</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q1_4"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire1"
                                        value={4}
                                        onClick={() => { setSelectedChoice1(4) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Insatisfait</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q1_5"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire1"
                                        value={5}
                                        onClick={() => { setSelectedChoice1(5) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Très insatisfait</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>



                                  2.	Recommanderiez-vous notre service de bilan de compétences à d'autres personnes ?
                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q2_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire2"
                                        value={1}
                                        onClick={() => { setSelectedChoice2(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>

                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q2_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire2"
                                        value={2}
                                        onClick={() => { setSelectedChoice2(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Non</span>
                                    </label>                                                                        
                                </div>
                                </p>
                              )
                          }
                        ]}
                      />

                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Pertinence et Utilité",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                                  3.	Les objectifs de votre bilan de compétences ont-ils été clairement définis dès le début ?
                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q3_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire3"
                                        value={1}
                                        onClick={() => { setSelectedChoice3(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q3_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire3"
                                        value={2}
                                        onClick={() => { setSelectedChoice3(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Non</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q3_3"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire3"
                                        value={3}
                                        onClick={() => { setSelectedChoice3(3) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> En partie</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>

                                  4.	Le bilan de compétences a-t-il répondu à vos attentes et à vos besoins ?
                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q4_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire4"
                                        value={1}
                                        onClick={() => { setSelectedChoice4(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui, entièrement</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q4_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire4"
                                        value={2}
                                        onClick={() => { setSelectedChoice4(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui, en partie</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q4_3"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire4"
                                        value={3}
                                        onClick={() => { setSelectedChoice4(3) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Non</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>

                                  5.	Les outils et méthodes utilisés pendant le bilan de compétences étaient-ils adaptés ?

                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q5_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire5"
                                        value={1}
                                        onClick={() => { setSelectedChoice5(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q5_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire5"
                                        value={2}
                                        onClick={() => { setSelectedChoice5(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Non</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q5_3"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire5"
                                        value={3}
                                        onClick={() => { setSelectedChoice5(3) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> En partie</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>

                                  6.	Les informations fournies pendant le bilan étaient-elles claires et compréhensibles ?
                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q6_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire6"
                                        value={1}
                                        onClick={() => { setSelectedChoice6(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q6_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire6"
                                        value={2}
                                        onClick={() => { setSelectedChoice6(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Non</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q6_3"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire6"
                                        value={3}
                                        onClick={() => { setSelectedChoice6(3) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> En partie</span>
                                    </label>
                                  </div>
                                </p>
                              )
                          }
                        ]}
                      />

                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Résultats du Bilan",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                                  7.	Le bilan de compétences vous a-t-il permis d'identifier clairement vos compétences et vos atouts ?
                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q7_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire7"
                                        value={1}
                                        onClick={() => { setSelectedChoice7(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q7_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire7"
                                        value={2}
                                        onClick={() => { setSelectedChoice7(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Non</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q7_3"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire7"
                                        value={3}
                                        onClick={() => { setSelectedChoice7(3) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> En partie</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>

                                  8.	Le bilan de compétences vous a-t-il aidé à définir un projet professionnel concret ?
                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q8_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire8"
                                        value={1}
                                        onClick={() => { setSelectedChoice8(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q8_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire8"
                                        value={2}
                                        onClick={() => { setSelectedChoice8(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Non</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q8_3"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire8"
                                        value={3}
                                        onClick={() => { setSelectedChoice8(3) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> En partie</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>

                                  9.	Le bilan de compétences vous a-t-il aidé à mieux vous connaître professionnellement et personnellement ?
                                  <div className={classes.spacePaddingS} ></div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q9_1"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire9"
                                        value={1}
                                        onClick={() => { setSelectedChoice9(1) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Oui</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q9_2"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire9"
                                        value={2}
                                        onClick={() => { setSelectedChoice9(2) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> Non</span>
                                    </label>
                                    <div className={classes.spacePaddingS} ></div>
                                  </div>
                                  <div
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    } key={"q9_3"}
                                  >
                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      <input
                                        type="radio"
                                        name="Questionnaire9"
                                        value={3}
                                        onClick={() => { setSelectedChoice9(3) }}
                                      />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> En partie</span>
                                    </label>
                                   </div>
                                </p>
                              )
                          }
                        ]}
                      />

                      {/*      <Accordion
                   active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Suggestions et Améliorations",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                                  10.	Quelles sont, selon vous, les principales forces de notre service de bilan de compétences ?
                                  <br></br>
                                  11.	Quels aspects de notre service de bilan de compétences pourraient être améliorés ?
                                  <br></br>
                                  12.	Avez-vous des suggestions pour améliorer la qualité de notre service ?
                                </p>
                              )
                          }
                        ]}
                      />

                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Remarques et Commentaires",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                                  16.	Avez-vous d'autres remarques ou commentaires que vous souhaitez partager ?
                                </p>
                              )
                          }
                        ]}
                      /> */}
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                 <div className={classes.spacePaddingS} />
                    <div className={classes.textCenter}>
                      <Button type="submit" size="sm" round color="primary">
                        Envoyer vos réponses
                      </Button>
                      <div className={classes.spacePaddingS} />
                      <h6 className={classes.textWarning}>{message}</h6>
                    </div>
              </GridItem>
            </GridContainer>
</form>

                  {/*  <div className={classes.space50} /> */}
                  <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                    Nous vous remercions pour le temps que vous avez consacré à répondre à ce questionnaire. <br></br>Vos réponses nous aideront à améliorer notre service de bilan de compétences pour mieux répondre à vos attentes et à celles de nos futurs clients.</p>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {/*                       <div className={classes.space30} />
 */}                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Merci de votre collaboration !</h4>

                      </div>
                    </GridItem>
                  </GridContainer>


                  <div className={classes.space50} />



                </CardBody>

              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};


