import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

// @material-ui/icons
//import Face from "@material-ui/icons/Face";
//import Chat from "@material-ui/icons/Chat";
//import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import tabsStyle from "assets/jss/material-kit-pro-react/views/walletTabStyle.js";

const useStyles = makeStyles(tabsStyle);

export default function PortefeuillePro() {
  const classes = useStyles();
  const history = useHistory();

  const [isVerbeAction, setVerbeAction] = useState([]);
  const [isSavoirFaire, setSavoirFaire] = useState([]);
  const [isMetierData, setMetierData] = useState([]);
  const [isValeurproData, setValeurproData] = useState([]);
  const [isMotivationData, setMotivationData] = useState([]);
  const [isActionButton, setActionButton] = useState(false);

  const [message, resultCheckReponse] = useState([]);


  const [checked, setChecked] = useState([]);//
  //console.log(checked);//le tableau qui collecte les idIpfSelect

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      //handleCheckboxAddCount();
    } else {
      newChecked.splice(currentIndex, 1);
      //handleCheckBoxRemoveCount();
    }
    setChecked(newChecked);
    // console.log(currentIndex)//montre le choix coché ou nom
   // console.log(newChecked)//le tableau qui collecte les idIpfSelect
  };

  const getPortefeuillePro = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPortefeuilleProfessionnel.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          //console.log(res.data.query)
          if (res.data.success === true) {
           // console.log(res.data.verbeData)
            setVerbeAction(res.data.verbeData);
            setSavoirFaire(res.data.savoirfaireData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getResultSynthese = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/ResultSynthese.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
          /*   console.log(res.data.resultmetierData)
            console.log(res.data.resultvaleurproData)
            console.log(res.data.resultmotivationData) */
            setMetierData(res.data.resultmetierData);
            setValeurproData(res.data.resultvaleurproData);
            setMotivationData(res.data.resultmotivationData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const affineSelectionMetier = async () => {
    /*CATALOGUE*/
    //console.log(checked)
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveNewChoiceMetier.php`, {
        // idIpf: idIpf,
        arrayIdMet: checked,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setActionButton(true)
            resultCheckReponse('');          
          }
          else {
            setActionButton(false)
            resultCheckReponse('Vous avez supprimé la totalité de vos sélections métier, de ce fait vous devez recommencer votre investigation en cliquant " CATALOGUE PROFESSIONNEL " dans le menu ci-dessus, ou bien recommencer votre synthèse en conservant vos choix métier en cliquant " SYNTHESE " .');          
          }
          return;
        })
    } catch (error) { throw error; }
  };

  
  useEffect(() => {
    //sortNumbers();
    getPortefeuillePro();
    getResultSynthese();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <div className={classes.section}>

<Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
                      <div className={classes.spacePaddingS} />

      <div className={classes.container}>
        <div id="nav-tabs">
          {/* <h3>Votre Portefeuille Professionnel</h3> */}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>

              {/* <h3>
                <small>Synthèse Professionnel</small>
              </h3> */}

              <CustomTabs

                headerColor="info"
                tabs={[
                  {
                    tabName: "Synthèse",
                    tabContent: (

                      <p className={classes.textCente_r}>
                        <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                          Sur cette page, retrouvez une synthèse pour chaque projet en relation avec vos valeurs et vos motivations respectées.<br></br>
                          Réfléchissez à ce qui est le plus important pour vous.<br></br>
                          Quels métiers aimeriez-vous conserver à ce stade ?<br></br>
                          Qu'est-ce qui est acceptable et qu'est-ce qui ne l'est pas ?<br></br>
                          L'objectif est de prendre une décision en accord avec ce qui est le plus important pour vous.</h5>
                        <hr></hr>
                        {isMetierData.map((itemM) => (
                          <>
                            <div>
                              <Card>
                                <CardHeader>
                                  <div className="zoom-on-hover imgShadow"
                                    style={{
                                      backgroundImage: `url(${ApiConfig.baseUrl}/images/metiers/${itemM.image})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center center',
                                      opacity: "1"
                                    }}
                                  >
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>

                                  </div>
                                </CardHeader>
                              </Card>
                              <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{itemM.metier}</h4>
                              {/*   {itemM.id_met}  */}
                            </div>
                            {!isValeurproData.length == 0 ?
                              <>
                                                               <h5 className={classNames(classes.textBlack, classes.shadowForSmallText)}>Valeur(s) retenue(s)</h5>

                             {/*  <h3>
                                <small>Valeur(s) retenue(s)</small>
                              </h3>  */}
                              
                              </> : <></>}
                            {isValeurproData.map((itemV) => (<>
                              {itemM.id_met == itemV.id_met ?
                                (<><div>   <h6 className={classNames(classes.titleGray, classes.shadowForSmallText)}>- {itemV.texte_valeurpro}</h6>
                                  {/*  {itemV.id_met} */}
                                </div>
                                </>) : (<></>)
                              }
                            </>
                            ))}
                            {!isMotivationData.length == 0 ?
                              <> 
                              <h5 className={classNames(classes.textBlack, classes.shadowForSmallText)}>Motivation(s) retenue(s)</h5>
                              </> : <></>}
                            {isMotivationData.map((itemT) => (
                              <>
                                {itemM.id_met == itemT.id_met ?
                                  (<><div><h6 className={classNames(classes.titleGray, classes.shadowForSmallText)}>- {itemT.texte_mtv}</h6>
                                    {/* {itemT.id_met}  */}
                                  </div>
                                  </>) : (<></>)
                                }
                              </>
                            ))}
                            <hr></hr>
                          </>
                        ))}
                      </p>
                    )
                  },
                  /* {
                    tabName: "Onglet2",
                    tabContent: (
                      <p className={classes.textCenter}>
                        Informations Onglet2
                      </p>
                    )
                  },
                  {
                    tabName: "Onglet3",
                    tabContent: (
                      <p className={classes.textCenter}>
                        Informations Onglet3
                      </p>
                    )
                  } */
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
          <Card>
            <CardBody signup>
              {isMetierData.map((itemM) => (
                <>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    } key={itemM.id_met}
                  >

                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(itemM.id_met)}
                         // checked={checked.indexOf(itemM.id_met) !== -1 ? true : false}
                          checkedIcon={<Check className={classes.uncheckedIcon} />}
                          icon={<Check className={classes.checkedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.titleBlue, root: classes.labelRoot }}
                      label={itemM.metier}
                    />
                  </div>
                  {/*                           <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{itemM.metier} {itemM.id_met}
 */}


                  <p className={classes.cardDescriptionWhite}>
                    <a href={itemM.lien_metier}
                      target="_blank"
                      rel="noopener noreferrer"
                    ><Button simple color="danger">
                        <Subject /> Consultez la fiche métier
                      </Button>
                    </a>
                  </p>


                </>)
              )}</CardBody></Card>
              
       
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody signup>
              <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
               Les métiers que vous avez sélectionnés sont cochés dans la liste ci-dessus.<br></br>Vous pouvez affiner votre sélection si besoin en décochant ceux qui ne sont plus en rapport avec votre projet professionnel.</h4>
               <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Attention, en décochant un métier vous décidez de ne pas retenir celui-ci.<br></br>En enregistrant votre sélection à l'étape suivante, votre sélection devient définitive.</h4>
                <div className={classes.spacePaddingS} />
                <div className={classes.textCenter}>
                {isActionButton === false? (
                        <>
                <Button
                            color="primary"
                            size="sm"
                            round
                            onClick={() => affineSelectionMetier()}
                          >
                            J'enregistre ma sélection pour la mémoriser
                          </Button>
                          <br />
                          <h6 className={classes.textWarning}>{message}</h6>
                          </>

) : (
  <>

  {/* poser le bouton independamment et alerter l'utiliosation de maniere definitive */}
                  <Link to="/capaciteProfessionnelle-page">
                    <Button size="sm" round color="primary">
                    Analisez vos compétences
                    </Button>
                  </Link>
                  </>)
                      }
                </div>
                <div className={classes.spacePaddingS} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
  </div>
      </div>
  );
}
