/*eslint-disable*/
import React, { useEffect, useState } from "react";

/* import Axios from 'axios';
import ApiConfig from "_config/ApiConfig"; */

//import { useHistory } from "react-router-dom";

import SectionRiasecGroup1 from "views/PresentationPage/Sections/SectionRiasecGroup1.js";
import SectionRiasecGroup2 from "views/PresentationPage/Sections/SectionRiasecGroup2.js";
import SectionRiasecGroup3 from "views/PresentationPage/Sections/SectionRiasecGroup3.js";
import SectionRiasecGroup4 from "views/PresentationPage/Sections/SectionRiasecGroup4.js";

//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//import Search from "@material-ui/icons/Search";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

//import { TextField } from '@material-ui/core/';

import formPageStyle from "assets/jss/material-kit-pro-react/views/presentationSections/formPageStyle.js";

const useStyles = makeStyles(formPageStyle);

export default function SectionRiasecGroup() {

  /* React.useEffect(() => {
   window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  //    return function cleanup() {};
 });*/

  //const history = useHistory();

  const classes = useStyles();

  //const [test, testResult] = useState(false);
  console.log(sessionStorage.getItem("testResult"),)
  /*  const easeInOutQuad = (t, b, c, d) => {
     t /= d / 2;
     if (t < 1) return (c / 2) * t * t + b;
     t--;
     return (-c / 2) * (t * (t - 2) - 1) + b;
   };
 
   const smoothScrollTarget_ = (target) => {
     var targetScroll = document.getElementById(target);
     scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
   };
 
   const smoothScrollClickTarget = (e, target) => {
     e.preventDefault();
     var targetScroll = document.getElementById(target);
     scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
   };
 
   const scrollGo = (element, to, duration) => {
     var start = element.scrollTop,
       change = to - start,
       currentTime = 0,
       increment = 20;
 
     var animateScroll = function () {
       currentTime += increment;
       var val = easeInOutQuad(currentTime, start, change, duration);
       element.scrollTop = val;
       if (currentTime < duration) {
         setTimeout(animateScroll, increment);
       }
     };
     animateScroll();
   }; */

  /* const [isNameParameter, setNameParameter] = useState({
    nameParameter: '',
  }); */
  // const [message, noSearchResult] = useState([]);

  /*  const onChangeValue = (e) => {
     setNameParameter({
       ...isNameParameter,
       [e.target.name]: e.target.value
     });
   }
 
   
 
   const smoothScrollTarget = (target) => {
     var targetScroll = document.getElementById(target);
     //scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
     targetScroll.scrollIntoView({ block: 'start' });
   }; */

  useEffect(() => {
    //sessionStorage.setItem("testResult", false);
    //testResult(false);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  return (
    <div>
      <div id="StartDiv"></div>
      <ScrollAnimation animateIn="animate__fadeInUp">
        <div id="FormSearch" className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader
                  color="info"
                  signup
                  className={classes.cardHeader}
                >
                  <h4 className={classes.cardTitle}>VOTRE CODE RIASEC</h4>
                </CardHeader></Card>
                {sessionStorage.getItem("SectionRiasec") === '1' ? 
                <>
                  <SectionRiasecGroup1 />  <br /><br />
                </>

              : <></>

              }
           
            
              {sessionStorage.getItem("SectionRiasec") === '2' ? 
                <>
                  <SectionRiasecGroup2 />  <br /><br />
                </>

               : <></>

              }
            
              {sessionStorage.getItem("SectionRiasec") === '3' ? 
                <>
                  <SectionRiasecGroup3 /> <br /><br />
                </>

               : <></>

              }
             
              {sessionStorage.getItem("SectionRiasec") === '4' ? 
                <>
                  <SectionRiasecGroup4 />   <br /><br />
                </>
               : <></>
              }
            </GridItem>
          </GridContainer>
        </div>
      </ScrollAnimation>
    </div>
  );
}
