import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import AttachFile from "@material-ui/icons/AttachFile";
import Accordion from "components/Accordion/Accordion.js";
import ArrowForwardIconUp from '@material-ui/icons/ArrowUpwardOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardOutlined';
import Subject from "@material-ui/icons/Subject";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import Info from "components/Typography/Info.js";
import { makeStyles } from "@material-ui/core/styles";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import "./style.css";
import image from "assets/img/bg7.jpg";
const useStyles = makeStyles(signupPageStyle);

export default function CatalogueMetiers() {
  const classes = useStyles();
  const history = useHistory();
  const [isMessage, setMessage] = useState('');
  const [isCatalogueTitle1, setCatalogueTitle1] = useState([]);
  const [isCatalogueTitle2, setCatalogueTitle2] = useState([]);
  const [isCatalogueTitle3, setCatalogueTitle3] = useState([]);
  const [isCatalogueTitle4, setCatalogueTitle4] = useState([]);
  const [isCatalogueTitle5, setCatalogueTitle5] = useState([]);

  const [isCatalogueMetier1, setCatalogueMetier1] = useState([]);
  const [isCatalogueMetier2, setCatalogueMetier2] = useState([]);
  const [isCatalogueMetier3, setCatalogueMetier3] = useState([]);
  const [isCatalogueMetier4, setCatalogueMetier4] = useState([]);
  const [isCatalogueMetier5, setCatalogueMetier5] = useState([]);

  const setSelectedItem = async (id_met) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveChoixMetier.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        id_met: id_met,
        keep: 1,
      })
        .then(res => {
          if (res.data.success === true) {
            getItemMetier();
          }
          else {
            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const retSelectedItem = async (id_met) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveChoixMetier.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        id_met: id_met,
        keep: 2,
      })
        .then(res => {
          if (res.data.success === true) {
            getItemMetier();
          }
          else {
            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  /*CATALOGUE*/
  const setSelectedCatalogue = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogueMetier.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setMessage('');
            setCatalogueTitle1(res.data.cataloguetitledata1);
            setCatalogueTitle2(res.data.cataloguetitledata2);
            setCatalogueTitle3(res.data.cataloguetitledata3);
            setCatalogueTitle4(res.data.cataloguetitledata4);
            setCatalogueTitle5(res.data.cataloguetitledata5);
            setCatalogueMetier1(res.data.cataloguemetierdata1);
            setCatalogueMetier2(res.data.cataloguemetierdata2);
            setCatalogueMetier3(res.data.cataloguemetierdata3);
            setCatalogueMetier4(res.data.cataloguemetierdata4);
            setCatalogueMetier5(res.data.cataloguemetierdata5);
          }
          else {
            setMessage("Pour accéder à vos catalogues métiers vous devez définir au préalable vos 5 intérêts professionnels.")
            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const [isSetItemMetier, setItemMetier] = useState([]);
  const [istDatePlus15jours, setDatePlus15jours] = useState(2);
  const [message, resultCheckReponse] = useState([]);
  const getItemMetier = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/getConsultationMetier.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      }).then(res => {
        if (res.data.success === true) {
          if (res.data.RecordCount > 0)
            setItemMetier(res.data.consultationmetierdata);
          setDatePlus15jours(res.data.datePlus15jours);
        }
        else {
          console.log('erreur');
        }
        return;
      })
    } catch (error) { throw error; }
  };


  const saveItemMetier = async (id_met) => {//sauvegarde la consultation au fur et a mesure

    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveConsultationMetier.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        idMetier: id_met,
      }).then(res => {
        if (res.data.success === true) {
          getItemMetier();//appele le tableau a mapper
           }
        else {
          console.log('erreur');
        }
        return;
      })
    } catch (error) { throw error; }
  };


  const gotoValeurPro = async () => { 
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveSelectionMetier.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {           
            history.push("/valeurProfessionnelle-page");
          }
          else {
            resultCheckReponse('Vous devez répondre par oui ou par non pour chacun des métiers que vous avez visualisés');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setSelectedCatalogue();
    getItemMetier();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  color="info"
                  signup
                  className={classes.cardHeader}
                >
                  <h4 className={classes.cardTitle}>Catalogue Métiers</h4>
                  <div className={classes.iframeContainer}>
                    <iframe height="350" src="https://www.youtube.com/embed/YFRvxPGbW5Y?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title_}>
                        <div className={classes.spacePaddingS} />
                        {isMessage !== '' ? (<>
                          <h5 className={classNames(classes.textWarning, classes.shadowForSmallText)}>{isMessage}</h5>
                          <Link to="/interetProfessionnel-page">
                            <Button
                              color="danger"
                              size="sm"
                              endIcon={<ArrowForwardIconUp />}
                              className={classNames(classes.navButton, classes.shadowForButton)}
                              round
                            >
                              retour
                            </Button>
                          </Link>
                        </>
                        )
                          : (
                            <></>
                          )
                        }

                        <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                          1 - Consultez la liste des métiers correspondant à vos 5 intérêts professionnels ci-dessous.<br></br>
                          2 - Identifiez les métiers qui suscitent votre curiosité, votre envie ou un rêve inachevé.<br></br>
                          3 - Utilisez le document annexe pour vous aider dans cette démarche.
                          <a className={classNames(classes.titleBlue, classes.shadowForSmallText)} href="https://bdc.aimezvous.fr/annexes/ANNEXE-RETOUR-INVESTIGATIONS-METIERS.pdf" target="_blank" >
                            <span className={classNames(classes.titleWarning, classes.shadowForSmallText)}> RETOUR INVESTIGATIONS METIERS</span>
                            <AttachFile /></a><br></br>
                          4 - Effectuez vos premières recherches sur ces métiers.<br></br>
                          5 - Conservez uniquement ceux qui éveillent un réel intérêt pour vous.</h5>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <div id="catalogue" />
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <Card className={classes.cardSdigdnup}>
                        <CardBody>
                          <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Retour sur les investigations de métiers</h4>
                          <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Vous disposez de 2 semaines au minimum pour rassembler les informations necessaires pour votre projet professionnel, ensuite revenez sur cette partie de l'interface pour continuer votre bilan.</h4>


                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Au fil de votre investigation, trouvez ici la liste des métiers que vous avez consultés.
                          </p>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Sélectionnez "oui" ou "non" pour chaque métier afin de déterminer ceux que vous souhaitez conserver.<br></br>Faites votre choix de manière aussi objective que possible.
                          </p>
                          {isSetItemMetier.length > 0 ?
                            (<>
                              <br></br>
                              {isSetItemMetier.map((item) => (
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  } key={item.id_met}
                                >
                                  <h6 className={classNames(classes.titleGray, classes.shadowForSmallText)}>{item.metier}
                                    <a href={item.lien_metier}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    ><Button simple color="danger">
                                        <Subject /> Voir la fiche métier
                                      </Button>
                                    </a></h6>
                                  <p>

                                    <label classes={{ label: classes.label, root: classes.labelRoot }}>
                                      {item.keep === '1' ?
                                        (<>
                                          <input
                                            type="radio"
                                            name={item.id_met}
                                            checked={true}
                                            value={1}
                                            onClick={() => setSelectedItem(item.id_met)}
                                          />
                                          oui
                                          <input
                                            type="radio"
                                            name={item.id_met}
                                            checked={false}
                                            value={2}
                                            onClick={() => retSelectedItem(item.id_met)}
                                          />
                                          non
                                        </>)
                                        : item.keep === '2' ?
                                          (<>
                                            <input
                                              type="radio"
                                              name={item.id_met}
                                              checked={false}
                                              value={1}
                                              onClick={() => setSelectedItem(item.id_met)}
                                            />
                                            oui
                                            <input
                                              type="radio"
                                              name={item.id_met}
                                              checked={true}
                                              value={2}
                                              onClick={() => retSelectedItem(item.id_met)}
                                            />
                                            non
                                          </>)
                                          :
                                          (<>
                                            <input
                                              type="radio"
                                              name={item.id_met}
                                              checked={false}
                                              value={1}
                                              onClick={() => setSelectedItem(item.id_met)}
                                            />
                                            oui
                                            <input
                                              type="radio"
                                              name={item.id_met}
                                              checked={false}
                                              value={2}
                                              onClick={() => retSelectedItem(item.id_met)}
                                            />
                                            non
                                          </>)
                                      }

                                    </label>

                                  </p>
                                  <hr></hr>
                                  <div className={classes.spacePaddingS} ></div>
                                </div>
                              ))}
                            </>) : (<>

                            </>)}
                          <div className={classes.spacePaddingS} ></div>
                          <GridContainer className={classes.pullRightButton}>
                            <Button round className={classes.buttonPrimary} size="sm"
                              endIcon={<ArrowDownwardIcon />}
                              onClick={() => { smoothScroll('bottomCatalogue') }}
                            >
                            </Button>
                          </GridContainer>
                        </CardBody>

                      </Card>
                    </GridItem>
                  </GridContainer>

                  <Accordion
                    active={0}
                    activeColor="info"
                    collapses={[
                      {
                        title: <><span className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Catalogue : {isCatalogueTitle1}</span></>,
                        content:
                          (
                            <>
                              <GridContainer>
                                {isCatalogueMetier1.map((item) => (
                                  <>
                                    <GridItem xs={12} sm={12} md={4} key={item.id_met}>
                                      <div id={"cat_" + item.id_met} />
                                      <Card blog>
                                        <CardHeader image>
                                          <div className="zoom-on-hover imgShadow"
                                            style={{
                                              backgroundImage: `url(${ApiConfig.baseUrl}/images/metiers/${item.image})`,
                                              backgroundSize: 'cover',
                                              backgroundPosition: 'center center',
                                              opacity: "1"
                                            }}
                                          >
                                            <a href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              onClick={() => { saveItemMetier(item.id_met) }}
                                            >
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                            </a>
                                          </div>

                                          <Info>
                                            <span className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{item.metier}</span>
                                          </Info>
                                          <div className={classes.author}>
                                            <span className={classNames(classes.titleWarning, classes.shadowForSmallText)} >{item.texte_interetpro}</span>
                                          </div>
                                          <div className={classes.stats + " " + classes.mlAuto}>
                                            <a
                                              href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"

                                            ><div className={classes.putRight}>
                                                <Button justIcon simple color="twitter"
                                                  onClick={() => { saveItemMetier(item.id_met) }}>
                                                  <ArrowForwardIcon />
                                                </Button></div></a>
                                          </div>
                                        </CardHeader>
                                      </Card>
                                    </GridItem>

                                  </>
                                ))}
                              </GridContainer>
                            </>
                          )
                      },
                      {
                        title: <><span className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Catalogue : {isCatalogueTitle2}</span></>,
                        content:
                          (
                            <>
                              <GridContainer>
                                {isCatalogueMetier2.map((item) => (
                                  <>
                                    <GridItem xs={12} sm={12} md={4} key={item.id_met}>
                                      <div id={"cat_" + item.id_met} />
                                      <Card blog>
                                        <CardHeader image>
                                          <div className="zoom-on-hover imgShadow"
                                            style={{
                                              backgroundImage: `url(${ApiConfig.baseUrl}/images/metiers/${item.image})`,
                                              backgroundSize: 'cover',
                                              backgroundPosition: 'center center',
                                              opacity: "1"
                                            }}
                                          >
                                            <a href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              onClick={() => { saveItemMetier(item.id_met) }}
                                            >
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                            </a>
                                          </div>
                                          <Info>
                                            <span className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{item.metier}</span>
                                          </Info>
                                          <div className={classes.author}>
                                            <span className={classNames(classes.titleWarning, classes.shadowForSmallText)} >{item.texte_interetpro}</span>
                                          </div>
                                          <div className={classes.stats + " " + classes.mlAuto}>
                                            <a
                                              href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            ><div className={classes.putRight}>
                                                <Button justIcon simple color="twitter"
                                                  onClick={() => { saveItemMetier(item.id_met) }}>
                                                  <ArrowForwardIcon />
                                                </Button></div></a>
                                          </div>
                                        </CardHeader>
                                      </Card>
                                    </GridItem>

                                  </>
                                ))}
                              </GridContainer>
                            </>
                          )
                      },
                      {
                        title: <><span className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Catalogue : {isCatalogueTitle3}</span></>,
                        content:
                          (
                            <>
                              <GridContainer>
                                {isCatalogueMetier3.map((item) => (
                                  <>
                                    <GridItem xs={12} sm={12} md={4} key={item.id_met}>
                                      <div id={"cat_" + item.id_met} />
                                      <Card blog>
                                        <CardHeader image>
                                          <div className="zoom-on-hover imgShadow"
                                            style={{
                                              backgroundImage: `url(${ApiConfig.baseUrl}/images/metiers/${item.image})`,
                                              backgroundSize: 'cover',
                                              backgroundPosition: 'center center',
                                              opacity: "1"
                                            }}
                                          >
                                            <a href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              onClick={() => { saveItemMetier(item.id_met) }}
                                            >
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                            </a>
                                          </div>
                                          <Info>
                                            <span className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{item.metier}</span>
                                          </Info>
                                          <div className={classes.author}>
                                            <span className={classNames(classes.titleWarning, classes.shadowForSmallText)} >{item.texte_interetpro}</span>
                                          </div>
                                          <div className={classes.stats + " " + classes.mlAuto}>
                                            <a
                                              href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"

                                            ><div className={classes.putRight}>
                                                <Button justIcon simple color="twitter"
                                                  onClick={() => { saveItemMetier(item.id_met) }}>
                                                  <ArrowForwardIcon />
                                                </Button></div></a>
                                          </div>
                                        </CardHeader>
                                      </Card>
                                    </GridItem>
                                  </>
                                ))}
                              </GridContainer>
                            </>
                          )
                      },
                      {
                        title: <><span className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Catalogue : {isCatalogueTitle4}</span></>,
                        content:
                          (
                            <>
                              <GridContainer>
                                {isCatalogueMetier4.map((item) => (
                                  <>
                                    <GridItem xs={12} sm={12} md={4} key={item.id_met}>
                                      <div id={"cat_" + item.id_met} />
                                      <Card blog>
                                        <CardHeader image>
                                          <div className="zoom-on-hover imgShadow"
                                            style={{
                                              backgroundImage: `url(${ApiConfig.baseUrl}/images/metiers/${item.image})`,
                                              backgroundSize: 'cover',
                                              backgroundPosition: 'center center',
                                              opacity: "1"
                                            }}
                                          >
                                            <a href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              onClick={() => { saveItemMetier(item.id_met) }}
                                            >
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                            </a>
                                          </div>
                                          <Info>
                                            <span className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{item.metier}</span>
                                          </Info>
                                          <div className={classes.author}>
                                            <span className={classNames(classes.titleWarning, classes.shadowForSmallText)} >{item.texte_interetpro}</span>
                                          </div>
                                          <div className={classes.stats + " " + classes.mlAuto}>
                                            <a
                                              href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            ><div className={classes.putRight}>
                                                <Button justIcon simple color="twitter"
                                                  onClick={() => { saveItemMetier(item.id_met) }}>
                                                  <ArrowForwardIcon />
                                                </Button></div></a>
                                          </div>
                                        </CardHeader>
                                      </Card>
                                    </GridItem>

                                  </>
                                ))}
                              </GridContainer>
                            </>
                          )
                      },
                      {
                        title: <><span className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Catalogue : {isCatalogueTitle5}</span></>,
                        content:
                          (
                            <>
                              <GridContainer>
                                {isCatalogueMetier5.map((item) => (
                                  <>
                                    <GridItem xs={12} sm={12} md={4} key={item.id_met}>
                                      <div id={"cat_" + item.id_met} />
                                      <Card blog>
                                        <CardHeader image>
                                          <div className="zoom-on-hover imgShadow"
                                            style={{
                                              backgroundImage: `url(${ApiConfig.baseUrl}/images/metiers/${item.image})`,
                                              backgroundSize: 'cover',
                                              backgroundPosition: 'center center',
                                              opacity: "1"
                                            }}
                                          >
                                            <a href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              onClick={() => { saveItemMetier(item.id_met) }}
                                            >
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                              <p>&nbsp;</p>
                                            </a>
                                          </div>
                                          <Info>
                                            <span className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{item.metier}</span>
                                          </Info>
                                          <div className={classes.author}>
                                            <span className={classNames(classes.titleWarning, classes.shadowForSmallText)} >{item.texte_interetpro}</span>
                                          </div>
                                          <div className={classes.stats + " " + classes.mlAuto}>
                                            <a
                                              href={item.lien_metier}
                                              target="_blank"
                                              rel="noopener noreferrer"

                                            ><div className={classes.putRight}>
                                                <Button justIcon simple color="twitter"
                                                  onClick={() => { saveItemMetier(item.id_met) }}>
                                                  <ArrowForwardIcon />
                                                </Button></div></a>
                                          </div>
                                        </CardHeader>
                                      </Card>
                                    </GridItem>

                                  </>
                                ))}
                              </GridContainer>
                            </>
                          )
                      }
                    ]}
                  />
                  <GridContainer className={classes.pullRightButton}>
                    <Button round className={classes.buttonPrimary} size="sm"
                      endIcon={<ArrowForwardIconUp />}
                      onClick={() => { smoothScroll('catalogue') }}
                    >
                    </Button>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <div id="bottomCatalogue" />
          {isSetItemMetier.length > 0 ?
            (<>

              <GridContainer>
                <GridItem>
                  <Card>
                    <CardBody>
                      <div className={classes.textCenter}>
                        {istDatePlus15jours === 2 ?
                          <h6 className={classes.textInfo}>Vous disposez de 2 semaines au minimum pour enregistrer vos choix métier</h6>
                          : (<>
                            <h6 className={classes.textInfo}>J'ai bien relu mes réponses et je confirme mes choix en cliquant sur le bouton ci-dessous...</h6>
                            <Button type="submit" size="sm" round color="primary"
                              onClick={() => gotoValeurPro()}
                            >Vers mes Valeurs Professionnelles</Button>
                            <br />
                            <h6 className={classes.textWarning}>{message}</h6>
                          </>)}
                      </div>
                      <div className={classes.spacePaddingS} ></div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </>) : (<>

            </>)}
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};


