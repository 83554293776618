import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Subject from "@material-ui/icons/Subject";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import NavPills from "components/NavPills/NavPills.js";
import { makeStyles } from "@material-ui/core/styles";
import AttachFile from "@material-ui/icons/AttachFile";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
//import Button from "components/CustomButtons/Button.js";
import image from "assets/img/bg7.jpg";
const useStyles = makeStyles(signupPageStyle);

export default function InteretsPro() {

  const classes = useStyles();
  const history = useHistory();

  const [checked, setChecked] = useState([]);//
  //console.log(checked);//le tableau qui collecte les idIpfSelect

  const [count, setCount] = useState(0);//le compteur

  /* const handleCheckboxAddCount = () => {
    setCount(count + 1);
  };

  const handleCheckBoxRemoveCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  }; */

   let nbrR = sessionStorage.getItem("R");
  let nbrI = sessionStorage.getItem("I");
  let nbrA = sessionStorage.getItem("A");
  let nbrS = sessionStorage.getItem("S");
  let nbrE = sessionStorage.getItem("E");
  let nbrC = sessionStorage.getItem("C");

  const [numbers, setNumbers] = useState([
    { letter: 'R', value: nbrR, color: "#ffa21a", qualite: "REALISTE" },
    { letter: 'I', value: nbrI, color: "#00d3ee", qualite: "INVESTIGATEUR" },
    { letter: 'A', value: nbrA, color: "#af2cc5", qualite: "ARTISTIQUE" },
    { letter: 'S', value: nbrS, color: "#5cb860", qualite: "SOCIAL" },
    { letter: 'E', value: nbrE, color: "#f55a4e", qualite: "ENTREPRENANT" },
    { letter: 'C', value: nbrC, color: "#CCCD", qualite: "CONVENTIONNEL" },
  ]);


  const firstIndex = useMemo(() => numbers[0], [numbers]);

  const [isMetierData, setMetierData] = useState([]);
  const [isVerbeAction, setVerbeAction] = useState([]);
  const [isVerbeTitle, setVerbeTitle] = useState([]);

  const [isSavoirFaire, setSavoirFaire] = useState([]);
  const [isValeurproData, setValeurproData] = useState([]);
  const [isMotivationData, setMotivationData] = useState([]);

  const getPortefeuillePro = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPortefeuilleProfessionnel.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          console.log(res.data.query)
          if (res.data.success === true) {
            setVerbeAction(res.data.verbeData);
            setVerbeTitle(res.data.verbeTitle);

            setSavoirFaire(res.data.savoirfaireData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getResultSynthese = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/ResultSynthese.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            console.log(res.data.resultmetierData)
            console.log(res.data.resultvaleurproData)
            console.log(res.data.resultmotivationData)

            setMetierData(res.data.resultmetierData);
            setValeurproData(res.data.resultvaleurproData);
            setMotivationData(res.data.resultmotivationData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };




  useEffect(() => {
    getPortefeuillePro();
    getResultSynthese();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardSignup}>
                <NavPills
                  alignCenter
                  color="rose"
                  tabs={[
                    {
                      tabButton: "Compétences Projet",
                      tabContent: (
                        <>
                          <div className={classes.iframeContainer}>

                            <iframe height="350" src="https://www.youtube.com/embed/hRfGu65DW7U?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                          </div>

                        </>
                      )
                    }
                  ]}
                />



                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Ci-dessous retrouvez la liste de vos métiers retenus

                        </h4>
                        <SnackbarContent
                          style={{ backgroundColor: "#af2cc5" }}
                          message="METIER/VALEUR/MOTIVATION"
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>MA SELECTION METIERS </h5>
                      <Accordion
                        activeColor="info"
                        collapses={[
                          {
                            title: "Mes Métiers",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>

                                  {isMetierData.map((itemM) => (
                                    <>
                                      <div><h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{itemM.metier}
                                        <a href={itemM.lien_metier}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        ><Button simple color="danger">
                                            <Subject /> Voir la fiche métier
                                          </Button>
                                        </a></h4>
                                      </div>
                                      <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Vos valeurs professionnelles retenues pour ce métier</h4>
                                      <ul>
                                      {isValeurproData.map((itemV) => (
                                        <>
                                          {itemM.id_met == itemV.id_met ?
                                            (<>
                                            <li>
                                              {itemV.texte_valeurpro}
                                              </li>
                                            </>) : (<></>)
                                          }
                                        </>
                                      ))}
                                      </ul>
                                      <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Vos motivations retenues pour ce métier</h4>
                                      <ul>
                                      {isMotivationData.map((itemT) => (
                                        <>
                                          {itemM.id_met === itemT.id_met ?
                                            (<>
                                            <li>{itemT.texte_mtv}
                                            </li>
                                            </>) : (<></>)
                                          }
                                        </>
                                      ))}
                                       </ul>
                                      <hr></hr>
                                    </>
                                  ))}
                                </p>
                              )
                          }
                        ]}
                      />
                    </GridItem>
                  </GridContainer>


                  <div className={classes.space50} />

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Ci-dessous retrouvez la liste de vos compétences passées acquises (savoir-faire et savoir-être)

                        </h4>
                        <SnackbarContent
                          style={{ backgroundColor: "#e91e63" }}
                          message="VOS SAVOIR-FAIRE et SAVOIR-ETRE"
                        />
                      </div>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>DETAIL DE VOS ACQUIS </h5>
                      <Accordion
                        activeColor="rose"
                        collapses={[
                          {
                            title: "Vos Savoir-faire",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>

                                  {isVerbeTitle.map((itemT) => (
                                    <>
                                      <h5 className={classNames(classes.titleWarning, classes.shadowForSmallText)}> {itemT.texte} </h5>

                                      {isVerbeAction.map((item) => (
                                        <>
                                          {item.id_cpf === itemT.id_cpf ?
                                            (<>
                                              <div> - {item.libelle} </div></>) : (<></>)
                                          }

                                        </>
                                      ))}
                                      <hr></hr>
                                    </>
                                  ))}
                                </p>
                              )
                          },
                          {
                            title: "Vos Savoir-être",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                                  {isSavoirFaire.map((item) => (
                                    <>
                                      <div>- {item.libelle}</div>
                                      <hr></hr>
                                    </>
                                  ))}

                                </p>
                              )
                          }
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                  <div className={classes.space50} />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Imprimez et utilisez le document annexe  par projet professionnel :</h4>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>A l’aide de l'annexe : compétences projets, et du lien pour chaque projet professionnel afin de prendre connaissance des compétences (savoir-faire et savoir être)
                          nécessaires pour chaque projet envisagé.</p>
                        <a className={classNames(classes.titleBlue, classes.shadowForSmallText)} href="https://bdc.aimezvous.fr/annexes/COMPETENCES-PROJET.pdf" target="_blank" >
                          <span className={classNames(classes.titleWarning, classes.shadowForSmallText)}> ANNEXE-COMPETENCES-PROJET</span>
                          <AttachFile /></a><br></br>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Vous devez classez les compétences de vos projets dans les colonnes compétences : acquises / Partiellement Acquises / non acquises.</p>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Aidez-vous également de l’annexe de la semaine précédente qui vous a permis d’analyser vos propres compétences acquises correspondantes à vos expériences professionnelles passées afin de les comparer avec celles de vos projets professionnels.</p>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Vous constaterez que vous maitrisez certaines des compétences de vos différents projets et à l'inverse qu’un besoin de formation vous sera nécessaire.</p>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Ce travail vous sera nécessaire pour la prochaine étape.</p>{/* S6 BESOIN DE FORMATION  */}
                      </div>
                      <div className={classes.spacePaddingS} ><hr></hr></div>
                          <Link to="/formationProfessionnelle-page">
                            <Button round color="info" size="sm"
                              endIcon={<ArrowForwardIcon />}
                            >
                              Formation professionnelle
                            </Button>
                          </Link>
                    </GridItem>
                  </GridContainer>



               </CardBody>
              </Card>
              
              

            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};


