/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


//import Switch from "@material-ui/core/Switch";
//import Checkbox from "@material-ui/core/Checkbox";
//import Check from "@material-ui/icons/Check";
//import Search from "@material-ui/icons/Search";

// @material-ui/icons

//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";


//import FormControlLabel from "@material-ui/core/FormControlLabel";

//import Radio from "@material-ui/core/Radio";


import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);


export default function SectionPersonnalite() {

  const history = useHistory();

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ block: 'start' });
  };

  const [selectedChoice, setSelectedChoice] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  sessionStorage.setItem(selectedItem, selectedChoice);//je collecte en session les differentes reponses de 1 a 10 avec numero

  console.log(selectedChoice + selectedItem)

  const [idCode, setIdCode] = useState(1);// Par default pour charger la premiere liste
  const [isCaractere, setCaractere] = useState('');

  const [isResultat, setResultat] = useState(false);

  const [isPersonnalite, setPersonnalite] = useState([]);


  const getPersonnalite = async (idCode) => {

    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPersonnaliteData.php`, {
        IdCode: idCode,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          setPersonnalite(res.data.personnaliteData);//rempli la liste
          //console.log(res.data.new_id_code);
          //console.log(res.data.query);
          setIdCode(res.data.new_id_code);//id +1
          setCaractere(res.data.caractere);
          sessionStorage.setItem("1", 0);
          sessionStorage.setItem("2", 0);
          sessionStorage.setItem("3", 0);
          sessionStorage.setItem("4", 0);
          sessionStorage.setItem("5", 0);
          sessionStorage.setItem("6", 0);
          sessionStorage.setItem("7", 0);
          sessionStorage.setItem("8", 0);
          sessionStorage.setItem("9", 0);
          sessionStorage.setItem("10", 0);

          return;
        })
    } catch (error) { throw error; }
  };


  const putInformation = (idCode) => {

    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPersonnaliteData.php`, {
        IdCode: idCode,
        question1: sessionStorage.getItem("1"),
        question2: sessionStorage.getItem("2"),
        question3: sessionStorage.getItem("3"),
        question4: sessionStorage.getItem("4"),
        question5: sessionStorage.getItem("5"),
        question6: sessionStorage.getItem("6"),
        question7: sessionStorage.getItem("7"),
        question8: sessionStorage.getItem("8"),
        question9: sessionStorage.getItem("9"),
        question10: sessionStorage.getItem("10"),
        newCaractere: isCaractere,
        newCheckedArray: 1000,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {

          if (res.data.success === true) {
            if (res.data.new_id_code == 1000) {//afficher le resultat du comptage et masquer le bouton suivant pour eviter les enregistrement multiple
              //  console.log(res.data.resultM4);
              setResultat(true);
              sessionStorage.removeItem("SectionRiasec");//vide la variale
              setPersonnalite('0');
              //setPersonnalite

            }
            else {
              setPersonnalite(res.data.personnaliteData);//rempli la liste
              //console.log(res.data.query);
              setIdCode(res.data.new_id_code);//id +1
              setCaractere(res.data.caractere);
              sessionStorage.setItem("1", 0);
              sessionStorage.setItem("2", 0);
              sessionStorage.setItem("3", 0);
              sessionStorage.setItem("4", 0);
              sessionStorage.setItem("5", 0);
              sessionStorage.setItem("6", 0);
              sessionStorage.setItem("7", 0);
              sessionStorage.setItem("8", 0);
              sessionStorage.setItem("9", 0);
              sessionStorage.setItem("10", 0);
              //setChecked([]);//vide le tableau/*  */
              smoothScrollTarget("FormSearch");

            }
            return;
          }
          else {
            //console.log(res.data.query);
            console.log('erreur')
            return;
          }
        })
    } catch (error) { throw error; }
  };

  const resultatRIASEC = () => {
    try {
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/ResultatRIASEC.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            sessionStorage.setItem("R", res.data.nombreR);
            sessionStorage.setItem("I", res.data.nombreI);
            sessionStorage.setItem("A", res.data.nombreA);
            sessionStorage.setItem("S", res.data.nombreS);
            sessionStorage.setItem("E", res.data.nombreE);
            sessionStorage.setItem("C", res.data.nombreC);

            console.log('R' + res.data.nombreR)
            console.log('I' + res.data.nombreI)
            console.log('A' + res.data.nombreA)
            console.log('S' + res.data.nombreS)
            console.log('E' + res.data.nombreE)
            console.log('C' + res.data.nombreC)

            history.push("/interetProfessionnel-page");
            return;
          }
        })
    } catch (error) { throw error; }
  };

  const resetInformation = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/ResetInformation.php`)
        .then(res => {
          if (res.data.success === true) {
            refreshPage();
            return;
          }
        })
    } catch (error) { throw error; }
  };
  function refreshPage() {
    window.location.reload(false);
  }

  const classes = useStyles();
  useEffect(() => {
    getPersonnalite(idCode);//CHARGE LA LISTE ET ATTEND LES CHOIX 
  }, []);

  return (
    <div>
      <div className={classes.container_}>
      <Card>
          <CardBody signup>
        <h5 className={classNames(classes.titleBlue, classes.shadowForText)}>
          4/4- Personnalité</h5>
        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Voici une liste de traits de caractère, d’attitudes, de tendances personnelles.<br></br>
        Faites une croix dans la case correspondant aux énoncés qui s’appliquent à vous, qui décrivent le plus honnêtement possible votre façon d’agir.</p>
        <br></br>
        {isPersonnalite !=='0' ?(
          <>
        {isPersonnalite.map((item) => (
          <div
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            } key={item.id}
          >
            <h6 className={classNames(classes.titleGray, classes.shadowForSmallText)}>{item.question}</h6>
            <p>

              <label classes={{ label: classes.label, root: classes.labelRoot }}>
                <input
                  type="radio"
                  name={item.id}
                  value={1}
                  onClick={() => { setSelectedItem(item.numero); setSelectedChoice(1) }}
                />
                1
              </label>
              <label classes={{ label: classes.label, root: classes.labelRoot }}>
                <input
                  type="radio"
                  name={item.id}
                  value={2}
                  onClick={() => { setSelectedItem(item.numero); setSelectedChoice(2) }}
                />
                2
              </label>
              <label classes={{ label: classes.label, root: classes.labelRoot }}>
                <input
                  type="radio"
                  name={item.id}
                  value={3}
                  onClick={() => { setSelectedItem(item.numero); setSelectedChoice(3) }}
                />
                3
              </label>
            </p>
            <hr></hr>
            <div className={classes.spacePaddingS} ></div>
          </div>
        ))}
</>) : (<>
  <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Votre test est complet, cliquez ci-dessous pour visualiser son résultat.</p>
        <br></br>
</>)
}

</CardBody>
        </Card>

        <Card>
          <CardBody signup>
        <div className={classes.textCenter}>
          {isResultat === false ?
            <>
              <h6 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>J'ai bien relu mes réponses et je confirme mes choix en cliquant sur suivant</h6>
              <Button color="info" round
                onClick={e => {
                  putInformation(idCode);
                }}
              >Suivant
              </Button>
              <div className={classes.spacePaddingS} />
            </>
            :
            <>  <br />
              {/* <h6>{isResultat}</h6> */}
              <Button color="primary" round size="sm"
                onClick={e => {
                  resultatRIASEC();
                }}
              >Résultat de votre test
              </Button>
              <div className={classes.spacePaddingS} />
            </>
          }
        </div>
        </CardBody>
        </Card>
      </div>
    </div>
  );
}
