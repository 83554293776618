import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
  const classes = useStyles();

  const [datePrice, setDataPrice] = useState([1, 2, 3]);
  const [Idind, setIdind] = useState('');

  const callBdcDataPrice = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/getPriceProductBdc.php`, {
        id_bdc: id,
      })
        .then(res => {
          if (res.data.success === true) {
            setDataPrice(res.data.priceproductdata);
            setIdind(res.data.id_ind);
          }
          else {
            console.log(res.data.success);
          }
        })
    } catch (error) { throw error; }
  };
  React.useEffect(() => {

  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    callBdcDataPrice(20);
  }, []);

  return (
    <div className={classes.pricingSection}>
      <GridContainer>
        <GridItem
          md={6}
          sm={6}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={3} sm={3}>
        </GridItem>
        <GridItem md={6} sm={6}>
          <Card raised pricing color="rose">
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Votre Bilan de Comptétences en {datePrice[0].label_ppd}</h6>
              <h2 className={classes.cardTitleWhite}>
                {/*               Abonnement {datePrice[0].label_ppd}
 */}
                {datePrice[0].price_ppd}  <small>€</small><small>/mois</small>
              </h2>
              <ul>
                <li>
                  <b>Analyse</b> de vos centres d'intérêts
                </li>
                <li>
                  <b>Investigations</b> métiers
                </li>
                <li>
                  <b>Travail</b> sur vos valeurs et vos motivations
                </li>
                <li>
                  <b>Enquêtes</b> métiers approfondie
                </li>
                <li>
                  <b>Analyse</b> de vos compétences professionnelles acquises
                </li>
                <li>
                  <b>Identification</b> des besoins de formation
                </li>
                <li>
                  <b>Plan d'action</b> et mise en oeuvre de vos projets professionnels
                </li>
                <li>
                  <b>Phase</b> de conclusion
                </li>
              </ul>
{/*               <Link to={`https://aimezvous.fr/stripeTransaction/lib/checkout.php?PRICE=${datePrice[0].product_ppd}&ID=${Idind}`}>
 */}                <Button href={`https://aimezvous.fr/stripeTransaction/lib/checkout.php?PRICE=${datePrice[0].product_ppd}&ID=${Idind}`} color="white" round>
                  Je m'abonne 
                  {/* {datePrice[0].price_ppd} € / mois */}
                </Button>
{/*               </Link>
 */}              <br></br><br></br>
              Abonnement pour {datePrice[0].label_ppd} prélevé automatiquement chaque mois,
              résiliable en fin de période d'abonnement.
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
        </GridItem>
      </GridContainer>
    </div>
  );
}
