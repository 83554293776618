import {
  primaryColor,
  grayColor,
  container,
  cardTitle,
  title,
  whiteColor,
  infoColor,
  dangerColor,
  blackColor,
  warningColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

const signupPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: whiteColor
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(" +
        hexToRgb(primaryColor[4]) +
        ",.56),rgba(" +
        hexToRgb(primaryColor[5]) +
        ",.95))"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2);",
    marginBottom: "100px",
    padding: "40px 0px"
  },
  cardTitle: {
    ...cardTitle,
    textDecoration: "none",
    textAlign: "center !important",
    marginBottom: "0.75rem",
    color: whiteColor + "  !important"
  },
  ...customCheckboxRadioSwitchStyle,
  socials: {
    marginTop: "0",
    position: "absolute",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  textCenter: {
    textAlign: "center"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: grayColor[13]
  },
  form: {
    margin: "0"
  },
  infoArea: {
    padding: "0px 0px 20px !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },  
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px"
  },
  spacePaddingS: {
    paddingBottom : "20px",
  },
  spacePaddingM: {
    paddingBottom : "50px",
  },
  spacePaddingL: {
    paddingBottom : "100px",
  },
  spacePaddingXL: {
    paddingBottom : "200px",
  },
  marginButton : {
    marginLeft: "auto",
    marginRight: "auto",
  },
  space70: {
    height: "70px",
    display: "block"
  },
  space50: {
    height: "50px",
    display: "block"
  },
  space30: {
    height: "30px",
    display: "block"
  },
  textbolder:{
    fontWeight: "500",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center"
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  titleBlue: {
    ...title,
    color: infoColor[0] 
  },
  titleGray: {
    ...title,
    color: grayColor[1] 
  },
  titleWarning: {
    ...title,
    color: warningColor[0] 
  },
  textBlack: {
    fontWeight: "500",
    color: "#000",
  },
  textWarning: {
    fontWeight: "500",
    color: dangerColor[0]
  },
  shadowForText: {  
    textShadow: "0 2px 3px rgba(" + hexToRgb(grayColor[9]) + ",0.3)"
  },
  shadowForSmallText: {  
    textShadow: "0 1px 2px rgba(" + hexToRgb(grayColor[9]) + ",0.2)"
  },
  shadowForButton: {  
    boxShadow: "0 3px 5px rgba(" + hexToRgb(grayColor[9]) + ",0.5)"
  },
  putRight: {
    margin: "0",
    float: "right",    
  },
  pullRightButton: {
    float: "right"
  },
  buttonPrimary: {
    background: primaryColor[0],
    color: whiteColor
  },
};

export default signupPageStyle;
