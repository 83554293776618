/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
//import Search from "@material-ui/icons/Search";

// @material-ui/icons

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Radio from "@material-ui/core/Radio";
import conectPageStyle from "assets/jss/material-kit-pro-react/views/conectPageStyle.js";

const useStyles = makeStyles(conectPageStyle);
//import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

//const useStyles = makeStyles(profilePageStyle);

export default function SectionAptitude() {

  const history = useHistory();
  const [selectedChoiceMotivation, setSelectedChoice] = useState([]);// de 1 a 3
  const [selectedIdMtv, setSelectedItem] = useState([]); // de 1 a 51

  sessionStorage.setItem(selectedIdMtv, selectedChoiceMotivation);//je collecte en session les differentes reponses de 1 a 6 pour chaque question

  const [isValeurMotivation, setValeurMotivation] = useState([]);
  const [isTitreMotivation, setTitreMotivation] = useState([]);
  const [isIdMotivation, setIdMotivation] = useState([]);
  const [isMessage, setMessage] = useState('');


  const [isResultat, setResultat] = useState(false);

  const getMotivation = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetMotivationData.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
        id_titre: id,
      })
        .then(res => {
          setValeurMotivation(res.data.motivationData);//rempli la liste
          setTitreMotivation(res.data.titre);
          setIdMotivation(res.data.id_titre);
          if(id===1){
          sessionStorage.setItem("1", 0);
          sessionStorage.setItem("2", 0);
          sessionStorage.setItem("3", 0);
          sessionStorage.setItem("4", 0);
          sessionStorage.setItem("5", 0);
          sessionStorage.setItem("6", 0);
          sessionStorage.setItem("7", 0);
          sessionStorage.setItem("8", 0);
          sessionStorage.setItem("9", 0);
          sessionStorage.setItem("10", 0);
          sessionStorage.setItem("11", 0);
          sessionStorage.setItem("12", 0);
          sessionStorage.setItem("13", 0);
          sessionStorage.setItem("14", 0);
          sessionStorage.setItem("15", 0);
          sessionStorage.setItem("16", 0);
          sessionStorage.setItem("17", 0);
          sessionStorage.setItem("18", 0);
          sessionStorage.setItem("19", 0);
          sessionStorage.setItem("20", 0);
          sessionStorage.setItem("21", 0);
          sessionStorage.setItem("22", 0);
          sessionStorage.setItem("23", 0);
          sessionStorage.setItem("24", 0);
          sessionStorage.setItem("25", 0);
          sessionStorage.setItem("26", 0);
          sessionStorage.setItem("27", 0);
          sessionStorage.setItem("28", 0);
          sessionStorage.setItem("29", 0);
          sessionStorage.setItem("30", 0);
          sessionStorage.setItem("31", 0);
          sessionStorage.setItem("32", 0);
          sessionStorage.setItem("33", 0);
          sessionStorage.setItem("34", 0);
          sessionStorage.setItem("35", 0);
          sessionStorage.setItem("36", 0);
          sessionStorage.setItem("37", 0);
          sessionStorage.setItem("38", 0);
          sessionStorage.setItem("39", 0);
          sessionStorage.setItem("40", 0);
          sessionStorage.setItem("41", 0);
          sessionStorage.setItem("42", 0);
          sessionStorage.setItem("43", 0);
          sessionStorage.setItem("44", 0);
          sessionStorage.setItem("45", 0);
          sessionStorage.setItem("46", 0);
          sessionStorage.setItem("47", 0);
          sessionStorage.setItem("48", 0);
          sessionStorage.setItem("49", 0);
          sessionStorage.setItem("50", 0);
          sessionStorage.setItem("51", 0); 
          }

          //console.log(res.data.new_id_code);
          //console.log(res.data.query);
        
          return;
        })
    } catch (error) { throw error; }
  };


  const putInformation = () => {

    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SaveMotivation.php`, {
        //id_titre: isIdMotivation,
        question1: sessionStorage.getItem("1"),
        question2: sessionStorage.getItem("2"),
        question3: sessionStorage.getItem("3"),
        question4: sessionStorage.getItem("4"),
        question5: sessionStorage.getItem("5"),
        question6: sessionStorage.getItem("6"),
        question7: sessionStorage.getItem("7"),
        question8: sessionStorage.getItem("8"),
        question9: sessionStorage.getItem("9"),
        question10: sessionStorage.getItem("10"),
        question11: sessionStorage.getItem("11"),
        question12: sessionStorage.getItem("12"),
        question13: sessionStorage.getItem("13"),
        question14: sessionStorage.getItem("14"),
        question15: sessionStorage.getItem("15"),
        question16: sessionStorage.getItem("16"),
        question17: sessionStorage.getItem("17"),
        question18: sessionStorage.getItem("18"),
        question19: sessionStorage.getItem("19"),
        question20: sessionStorage.getItem("20"),
        question21: sessionStorage.getItem("21"),
        question22: sessionStorage.getItem("22"),
        question23: sessionStorage.getItem("23"),
        question24: sessionStorage.getItem("24"),
        question25: sessionStorage.getItem("25"),
        question26: sessionStorage.getItem("26"),
        question27: sessionStorage.getItem("27"),
        question28: sessionStorage.getItem("28"),
        question29: sessionStorage.getItem("29"),
        question30: sessionStorage.getItem("30"),
        question31: sessionStorage.getItem("31"),
        question32: sessionStorage.getItem("32"),
        question33: sessionStorage.getItem("33"),
        question34: sessionStorage.getItem("34"),
        question35: sessionStorage.getItem("35"),
        question36: sessionStorage.getItem("36"),
        question37: sessionStorage.getItem("37"),
        question38: sessionStorage.getItem("38"),
        question39: sessionStorage.getItem("39"),
        question40: sessionStorage.getItem("40"),
        question41: sessionStorage.getItem("41"),
        question42: sessionStorage.getItem("42"),
        question43: sessionStorage.getItem("43"),
        question44: sessionStorage.getItem("44"),
        question45: sessionStorage.getItem("45"),
        question46: sessionStorage.getItem("46"),
        question47: sessionStorage.getItem("47"),
        question48: sessionStorage.getItem("48"),
        question49: sessionStorage.getItem("49"),
        question50: sessionStorage.getItem("50"),
        question51: sessionStorage.getItem("51"),
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),

      })
        .then(res => {

          if (res.data.success === true) {
            setMessage('');
            //console.log(res.data);
            history.push("/synthese-page");
            return;
          }
          else {
            setMessage("Vous devez répondre au minimum à 30 questions");

            //console.log(res.data);
            //console.log(res.data.query);
            //console.log('erreur')
            return;
          }
        })
    } catch (error) { throw error; }
  };


  const retry = () => {
    getMotivation(1);//CHARGE LA LISTE ET ATTEND LES CHOIX 
  }; 

  const classes = useStyles();
  useEffect(() => {
    getMotivation(1);//CHARGE LA LISTE ET ATTEND LES CHOIX 
  }, []);

  return (
    <div>
      <div className={classes.container}>


        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                signup
                className={classes.cardHeader}
              >
                <h4 className={classes.cardTitle}>Mes Motivations</h4>
                <div className={classes.iframeContainer}>
                    <iframe height="350" src="https://www.youtube.com/embed/laBsKnntcZQ?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                 
                </div>
              </CardHeader>
              <CardBody signup>


              <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Vous trouverez ci-dessous une liste de motivations. Pour chacune d'elles, indiquez si elle est souhaitée pour votre futur projet professionnel, si vous êtes indifférent, ou si elle est refusée.</h5>
                <br></br>
                <h4 className={classNames(classes.textBlack, classes.shadowForSmallText)}>{isTitreMotivation}</h4>


                {isValeurMotivation.map((item) => (
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    } key={item.id_mtv}
                  > <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h6 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{item.question} - question {item.id_mtv}</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <label classes={{ label: classes.label, root: classes.labelRoot }}>
                          <input
                            type="radio"
                            name={item.id_mtv}
                            value={1}
                            onClick={() => { setSelectedItem(item.id_mtv); setSelectedChoice(1) }}
                          />
                          Souhaité
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <label classes={{ label: classes.label, root: classes.labelRoot }}>
                          <input
                            type="radio"
                            name={item.id_mtv}
                            value={2}
                            onClick={() => { setSelectedItem(item.id_mtv); setSelectedChoice(2) }}
                          />
                          Indifférent
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <label classes={{ label: classes.label, root: classes.labelRoot }}>
                          <input
                            type="radio"
                            name={item.id_mtv}
                            value={3}
                            onClick={() => { setSelectedItem(item.id_mtv); setSelectedChoice(3) }}
                          />
                          Refusé
                        </label>
                      </GridItem>
                    </GridContainer>
                    <div className={classes.spacePaddingS} ><hr></hr></div>
                  </div>
                ))}

                {isIdMotivation !== 1000 ?
                  (
                    <>
                      {isResultat === false ?
                        <>
                          <div className={classes.textCenter}>
                            <h6 className={classes.textInfo}>J'ai bien relu mes réponses et je confirme mes choix en cliquant sur suivant</h6>
                            <Button color="info" size="sm"
                              onClick={e => {
                                getMotivation(isIdMotivation);
                              }}
                            >Suivant
                            </Button>
                          </div>
                        </>
                        :
                        <>  <br />
                          {isResultat.map((item) => (
                            <>
                              {item.titreValeurpro + " : " + item.texteValeurpro + " : " + item.valRep}
                              <div className={classes.spacePaddingS} ></div>
                            </>
                          ))}
                         
                        </>
                      }
                    </>) : (<>
                        {/* {isResultat.length > 0 ?
                            (<> */}
                              <div className={classes.textCenter}>
                                <Button type="submit" size="sm" round color="primary"
                                  onClick={() => putInformation()}
                                >Synthèse de mes valeurs</Button>
                                 <h6 className={classes.textWarning}>{isMessage}</h6>
                                 {isMessage.length > 0 ? 
                                 <>
                                 <Button type="submit" size="sm" round color="primary"
                                  onClick={() => retry()}
                                >Recommencer</Button>                                 
                                 </> : (<></>)}
                              </div>
                          {/*   </>) : (<></>)} */}
                    
                    </>)

                }

                <div className={classes.spacePaddingS} ></div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </div>
    </div>
  );
}
