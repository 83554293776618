/*eslint-disable*/
import React from "react";
// react plugin for creating date-time-picker
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Accordion from "components/Accordion/Accordion.js";

import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";



const useStyles = makeStyles(javascriptStyles);


export default function SectionDetailProfil() {

  /*
    { letter: 'R', value: nbrR, color: "#ffa21a", qualite: "REALISTE" },
    { letter: 'I', value: nbrI, color: "#00d3ee", qualite: "INVESTIGATEUR" },
    { letter: 'A', value: nbrA, color: "#af2cc5", qualite: "ARTISTIQUE" },
    { letter: 'S', value: nbrS, color: "#5cb860", qualite: "SOCIAL" },
    { letter: 'E', value: nbrE, color: "#f55a4e", qualite: "ENTREPRENANT" },
    { letter: 'C', value: nbrC, color: "#CCCD", qualite: "CONVENTIONNEL" },
    */
  sessionStorage.getItem("firstLetter");
  sessionStorage.getItem("secondLetter");

  const classes = useStyles();
  return (
    <div className={classes.container}>

      {sessionStorage.getItem("firstLetter") === 'R' ?
        (
          <>
            <GridContainer>
              <GridItem xs={12} sm={8} md={8}>
                <h5 className={classes.title}>DETAIL DE VOTRE PROFIL</h5>
                <Accordion
                  active={0}
                  activeColor="rose"
                  collapses={[
                    {
                      title: "TYPE REALISTE",
                      content:
                        "Les personnes de ce type sont fréquemment peu à l’aise dans les relations sociales. Elles ont besoin d’être sollicitée pour s’exprimer. Il est nécessaire d’établir une relation de confiance pour qu’un véritable dialogue s’instaure. Elles ont du mal à se décrire elles-mêmes et sont plus à l’aise pour le faire lorsqu’elles sont confrontées à des situations réelles et à des expériences concrètes (stages professionnels)."
                    },
                    {
                      title: "Le Type REALISTE",
                      content:
                        "Cette rubrique correspond au goût pour les activités concrètes, pratiques, qui nécessitent un travail manuel et une certaine dépense physique, comme : réparer, conduire des machines ou utiliser des outils. Les personnes qui ont un score élevé dans cette rubrique préfèrent l’action à la réflexion, les problèmes concrets aux problèmes abstraits. Il en résulte une attirance pour les métiers techniques, manuels, et le travail en plein air (ingénieur des eaux et forêts, militaire, conducteur routier, sportif professionnel, charpentier, ingénieur en électronique, mécanicien...). Les personnes « Réalistes » ont un certain sens pratique, de bonnes aptitudes techniques et une certaine maîtrise de leur corps.                    Les personnes de ce type exercent surtout des tâches concrètes. Habiles de leurs mains, elles savent coordonner leurs gestes. Elles se servent d’outils, font fonctionner des appareils, des machines, des véhicules. Les réalistes ont le sens de la mécanique, le souci de la précision. Plusieurs exercent leur profession à l’extérieur plutôt qu’à l’intérieur. Leur travail demande souvent une bonne endurance physique, et même des capacités athlétiques. Ces personnes sont patientes, minutieuses, constantes, sensées, natures, franches, pratiques, concrètes, simples. Caractérisé par la simplicité, le sens pratique, une attitude naturelle, etc., le type réaliste est attiré par les activités concrètes (manipulation d’objets, d’outils, de machines), les contacts avec la nature (animaux, plantes), l’exercice physique et le sport ; il s’intéresse, par exemple, à la mécanique ou à d’autres techniques ; habituellement, il ne recherche pas spécialement les contacts sociaux et évite de se faire remarquer."
                    }
                  ]}
                />
                <h5 className={classes.title}>AUTRES INFORMATIONS</h5>
                <Accordion
/*               active={0}
 */              activeColor="rose"
                  collapses={[
                    {
                      title: "Types d’activités préférées",
                      content:
                        "Construction, activité militaire, fabrication, mécanique, commerce spécialisé, transport, agriculture, exploitation forestière, application de la loi, ingénierie."
                    },
                    {
                      title: "Caractéristiques des individus de ce type",
                      content:
                        "Sens pratique, traditionalistes, pragmatiques, rudes, aimant la vie à l’extérieur, aventureux, physiquement forts, de confiance, économes, tenaces, conventionnels, stables."
                    },
                    {
                      title: "Univers de travail",
                      content:
                        "Mécanique, structuré, si possible à l'extérieur, nécessitant l’usage d’outils et fabriquant des produis tangibles, horaires réguliers, instructions claires et ne prêtant pas à interprétation."
                    }
                  ]}
                />
                <h5 className={classes.title}>DESCRIPTION GENERALE</h5>
                <Accordion
/*               active={0}
 */              activeColor="rose"
                  collapses={[
                    {
                      title: "Description",
                      content:
                        "Les personnes de type « Réaliste » sont stables, tenaces, entières et robustes. Elles préfèrent les univers très tournés vers les choses pratiques et mettant en Jeu des produits bien définis. Elles aiment apprendre et travailler avec des machines et du matériel. Elles préfèrent les méthodes traditionnelles pour faire les choses. Elles aiment la vie à l’extérieur et peuvent préférer s’installer hors des villes. Les personnes de type « Réaliste » sont des réalisatrices et préfèrent l’action à la discussion. Elles peuvent devenir impatientes avec ceux qui préfèrent discuter d’un problème que de le résoudre. Elles sont véritablement épanouies quand elles atteignent leur condition physique optimum."
                    },

                    {
                      title: "Valeurs/objectifs",
                      content:
                        "Les personnes de- type « Réaliste » cherchent à produire des choses utiles, tangibles et de bonne qualité. Elles considèrent le succès comme la capacité d’utiliser des talents physiques ou moteurs pour résoudre des problèmes concrets et immédiat. Elles sont peu concernées par la façon dont les autres les voient ou les considèrent aussi longtemps qu’elles sont satisfaites d’elles-mêmes. L’argent leur sert à acquérir les choses qui leur plaisent : une petite maison secondaire, une moto tout terrain, une caravane ou une fermette."
                    },
                    {
                      title: "Vision du monde",
                      content:
                        "Les personnes de type « Réaliste » voient le monde d’une façon pratique et immédiate. Elles se sentent plus concernées par le présent que par le futur."
                    }
                  ]}
                />
                <h5 className={classes.title}>L'INDIVIDU</h5>
                <Accordion
                  /*  active={0} */
                  activeColor="rose"
                  collapses={[
                    {
                      title: "Contributions et apports",
                      content:
                        "Les personnes de type « Réaliste » apportent à leur organisation la stabilité, une vision pratique des choses et conservent la tradition. Elles offrent leur façon concrète de s’attaquer aux problèmes, leur capacité physique et leur expérience dans le domaine mécanique."
                    },
                    {
                      title: "Besoin en formation",
                      content:
                        "Les personnes de type « Réaliste » ont tout à gagner à développer ou améliorer leur sens de la communication. Par exemple, savoir féliciter pour un travail bien fait, tourner les phrases de façon plus positive (avec un peu plus de tact) ou se taire quand elles ont intérêt à le faire. Elles tirent surtout parti de formations qui mettent l’accent sur un axe principal de développement de leur organisation et qui comportent des exemples qui leur permettent d’anticiper les tendances futures. Des cours de formation dans le domaine des sciences humaines, du management, de l’apport créatif aux résolutions de problèmes, de la communication et de la présentation peuvent représenter pour elles un bon investissement."
                    },
                    {
                      title: "Risques possibles",
                      content:
                        "Les relations avec leurs subordonnés, leurs pairs et leurs responsables hiérarchiques, prennent sur le plan personnel une allure de défi. Les personnes de type « Réaliste » risquent d’être vues comme réagissant trop vivement, comme peu sociables ou insensibles aux sentiments des autres. Elles peuvent s’emballer trop vivement sur des sujets qui les intéressent. Elles s’attachent parfois trop fortement à la tradition et négligent d’actualiser des équipements, des systèmes ou des politiques de personnel. Leur faible intérêt pour la recherche et le développement peut rendre leurs produits vieillots voir obsolètes."
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
            <div className={classes.space50} />

          </>
        ) : sessionStorage.getItem("firstLetter") === 'I' ?
          (
            <>
              <GridContainer>
                <GridItem xs={12} sm={8} md={8}>
                  <h5 className={classes.title}>DETAIL DE VOTRE PROFIL</h5>
                  <Accordion
                    active={0}
                    activeColor="rose"
                    collapses={[
                      {
                        title: "TYPE INVESTIGATEUR",
                        content:
                          "Les personnes de ce type souvent curieuses (“Comment Holland a-t-il procédé pour élaborer sa typologie ?“...). Ces individus expriment souvent le désir d’une meilleure connaissance des différents secteurs professionnels et apprécieront les démarches d’information et de consultation de documentations. Par contre, ils ont du mal à passer de la réflexion à l’action, c’est à dire à prendre des décisions concrètes. Une fois que les contours d’un projet auront été définis, elles devront s’efforcer de le finaliser et engager les démarches (inscriptions...) nécessaires à sa mise en place."
                      },
                      {
                        title: "Le Type INVESTIGATEUR",
                        content:
                          "Cette rubrique correspond au goût pour les activités intellectuelles, le monde des idées, la recherche de connaissances et les théories scientifiques. Les personnes qui ont un score élevé apprécient généralement résoudre des problèmes abstraits, analyser des données, et mener des études. Les métiers correspondants à cette rubrique sont variés : chercheur scientifique, physicien, astronome, sociologue, biologiste, généticien, médecin, pharmacien... Les personnes « Investigatrices » sont plutôt réservées, indépendantes, manifestent une grande curiosité intellectuelle et possèdent des aptitudes scientifiques."

                      },
                      {
                        title: "TYPE INTELLECTUEL SCIENTIFIQUE",
                        content:
                          "(au sens large) caractérisé par le goût de l’étude (qu’il préfère à l’action), ouvert, curieux, méthodique, le type intellectuel scientifique se plaît à observer et à expérimenter pour comprendre les phénomènes qui l’entourent, physiques, biologiques ou culturels ; plutôt méditatif qu’expansif, c’est un chercheur qui aurait tendance à se tenir un peu à l’écart de la vie publique."
                      },
                      {
                        title: "Valeurs/objectifs",
                        content:
                          "Les personnes de type « Investigateur » cherchent à utiliser leurs aptitudes analytiques ou théoriques pour résoudre les problèmes. Le succès se matérialise pour elles de deux façons différentes : au plan académique par des diplômes et des études poussées et au plan professionnel par des découvertes, des publications et le respect que leur témoignent leurs collègues et pairs. La puissance, le conformisme et l’autorité les attirent peu. Elles considèrent l’argent comme utile pour leur permettre d’acquérir des choses qu’elles aiment : des outils pour résoudre certains problèmes, des ordinateurs, des livres et manuels, des appareils de mesure."
                      },
                      {
                        title: "Vision du monde",
                        content:
                          "Les personnes de type « Investigateur » aiment regarder le monde comme observatrices et comme critiques. Elles aiment poser des questions et savoir le « pourquoi du comment ». Elles sont tournées vers le futur et les possibilités qu’il offre, plutôt que vers le passé et la tradition."
                      }
                    ]}
                  />
                  <h5 className={classes.title}>L'INDIVIDU</h5>
                  <Accordion
                    /*  active={0} */
                    activeColor="rose"
                    collapses={[
                      {
                        title: "Contributions et apports",
                        content:
                          "Les personnes de type « Investigateur » apportent aux organisations dans lesquelles elles travaillent des idées, des produits nouveaux, des réflexions stratégiques, des points de vue différents et leur capacité de résoudre des problèmes."
                      },
                      {
                        title: "Besoin en formation",
                        content:
                          "Les personnes de type investigateur peuvent améliorer leur aptitude à la gestion. Pour cela elles doivent toujours être tenues pour responsables des résultats qu’elles obtiennent et s’impliquer plus en tant que membre d’une équipe plutôt qu’en observatrices. Elles y gagnent en apprenant des modes de prise de décisions plus rapides et en étant plus simples dans leur façon de communiquer. Des cours de formation sur la confiance en soi, la fixation d’objectifs, l’autorité, la prise de décision, les techniques de présentation, la persuasion, le pouvoir et l’influence peuvent représenter pour elles un bon investissement."
                      },
                      {
                        title: "Risques possibles",
                        content:
                          "Bien que leurs idées ou solutions soient excellentes, les personnes de type « Investigateur » peuvent être inefficaces dans des organisations si elles ne surveillent pas ou ne développent pas leur côté « politique ». D’autre part, elles risquent de se voir reprocher de n’avoir pas les pieds sur terre, d’être indécises et insipides. Pour l’éviter, elles doivent apprendre à « vendre » leurs idées ou solutions à la direction générale. Elles peuvent proposer ou imaginer d’excellentes solutions, mais sans suivi, les applications pratiques qu’elles préconisent risquent de ne pas prendre corps."
                      }
                    ]}
                  />
                </GridItem>
              </GridContainer>
              <div className={classes.space50} />
            </>
          ) : sessionStorage.getItem("firstLetter") === 'A' ?
            (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={8} md={8}>
                    <h5 className={classes.title}>DETAIL DE VOTRE PROFIL</h5>
                    <Accordion
                      active={0}
                      activeColor="rose"
                      collapses={[
                        {
                          title: "TYPE ARTISTIQUE",
                          content:
                            "Les personnes de ce type manquent souvent de confiance en elles. Elles devront se forger une image un peu plus positive d’elles-mêmes. Ces individus sont par ailleurs souvent déchirés entre leurs aspirations personnelles et les contraintes du monde réel qui nécessitent une recherche d’activité plus lucrative que la vie d’artiste. A cela s’ajoute fréquemment des conflits avec leur entourage (parents...) qui est moins convaincu qu’eux de leur vocation et leur talent, et les encourage à des choix plus rationnels. Il faudra les amener à dépister, à partir du profil, des activités professionnelles permettant de satisfaire à la fois leurs intérêts artistiques et leurs besoins matériels (graphisme, rédaction dans un journal d’entreprise...)."
                        },
                        {
                          title: "Le Type ARTISTIQUE",
                          content:
                            "Cette rubrique correspond au goût pour les arts et toutes les activités qui autorisent une libre expression de soi : jouer la comédie, créer, imaginer, écrire, décorer... Les personnes ayant un score élevé dans cette rubrique n’aiment pas la routine et les environnements contraignants (horaires fixes, emploi du temps rigide, hiérarchie omniprésente). Elles peuvent s’épanouir dans des lieux de travail variés: théâtre, musée, librairie, agence publicitaire, galerie d’art... Les métiers correspondant au type “Artistique” peuvent être musicien (compositeur/interprète), danseur, photographe, dessinateur, décorateur, bijoutier, maquilleur, journaliste, écrivain, historien, éditeur... Les personnes “Artistes” sont sensibles, imaginatives, impulsives, anticonformistes et indépendantes. Les personnes de ce type aiment les activités qui leur permettent de s’exprimer librement, à partir de leurs perceptions, de leur sensibilité et de leur intuition. Elles s’intéressent au travail de création, qu’il s’agisse d’art visuel, de littérature, de musique, de publicité ou de spectacle. D’esprit indépendant et non conformiste, elles sont à l’aise dans des situations qui sortent de l’ordinaire. Elles sont dotées d’une grande sensibilité et imagination. Bien qu’elles soient rebutées par les tâches méthodiques et routinières, elles sont néanmoins capables de travailler avec discipline. Ces personnes sont spontanées, expressives, imaginatives, émotives, indépendantes, originales, intuitives, passionnées, fières, flexibles, disciplinées."
                        }
                      ]}
                    />
                    <h5 className={classes.title}>AUTRES INFORMATIONS</h5>
                    <Accordion
/*               active={0}
 */              activeColor="rose"
                      collapses={[
                        {
                          title: "Types d’activités préférées",
                          content:
                            "Art, rédaction, domaine juridique, publicité, musique, photographie, émissions de radio, relations publiques, langues, art dramatique."
                        },
                        {
                          title: "Caractéristiques des individus de ce type",
                          content:
                            "Laissent paraître des émotions, créatifs, intuitifs, décontractés, tournés vers la gestion de projet, uniques, imprévisibles, et changeant en permanence, spontanés, aimant s’exprimer verbalement, visuels, sensibles, indépendants."
                        },
                        {
                          title: "Univers de travail",
                          content:
                            "Coloré, informel, peu structuré, imprévisible et changeant en permanence, avec rien de définitif et peu ou pas d’instructions précises."
                        }
                      ]}
                    />
                    <h5 className={classes.title}>DESCRIPTION GENERALE</h5>
                    <Accordion
/*               active={0}
 */              activeColor="rose"
                      collapses={[
                        {
                          title: "Description",
                          content:
                            "Les personnes de type « Artiste » sont créatives, intuitives, expressives et spontanées. Elles apprécient les tâches où elles peuvent utiliser leurs aptitudes visuelles et verbales. Elles aiment travailler plutôt seules, mais n’aiment pas être ignorées. Elles aiment retenir l’attention des autres et être reconnues pour leurs apports créatifs. Les personnes de type « Artiste » aiment la liberté et la spontanéité. Elles se sentent parfois étouffées lorsqu’elles se trouvent dans un environnement trop structuré. Les règles leur sont pesantes et les horaires trop réguliers et pénibles. Elles préfèrent travailler sur des projets de façon intense (sous forme de « charrettes ») souvent tard le soir ou très tôt le matin. Elles sont surtout productives dans un milieu non structuré et très souple."
                        },

                        {
                          title: "Valeurs/objectifs",
                          content:
                            "Les personnes du type « Artiste » cherchent à utiliser leurs aptitudes créatives pour produire quelque chose d’original. Leur succès se matérialise par la reconnaissance de la créativité de leur production. Leur objectif est d’être reconnues pour un travail créatif qui est unique, spécial ou inhabituel. L’argent leur est utile pour acquérir des objets d’art, des livres ou des disques."
                        },
                        {
                          title: "Vision du monde",
                          content:
                            "Les personnes de type « Artiste » ont tendance à regarder le monde sous un angle émotionnel et intuitif qu’elles ne peuvent pas facilement expliquer aux autres. Leur inonde est plus visuel qu’analytique et logique. Comme les personnes de type « Investigateur », elles ont tendance à être plutôt observatrices face au monde et à utiliser cette capacité d’observation et d’intuition pour créer des choses nouvelles."
                        }
                      ]}
                    />
                    <h5 className={classes.title}>L'INDIVIDU</h5>
                    <Accordion
                      /*  active={0} */
                      activeColor="rose"
                      collapses={[
                        {
                          title: "Contributions et apports",
                          content:
                            "Les personnes de type « Artiste » apportent à l’organisation de la couleur, de la créativité, de l’originalité et des sentiments. Elles sont capables de bien communiquer et peuvent communiquer aux autres leur enthousiasme pour les produits ou les services qu’offre cette organisation. Elles permettent souvent de regarder d’une façon nouvelle la vie de la société dans laquelle elles se trouvent."
                        },
                        {
                          title: "Besoin en formation",
                          content:
                            "Pour que leurs collègues ou leurs responsables hiérarchiques comprennent mieux leur travail, les personnes de type « Artiste » doivent apprendre à présenter leurs idées ou leurs créations de façon plus logique et plus analytique. Une meilleure organisation personnelle et une planification plus précise leur apporteraient plus que l’attente d’une certaine inspiration. Un besoin de formation se fait parfois sentir dans le domaine de la communication, du tact et de la diplomatie dans leurs relations avec les autres. Des cours de développement personnel dans le domaine de la gestion du temps, la planification de projets ou la gestion financière peuvent s’avérer utiles."
                        },
                        {
                          title: "Risques possibles",
                          content:
                            "Alors que les personnes de type « Artiste » excellent dans la mise au point des grandes lignes d’un projet, elles peuvent s’ennuyer et devenir impatientes une fois que leurs idées ont été acceptées. Si elles n’y prennent pas garde, elles risquent de ne pas surveiller les détails. Elles peuvent être considérées comme difficiles à diriger à cause de leur besoin de liberté. Elles ont tendance à ne pas respecter la tradition et les usages, ce qui risque de perturber et inquiéter ceux qui - autour d’elles - sont plus traditionnels."
                        }
                      ]}
                    />
                  </GridItem>
                </GridContainer>
                <div className={classes.space50} />
              </>

            ) : sessionStorage.getItem("firstLetter") === 'S' ?
              (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <h5 className={classes.title}>DETAIL DE VOTRE PROFIL</h5>
                      <Accordion
                        active={0}
                        activeColor="rose"
                        collapses={[
                          {
                            title: "TYPE SOCIAL",
                            content:
                              "Les personnes de ce type aiment beaucoup les relations sociales. Elles sont à l’aise pour parler d’elles-mêmes, raconter leur vie et expliciter les points de cohérence entre celle-ci et les résultats obtenus aux différents tests. Elles manquent souvent d’organisation, de pragmatisme, et il sera donc nécessaire de les focaliser sur des objectifs précis à court terme et de baliser les différentes étapes à suivre pour qu’elles progressent efficacement."
                          },
                          {
                            title: "Le Type SOCIAL",
                            content:
                              "Cette rubrique correspond au goût pour les activités relationnelles, avec l’objectif d’aider, d’informer, de conseiller, d’apprendre aux autres. Les personnes appartenant à cette rubrique peuvent s’épanouir dans des structures « Humanitaires » : écoles ou centres de formation, associations d’aide au tiers-Monde... Les métiers correspondant au type « Social » sont éducateur, travailleur social, orthophoniste, psychanalyste, conseiller d’orientation, instituteur, animateur de club de vacances... Les personnes « Sociales » sont souvent coopératives, généreuses, altruistes, idéalistes et optimistes. Elles sont également amicales, compréhensives, généreuses, sensibles aux problèmes de leurs semblables. Le type social s’attache avant tout à aider, éduquer, informer, soigner ; il a tendance, cependant, à éviter - sinon à rejeter - les activités de caractère technique ou administratif. Les personnes de ce type aiment être en contact avec les autres dans le but de les aider, de les informer, de les éduquer, de les divertir, de les soigner ou encore de favoriser leur croissance. Elles s’intéressent aux comportements humains et sont soucieuses de la qualité de leurs relations avec les autres. Elles utilisent leur savoir ainsi que leurs impressions et leurs émotions pour agir et pour interagir. Elles aiment communiquer et s’expriment facilement. Ces personnes sont attentives aux autres, coopératives, collaboratrices, compréhensives, dévouées, sensibles, sympathiques, perspicaces, bienveillantes, communicatives, encourageantes."
                          }
                        ]}
                      />

                      <h5 className={classes.title}>DESCRIPTION GENERALE</h5>
                      <Accordion
/*               active={0}
*/              activeColor="rose"
                        collapses={[
                          {
                            title: "Valeurs/objectifs",
                            content:
                              "Les personnes de type « Social » cherchent à utiliser leurs aptitudes dans les relations interpersonnelles pour réduire les conflits et aider les autres à travailler ensemble pour le bien de tous. Pour ce type de personnes le succès se mesure en termes de valeur morale et humaniste. Les relations qu’elles établissent avec les autres et l’aide qu’elles peuvent apporter aux moins chanceux sont, à leurs yeux, importantes. Le succès n’est pas aussi primordial pour elles que la possibilité qu’elles ont d’agir avec les autres. Elles peuvent sacrifier leurs propres besoins et objectifs si elles pensent qu’un groupe en bénéficiera. Elles trouvent l’argent utile pour financer des stages, acheter des livres sur le développement personnel ou participer à une cause philanthropique dans laquelle elles croient."
                          },
                          {
                            title: "Vision du monde",
                            content:
                              "Les personnes de type « Social » recherchent le potentiel dans chaque individu. Elles déterminent et apprécient le bon côté des autres et sont assez soucieuses de la façon dont le monde les considère. Elles ont souvent un puissant besoin de se sentir nécessaires et acceptées et peuvent se décourager si elles s’estiment rejetées ou non désirées."
                          }
                        ]}
                      />
                      <h5 className={classes.title}>L'INDIVIDU</h5>
                      <Accordion
                        /*  active={0} */
                        activeColor="rose"
                        collapses={[
                          {
                            title: "Contributions",
                            content:
                              "Les personnes de type « Social » sont les plus aptes à comprendre les relations humaines. Ce sont de bonnes enseignantes ou formatrices ; elles comprennent ou perçoivent les sentiments des employés et peuvent les communiquer à la direction d’une façon positive."
                          },
                          {
                            title: "Besoin en formation",
                            content:
                              "Les personnes de type « Social » sont souvent très désireuses de suivre des cours de développement personnel. Les domaines qu’elles doivent approfondir sont les aspects « pratico-pratiques » de leur organisation. Elles peuvent avoir à apprendre à évaluer l’efficacité ou la performance des autres d’une façon plus objective. Il leur faut aussi mieux savoir prendre les décisions nécessaires et difficiles du monde des affaires. Des cours de développement dans la gestion des conflits, l’évaluation de performance, l’aptitude à la gestion des affaires ou des finances et la confiance ou l’affirmation de soi peuvent représenter pour elles un bon investissement."
                          },
                          {
                            title: "Risques possibles",
                            content:
                              "Les personnes de type « Social » peuvent être perçues comme n’ayant pas du tout le sens des affaires et plus concernées par les individus que par les tâches à remplir. Elles ont tendance à en faire un peu trop pour leurs subordonnés et, au bout du compte, à ne pas être payées de retour. Elles risquent de mettre trop de temps à prendre des décisions personnelles difficiles lorsque les affaires vont mal."
                          }
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                  <div className={classes.space50} />
                </>

              ) : sessionStorage.getItem("firstLetter") === 'E' ?
                (
                  <><GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <h5 className={classes.title}>DETAIL DE VOTRE PROFIL</h5>
                      <Accordion
                        active={0}
                        activeColor="rose"
                        collapses={[
                          {
                            title: "TYPE ENTREPRENANT",
                            content:
                              "Les personnes de ce type sont généralement très à l’aise sur le plan relationnel et ne rencontrent donc aucune difficulté à s’exprimer. Ce sont des individus sûrs d’eux, qui aiment l’action, et auront tendance à prendre des décisions un peu trop rapidement, sans y avoir suffisamment réfléchi, ni même s’être suffisamment informé. Ces personnes feront souvent des choix d’orientation se basant davantage sur les perspectives de carrière offertes que sur le contenu et le vécu au quotidien du métier envisagé, ce sur quoi il faudra les amener à réfléchir. L'orientation « entrepreneuriale » (E) est celle d’individus habiles sur le plan verbal, mais qui utilisent moins leur habileté pour soutenir, aider ou encourager les autres que pour les manipuler et les dominer. Ils s’intéressent au pouvoir et à l’Etat, en ce qu’ils aspirent à détenir les leviers de commande auxquels les autres se soumettent."
                          },
                          {
                            title: "Le Type ENTREPRENANT",
                            content:
                              "Les personnes de ce type aiment influencer leur entourage. Leur capacité de décision, le sens de l’organisation et une habileté particulière à communiquer leur enthousiasme les appuient dans leurs objectifs. Elles savent vendre des idées autant que des biens matériels. Elles ont le sens de l’organisation, de la planification et de l’initiative et savent mener à bien leurs projets. Elles savent faire preuve d’audace et d’efficacité. Ces personnes sont persuasives, énergiques, optimistes, audacieuses, sûres d’elles-mêmes, ambitieuses, déterminées, diplomates, débrouillardes, sociables."
                          }
                        ]}
                      />
                      <h5 className={classes.title}>AUTRES INFORMATIONS</h5>
                      <Accordion
/*               active={0}
 */              activeColor="rose"
                        collapses={[
                          {
                            title: "Types d’activités préférées",
                            content:
                              "Vente, marketing, distribution, immobilier, gestion, finance, achat, service public, politique, administration."
                          },
                          {
                            title: "Caractéristiques des individus de ce type",
                            content:
                              "Sûrs d’eux, décidés, meneurs, attirés par le pouvoir, ayant l’esprit de compétition, ambitieux, perspicaces, rapides, preneurs de risque, ayant confiance en eux, énergiques, réalisateurs, ayant du flair."
                          },
                          {
                            title: "Univers de travail",
                            content:
                              "Dynamique, structuré, riche, « qui en jette », concurrentiel, avec la possibilité d’assurer des commandements ou d’avoir un impact sur les choses ou les gens."
                          }
                        ]}
                      />
                      <h5 className={classes.title}>DESCRIPTION GENERALE</h5>
                      <Accordion
/*               active={0}
 */              activeColor="rose"
                        collapses={[
                          {
                            title: "Description",
                            content:
                              "Les personnes de type « Entreprenant » sont énergiques, ambitieuses et rapides. Elles apprécient le pouvoir, le statut social et la richesse. Elles peuvent être très sûres d’elles-mêmes et persuasives. Elles sont prêtes à prendre des décisions et à les mettre en application. Elles passent rapidement à l’action, aiment les défis, le risque, l’aventure et la concurrence. En général, elles n’aiment pas la précision et le travail de détail. Elles passent habituellement peu de temps à fouiller un problème et préfèrent une analyse très rapide suivie d’action. Elles peuvent même oser mettre les autres au défi de démontrer qu’elles bluffent."
                          },

                          {
                            title: "Valeurs/objectifs",
                            content:
                              "Les personnes de type « Entreprenant » cherchent à utiliser leur aptitude à persuader et à organiser pour se mettre- en avant ou pour promouvoir leurs projets là où elles travaillent. Elles mesurent le succès en termes de reconnaissance, de pouvoir, d’argent et de possession. Elles risquent de ne pas être satisfaites des succès qu’elles obtiennent et sont sans cesse à la recherche de victoires nouvelles. L’argent leur sert à acquérir des choses de qualité, de prestige et un statut social."
                          },
                          {
                            title: "Vision du monde",
                            content:
                              "Les personnes de type « Entreprenant » scrutent le monde et cherchent à savoir qui a le pouvoir, la richesse et le statut social. Pour elles, la position sociale prend le pas sur la fonction et « ceux qu’elles connaissent » peut être aussi important que « ce qu’elles font ». Elles recherchent des opportunités dans des domaines où elles pensent qu’elles peuvent prendre en charge les changements qu’elles jugent nécessaires."
                          }
                        ]}
                      />
                      <h5 className={classes.title}>L'INDIVIDU</h5>
                      <Accordion
                        /*  active={0} */
                        activeColor="rose"
                        collapses={[
                          {
                            title: "Contributions et apports",
                            content:
                              "Les personnes de type « Entreprenant » portent à leur organisation leur dynamisme, leur caractère de meneur, leur optimisme, leur détermination et leur sens du contrôle. Elles sentent bien l’atmosphère politique d’une organisation et savent faire adopter leurs idées. Elles s’y prennent souvent bien pour faire aboutir les choses."
                          },
                          {
                            title: "Besoin en formation",
                            content:
                              "Les personnes de type « Entreprenant » ont surtout besoin d’apprendre à réfléchir sur les conséquences lointaines de leurs décisions avant de passer immédiatement à l’action. Elles peuvent avoir besoin d’affiner leurs talents techniques dans le domaine de la gestion, de la planification financière et du marketing ainsi que l’art de la négociation et du compromis. Des cours en management participatif, en écoute active, en résolution de problèmes, en planification et en négociation peuvent représenter pour elles un investissement valable."
                          },
                          {
                            title: "Risques possibles",
                            content:
                              "Les personnes de type « Entreprenant » peuvent sembler impatientes ou agressives. Elles risquent de trop se reposer sur leurs intuitions et ne pas préparer rationnellement leurs décisions. Il leur arrive de prendre des décisions qu’elles regrettent plus tard. Les personnes de type « Entreprenant » ont tendance à aimer leur travail et à passer plus de temps à travailler qu’à faire autre chose. Un bon programme de culture physique ou de fitness peut leur être utile pour les aider à dominer leur stress."
                          }
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                    <div className={classes.space50} />
                  </>

                ) : sessionStorage.getItem("firstLetter") === 'C' ?
                  (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={8} md={8}>
                          <h5 className={classes.title}>DETAIL DE VOTRE PROFIL</h5>
                          <Accordion
                            active={0}
                            activeColor="rose"
                            collapses={[
                              {
                                title: "TYPE CONVENTIONNEL",
                                content:
                                  "Les personnes de ce type préfèrent les situations structurées, organisées, planifiées à l’avance. Ces personnes souhaiteront avant de s’orienter vers une formation ou une profession en connaître les différents aspects afin de pouvoir en soupeser les avantages et inconvénients. L’orientation conventionnelle (C) correspond au goût des règles, de l’ordre, à l’autocontrôle. Les individus ayant cette orientation aiment et apprécient les situations bien structurées et recherchent les situations interpersonnelles et professionnelles où les structures sont bien définies."
                              },
                              {
                                title: "Le Type CONVENTIONNEL",
                                content:
                                  "Les personnes de ce type ont une préférence pour les activités précises, méthodiques, axées sur un résultat prévisible. Elles se préoccupent de l’ordre et de la bonne organisation matérielle de leur environnement. Elles préfèrent se conformer à des conventions bien établies et à des consignes claires plutôt que d’agir avec improvisation. Elles aiment calculer, classer, tenir à jour des registres ou des dossiers. Elles sont efficaces dans tout travail qui exige de l’exactitude et à l’aise dans les tâches routinières. Ces personnes sont loyales, organisées, efficaces, respectueuses de l’autorité, perfectionnistes, raisonnables, consciencieuses, ponctuelles, discrètes, strictes."
                              }
                            ]}
                          />
                          <h5 className={classes.title}>AUTRES INFORMATIONS</h5>
                          <Accordion
/*               active={0}
 */              activeColor="rose"
                            collapses={[
                              {
                                title: "Types d’activités préférées",
                                content:
                                  "Types d’activités préférées : comptabilité, tenue des livres, banque, service des impôts, contentieux, activités administratives, entretien et nettoyage, restauration, secrétariat, tenue des comptes."
                              },
                              {
                                title: "Caractéristiques des individus de ce type",
                                content:
                                  "Ordonnés, minutieux, organisés, efficaces, de confiance, travailleurs, stables, précis, prudents, consciencieux, exacts, méthodiques."
                              },
                              {
                                title: "Univers de travail",
                                content:
                                  "Organisé, bien documenté, précis, tâches bien définies et étayées, instructions précises, « une place pour chaque chose et chaque chose à sa place », heures régulières avec de bons avantages sociaux et la sécurité."
                              }
                            ]}
                          />
                          <h5 className={classes.title}>DESCRIPTION GENERALE</h5>
                          <Accordion
/*               active={0}
 */              activeColor="rose"
                            collapses={[
                              {
                                title: "Description",
                                content:
                                  "Les personnes de type « Conventionnel » sont consciencieuses, stables, régulières et précise. Elles excellent dans les tâches d’entretien ou de suivi. Elles ont tendance à préférer les méthodes sûres et éprouvées. Elles aiment les univers concrets, logiques, pratiques et dans lesquels les procédures et règles sont bien définies. Elles préfèrent travailler dans des bureaux où les horaires sont précis, où la formation est bonne et les avantages sociaux sûrs. Les personnes de type « Conventionnel » aiment savoir exactement ce qu’on attend d’elles, préfèrent des règles très précises qu’elles peuvent suivre avec un minimum de changement ou de dérangement. Elles travaillent bien sous surveillance étroite."
                              },

                              {
                                title: "Valeurs/objectifs",
                                content:
                                  "Les personnes de type « Conventionnel » recherchent un univers de travail qui fonctionne bien et sans à-coup. Elles aiment les environnements où elles peuvent utiliser leur goût pour les chiffres et donner au milieu dans lequel elles évoluent le sens de la stabilité, de l’ordre et de la prévision. Elles donnent peu d’importance au pouvoir, à la réalisation de changements ou à la prise de risques. Elles mettent de l’argent de côté pour les études, le logement ou pour réaliser des investissements de type « père de famille »."
                              },
                              {
                                title: "Vision du monde",
                                content:
                                  "Les personnes de type « Conventionnel » souhaitent que les choses scient claires, ordonnées et sûres. Comme les personnes de type « Réaliste », elles cherchent à maintenir les valeurs traditionnelles et sont toujours plutôt réservées face aux nouveautés. Si leurs méthodes de travail ont fait leurs preuves elles ont tendance à vouloir les conserver plutôt que d’en essayer de nouvelles..."
                              }
                            ]}
                          />
                          <h5 className={classes.title}>L'INDIVIDU</h5>
                          <Accordion
                            /*  active={0} */
                            activeColor="rose"
                            collapses={[
                              {
                                title: "Contributions et apports",
                                content:
                                  "Les personnes de type « Conventionnel » apportent à leur organisation leur efficacité, leur précision, leur soin du détail et une bonne disposition à suivre les procédures. On peut en général compter sur elles pour que les choses soient faites dans les temps."
                              },
                              {
                                title: "Besoin en formation",
                                content:
                                  "Les personnes de type « Conventionnel » risquent de résister au changement et d’ignorer systématiquement les nouvelles façons de faire les choses. Elles ont besoin d’être informées de l’évolution des marchés et des changements des besoins des clients. En raison de leur fidélité aux personnes et aux institutions, elles ne sont pas prédisposées à remettre en question la façon dont les choses se font. Des cours de développement dans la résolution créative de problèmes, la gestion du changement, l'affirmation de soi, la prise de risques, la prise de décision et la persuasion peuvent représenter pour elles un bon investissement."
                              },
                              {
                                title: "Risques possibles",
                                content:
                                  "En adoptant et en conservant un point de vue traditionnel, les personnes de type « Conventionnel » prennent le risque de se retrouver à l’écart. Leur façon de se reposer essentiellement sur l’expérience passée peut leur créer des problèmes si elles ne mettent pas à jour leur savoir-faire et ne se tiennent pas au courant de l’évolution des choses."
                              }
                            ]}
                          />
                        </GridItem>
                      </GridContainer>
                      <div className={classes.space50} />
                    </>

                  ) : (
                    <></>
                  )
      }
    </div>
  );
}
