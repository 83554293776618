/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import Checkbox from "@material-ui/core/Checkbox";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import Timeline from "@material-ui/icons/Timeline";
//import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
//import Face from "@material-ui/icons/Face";
//import Email from "@material-ui/icons/Email";
//import Check from "@material-ui/icons/Check";
//import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
//import { Link } from "react-router-dom";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(signupPageStyle);

export default function ReorientationPage() {
  const history = useHistory();
  const classes = useStyles();

  const [selectedChoice1, setSelectedChoice1] = useState([]); //reponse selectionnee

  const [selectedChoice2, setSelectedChoice2] = useState([]); //reponse selectionnee

  const [selectedChoice3, setSelectedChoice3] = useState([]); //reponse selectionnee

  const [selectedChoice4, setSelectedChoice4] = useState([]); //reponse selectionnee

  const [selectedChoice5, setSelectedChoice5] = useState([]); //reponse selectionnee

  const [message, resultCheckRequest] = useState([]);

  const submitQuestionnaireReorientation = async (event) => {
    try {
      event.preventDefault();
      event.persist();
         if(selectedChoice1.length===0){
        resultCheckRequest('information manquante question 1');
      return false;
      }
      if(selectedChoice2.length===0){
         resultCheckRequest('information manquante question 2');
      return false;
      }
      if(selectedChoice3.length===0){
         resultCheckRequest('information manquante question 3');
      return false;
      }
       if(selectedChoice4.length===0){
         resultCheckRequest('information manquante question 4');
      return false;
      }
       if(selectedChoice5.length===0){
         resultCheckRequest('information manquante question 5');
      return false;
      }
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/StartQuestionnaire_bilan.php`, {
        choice_q1: selectedChoice1,
        choice_q2: selectedChoice2,
        choice_q3: selectedChoice3,
        choice_q4: selectedChoice4,
        choice_q5: selectedChoice5,
        selectBilan: 1,//Reorientation
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            //console.log(res.data.session_id)
          // console.log(res.data.query)
            sessionStorage.setItem("SectionRiasec", 1);
           history.push("/riasec-page");
          }
          else {
            resultCheckRequest('Erreur!');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  /*   useEffect(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }); */

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <form className={classes.form} onSubmit={submitQuestionnaireReorientation}>

            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={10}>
                <Card>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}                  >
                    <h4 className={classes.cardTitle}>BILAN DE REORIENTATION</h4>
                    <div className={classes.iframeContainer}>
                      <iframe height="350" src="https://www.youtube.com/embed/0YmIAWPHcDM?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={5}>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="1) Pour quelle raison principale vous sentez-vous perdu(e) ?"
                          description=""
                          icon={Group}
                          iconColor="info"
                        />
                        <h5 className={classNames(classes.titleBlue, classes.shadowForText)}>Choisissez l'option qui correspond le plus à votre situation actuelle.</h5>
                        <div className={classes.spacePaddingS} ></div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire1"
                              value={1}
                              onClick={() => { setSelectedChoice1(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> A - Peur de l’inconnu</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire1"
                              value={2}
                              onClick={() => { setSelectedChoice1(2) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> B - Peur du changement</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire1"
                              value={3}
                              onClick={() => { setSelectedChoice1(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> C - Manque de confiance</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire1"
                              value={4}
                              onClick={() => { setSelectedChoice1(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> D - Syndrôme de l’imposteur</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_5"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire1"
                              value={5}
                              onClick={() => { setSelectedChoice1(5) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> E - Méconnaissance des différents métiers</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_6"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire1"
                              value={6}
                              onClick={() => { setSelectedChoice1(6) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> F - Je n'ai aucune idée de quel métier envisager</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_7"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire1"
                              value={7}
                              onClick={() => { setSelectedChoice1(7) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> G - Je ne sais pas de quoi je suis capable</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q1_8"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire1"
                              value={8}
                              onClick={() => { setSelectedChoice1(8) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> H - J'ai besoin d'analyser mes compétences professionnelles pour faire les bons choix</span>
                          </label>
                          <div className={classes.spacePaddingS}></div><hr></hr>
                        </div>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="2) A quel point souhaitez-vous changer de voie ?"
                          description=""
                          icon={Group}
                          iconColor="info"
                        />
                        <h5 className={classNames(classes.titleBlue, classes.shadowForText)}>Pas très motivé-e, 0, Extrêmement motivé-e, 10</h5>
                        <div className={classes.spacePaddingS} ></div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_0"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={0}
                              onClick={() => { setSelectedChoice2(0) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 0</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={1}
                              onClick={() => { setSelectedChoice2(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 1</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={2}
                              onClick={() => { setSelectedChoice2(2) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 2</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={3}
                              onClick={() => { setSelectedChoice2(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 3</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={4}
                              onClick={() => { setSelectedChoice2(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 4</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_5"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={5}
                              onClick={() => { setSelectedChoice2(5) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 5</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_6"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={6}
                              onClick={() => { setSelectedChoice2(6) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 6</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_7"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={7}
                              onClick={() => { setSelectedChoice2(7) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 7</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_8"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={8}
                              onClick={() => { setSelectedChoice2(8) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 8</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_9"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={9}
                              onClick={() => { setSelectedChoice2(9) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 9</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q2_10"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire2"
                              value={10}
                              onClick={() => { setSelectedChoice2(10) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 10</span>
                          </label>
                          <div className={classes.spacePaddingS}></div><hr></hr>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={5}>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="3) Si vous parveniez à changer de voie, à quel point pensez-vous que votre vie s’améliorerait ?"
                          description=""
                          icon={Group}
                          iconColor="info"
                        />
                        <h5 className={classNames(classes.titleBlue, classes.shadowForText)}>Peu de changement, 0, Amélioration importante, 10</h5>
                        <div className={classes.spacePaddingS} ></div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_0"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={0}
                              onClick={() => { setSelectedChoice3(0) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 0</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={1}
                              onClick={() => { setSelectedChoice3(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 1</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={2}
                              onClick={() => { setSelectedChoice3(2) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 2</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={3}
                              onClick={() => { setSelectedChoice3(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 3</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={4}
                              onClick={() => { setSelectedChoice3(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 4</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_5"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={5}
                              onClick={() => { setSelectedChoice3(5) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 5</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_6"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={6}
                              onClick={() => { setSelectedChoice3(6) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 6</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_7"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={7}
                              onClick={() => { setSelectedChoice3(7) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 7</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_8"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={8}
                              onClick={() => { setSelectedChoice3(8) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 8</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_9"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={9}
                              onClick={() => { setSelectedChoice3(9) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 9</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div> <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q3_10"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire3"
                              value={10}
                              onClick={() => { setSelectedChoice3(10) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> 10</span>
                          </label>
                          <div className={classes.spacePaddingS}></div><hr></hr>
                        </div>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="4) Depuis combien de temps travaillez-vous ?"
                          description=""
                          icon={Group}
                          iconColor="info"
                        />
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q4_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire4"
                              value={1}
                              onClick={() => { setSelectedChoice4(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> A - Moins de 2 ans</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q4_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire4"
                              value={2}
                              onClick={() => { setSelectedChoice4(2) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> B - 2 à 4 ans</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q4_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire4"
                              value={3}
                              onClick={() => { setSelectedChoice4(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> C - 5 à 10 ans</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q4_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire4"
                              value={4}
                              onClick={() => { setSelectedChoice4(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> D - Plus de 10 ans</span>
                          </label>
                          <div className={classes.spacePaddingS}></div><hr></hr>
                        </div>
                        <InfoArea
                          className={classNames(classes.infoArea, classes.shadowForText)}
                          title="5) Quel est votre niveau d’études ?"
                          description=""
                          icon={Group}
                          iconColor="info"
                        />
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_1"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={1}
                              onClick={() => { setSelectedChoice5(1) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> A - Sans diplôme</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_2"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={2}
                              onClick={() => { setSelectedChoice5(2) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> B - CAP</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_3"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={3}
                              onClick={() => { setSelectedChoice5(3) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> C - BEP</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_4"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={4}
                              onClick={() => { setSelectedChoice5(4) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> D - Baccalauréat</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_5"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={5}
                              onClick={() => { setSelectedChoice5(5) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> E - Bac +2</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_6"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={6}
                              onClick={() => { setSelectedChoice5(6) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> F - Licence</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_7"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={7}
                              onClick={() => { setSelectedChoice5(7) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> G - Master 1</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_8"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={4}
                              onClick={() => { setSelectedChoice5(8) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> H - Master 2</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          } key={"q5_9"}
                        >
                          <label classes={{ label: classes.label, root: classes.labelRoot }}>
                            <input
                              type="radio"
                              name="Questionnaire5"
                              value={9}
                              onClick={() => { setSelectedChoice5(9) }}
                            />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}> I - Doctorat</span>
                          </label>
                          <div className={classes.spacePaddingS} ></div>
                        </div>
                        <div className={classes.spacePaddingS}></div>



                      </GridItem>
                    </GridContainer>

                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <Card>
                  <CardBody signup>
                    <div className={classes.spacePaddingS} />
                    <div className={classes.textCenter}>
                      <Button type="submit" size="sm" round color="primary">
                        Continuons vers le RIASEC
                      </Button>
                      <div className={classes.spacePaddingS} />
                      <h6 className={classes.textWarning}>{message}</h6>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </form>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
