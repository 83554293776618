/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
/* import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js"; */
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
/* import { Link } from "react-router-dom";
import { TextField } from '@material-ui/core/';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio"; */
import conectPageStyle from "assets/jss/material-kit-pro-react/views/conectPageStyle.js";
import "./style.css";


const useStyles = makeStyles(conectPageStyle);

export default function QuestionairePage() {
  const classes = useStyles();
  const history = useHistory();
  const [selectedChoice1, setSelectedChoice1] = useState([]); //reponse selectionnee

  const [selectedChoice2, setSelectedChoice2] = useState([]); //reponse selectionnee

  const [selectedChoice3, setSelectedChoice3] = useState([]); //reponse selectionnee

  const [message, resultCheckRequest] = useState([]);

  const submitQuestionnaire = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      //sessionStorage.clear();
      if (selectedChoice1.length === 0) {
        resultCheckRequest('Renseignez votre situation');
        return false;
      }
      if (selectedChoice2.length === 0) {
        resultCheckRequest('Renseignez votre catégorie socioprofessionnelle');
        return false;
      }
      if (selectedChoice3.length === 0) {
        resultCheckRequest("Renseignez votre votre tranche d'âge");
        return false;
      }
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SaveQuestionnaire_diagnostic.php`, {
        choice_q1: selectedChoice1,
        choice_q2: selectedChoice2,
        choice_q3: selectedChoice3,
      })
        .then(res => {
          if (res.data.success === true) {
            sessionStorage.setItem("id_ind", res.data.id_ind);
            sessionStorage.setItem("session_id", res.data.session_id);
            if (selectedChoice1 === 1)
              history.push("/bilanDeReorientation-page");
            else if (selectedChoice1 === 2)
              history.push("/bilanDeEvolution-page");
            else if (selectedChoice1 === 3)
              history.push("/validationDeProjet-page");
            else
              resultCheckRequest('Renseignements manquants');
          }
          else {
            resultCheckRequest('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  if (!sessionStorage.getItem("statut_clt")) {
    return (
      <div>
        <div className={classes.container}>
          <form className={classes.form} onSubmit={submitQuestionnaire}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <Card>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Enchantés, faisons connaissance ! </h4>
                    <div className={classes.iframeContainer}>

                      <iframe height="350" src="https://www.youtube.com/embed/hfBYz96AV_c?version=3&loop=2&rel=0&controls=1&showinfo=0" title="Bienvenue" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                    </div>
                  </CardHeader>
                  <CardBody signup>
                    <h5 className={classNames(classes.titleBlue, classes.shadowForText)}>Quelle est votre situation actuelle ?</h5>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q1_1"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire1"
                          value={1}
                          onClick={() => { setSelectedChoice1(1) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>A - J'aimerais changer de voie, mais je suis un peu perdu(e)</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    {/*  <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q1_2"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire1"
                          value={2}
                          onClick={() => { setSelectedChoice1(2) }}
                        />{""}  <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>B - Je veux savoir comment ajuster mon rôle dans mon entreprise actuelle ou évoluer dans mon domaine</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q1_3"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire1"
                          value={3}
                          onClick={() => { setSelectedChoice1(3) }}
                        />{""}  <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>C - J'ai une idée précise de ce que je veux faire et je veux valider mon projet professionnel</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div> */}
                    <div className={classes.spacePaddingS} ></div>
                    <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Quelle est votre catégorie socioprofessionnelle ?</h5>

                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q2_1"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire2"
                          value={1}
                          onClick={() => { setSelectedChoice2(1) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>A - Salarié(e) du privé</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q2_2"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire2"
                          value={2}
                          onClick={() => { setSelectedChoice2(2) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>B - Salarié(e) du public</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q2_3"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire2"
                          value={3}
                          onClick={() => { setSelectedChoice2(3) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>C - A mon compte</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q2_4"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire2"
                          value={4}
                          onClick={() => { setSelectedChoice2(4) }}
                        />{""}
                        <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>D - Demandeur(se) d’emploi</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div className={classes.spacePaddingS} ></div>
                    <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}> Merci de nous indiquer votre tranche d'âge</h5>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q3_1"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire3"
                          value={1}
                          onClick={() => { setSelectedChoice3(1) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>18/25</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q3_2"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire3"
                          value={2}
                          onClick={() => { setSelectedChoice3(2) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>26/35</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q3_3"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire3"
                          value={3}
                          onClick={() => { setSelectedChoice3(3) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>36/45</span>
                      </label>

                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q3_4"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire3"
                          value={4}
                          onClick={() => { setSelectedChoice3(4) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>46/55</span>
                      </label>
                      <div className={classes.spacePaddingS} ></div>
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      } key={"q3_5"}
                    >
                      <label classes={{ label: classes.label, root: classes.labelRoot }}>
                        <input
                          type="radio"
                          name="Questionnaire3"
                          value={4}
                          onClick={() => { setSelectedChoice3(5) }}
                        />{""} <span className={classNames(classes.titleGray, classes.shadowForSmallText)}>56/65</span>
                      </label>
                      <div className={classes.spacePaddingS} ></div>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <Card>
                  <CardBody signup>
                    <div className={classes.spacePaddingS} />
                    <div className={classes.textCenter}>
                      <Button type="submit" size="sm" round color="info">
                        Suivant
                      </Button>
                      <div className={classes.spacePaddingS} />
                      <h6 className={classes.textWarning}>{message}</h6>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </form>
        </div>
      </div>
    );
  }
  else {
    return false;
  }
}
