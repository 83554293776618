import { container, title, infoColor, grayColor, blackColor, warningColor, hexToRgb } from "assets/jss/material-kit-pro-react.js";

const tabsStyle = {
  section: {
    padding: "70px 0"
  },
  container,
  textCenter: {
    textAlign: "center"
  },
  spacePaddingS: {
    paddingBottom : "20px",
  },
  spacePaddingM: {
    paddingBottom : "50px",
  },
  spacePaddingL: {
    paddingBottom : "100px",
  },
  spacePaddingXL: {
    paddingBottom : "200px",
  },
  titleBlue: {
    ...title,
    color: infoColor[0] 
  },
  titleGray: {
    ...title,
    color: grayColor[0] 
  },
  textBlack: {
    fontWeight: "500",
    color: "#000",
  },
  shadowForText: {  
    textShadow: "0 2px 3px rgba(" + hexToRgb(grayColor[9]) + ",0.3)"
  },
  shadowForSmallText: {  
    textShadow: "0 1px 2px rgba(" + hexToRgb(grayColor[9]) + ",0.2)"
  },
  shadowForButton: {  
    boxShadow: "0 3px 5px rgba(" + hexToRgb(grayColor[9]) + ",0.5)"
  },
  titleWarning: {
    ...title,
    color: warningColor[0] 
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px"
  },
};

export default tabsStyle;
