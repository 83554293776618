import {
  container,
  description,
  cardTitle,
  blackColor,
  whiteColor,
  grayColor,
  dangerColor,
  warningColor,
  infoColor,
  hexToRgb,
  title
} from "assets/jss/material-kit-pro-react.js";

const signupPageStyle = theme => ({
  description,
  cardTitle: {
    ...cardTitle,
    color: whiteColor + "  !important"
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  pageHeader: {
    color: whiteColor,
    border: "0",
    height: "100%",
    margin: "0",
    display: "flex!important",
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: grayColor[13]
  },
  textBlue: {
    fontWeight: "500",
    color: infoColor[0]    
  },
  textWarning: {
    fontWeight: "500",
    color: dangerColor[0]
  },
  textCenter: {
    textAlign: "center"
  },
  iconButtons: {
    marginRight: "3px !important",
    marginLeft: "3px !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  footer: {
    position: "absolute",
    width: "100%",
    background: "transparent",
    bottom: "0",
    color: whiteColor,
    zIndex: "2"
  },
  spacePaddingS: {
    paddingBottom : "20px",
  },
  spacePaddingM: {
    paddingBottom : "50px",
  },
  spacePaddingL: {
    paddingBottom : "100px",
  },
  spacePaddingXL: {
    paddingBottom : "200px",
  },
    textBlack: {
    fontWeight: "500",
    color: "#000",
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  titleBlue: {
    ...title,
    color: infoColor[0] 
  },
  titleGray: {
    ...title,
    color: grayColor[1] 
  },
  titleWarning: {
    ...title,
    color: warningColor[0] 
  },
  shadowForText: {  
    textShadow: "0 2px 3px rgba(" + hexToRgb(grayColor[9]) + ",0.3)"
  },
  shadowForSmallText: {  
    textShadow: "0 1px 2px rgba(" + hexToRgb(grayColor[9]) + ",0.2)"
  },
  shadowForButton: {  
    boxShadow: "0 3px 5px rgba(" + hexToRgb(grayColor[9]) + ",0.5)"
  }
});

export default signupPageStyle;
