import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Subject from "@material-ui/icons/Subject";
// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import { makeStyles } from "@material-ui/core/styles";
//import AttachFile from "@material-ui/icons/AttachFile";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
//const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(signupPageStyle);

export default function InteretsPro() {
  const classes = useStyles();
  const history = useHistory();

  const [checked, setChecked] = useState([]);//
  console.log(checked);//le tableau qui collecte les idIpfSelect

  const [count, setCount] = useState(0);//le compteur

  /* const handleCheckboxAddCount = () => {
    setCount(count + 1);
  };

  const handleCheckBoxRemoveCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  }; */

 


  let nbrR = sessionStorage.getItem("R");
  let nbrI = sessionStorage.getItem("I");
  let nbrA = sessionStorage.getItem("A");
  let nbrS = sessionStorage.getItem("S");
  let nbrE = sessionStorage.getItem("E");
  let nbrC = sessionStorage.getItem("C");

  const [numbers, setNumbers] = useState([
    { letter: 'R', value: nbrR, color: "#ffa21a", qualite: "REALISTE" },
    { letter: 'I', value: nbrI, color: "#00d3ee", qualite: "INVESTIGATEUR" },
    { letter: 'A', value: nbrA, color: "#af2cc5", qualite: "ARTISTIQUE" },
    { letter: 'S', value: nbrS, color: "#5cb860", qualite: "SOCIAL" },
    { letter: 'E', value: nbrE, color: "#f55a4e", qualite: "ENTREPRENANT" },
    { letter: 'C', value: nbrC, color: "#CCCD", qualite: "CONVENTIONNEL" },
  ]);




  const firstIndex = useMemo(() => numbers[0], [numbers]);

  const [isMetierData, setMetierData] = useState([]);
  const [isVerbeAction, setVerbeAction] = useState([]);
  const [isVerbeTitle, setVerbeTitle] = useState([]);

  const [isSavoirFaire, setSavoirFaire] = useState([]);
  const [isValeurproData, setValeurproData] = useState([]);
  const [isMotivationData, setMotivationData] = useState([]);

  /* const [isRecordCount, setRecordCount] = useState(0);
  const [isRecordCount2, setRecordCount2] = useState(0); */



  const getPortefeuillePro = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPortefeuilleProfessionnel.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          console.log(res.data.RecordCount)

            console.log(res.data.RecordCount2)
          if (res.data.success === true) {
           /*  setRecordCount(res.data.RecordCount);
            setRecordCount2(res.data.RecordCount2); */
            setVerbeAction(res.data.verbeData);
            setVerbeTitle(res.data.verbeTitle);

            setSavoirFaire(res.data.savoirfaireData);
          }
          else {
           /*  setRecordCount(0);
            setRecordCount2(0); */

            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getResultSynthese = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/ResultSynthese.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
           /*  console.log(res.data.resultmetierData)
            console.log(res.data.resultvaleurproData)
            console.log(res.data.resultmotivationData) */

            setMetierData(res.data.resultmetierData);
            setValeurproData(res.data.resultvaleurproData);
            setMotivationData(res.data.resultmotivationData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };





  useEffect(() => {
    getPortefeuillePro();
    getResultSynthese();
  }, []);

  return (
      <div>
       
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardSignup}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Ci-dessous retrouvez la liste de vos métiers retenus

                        </h4>
                        <SnackbarContent
                          style={{ backgroundColor: "#af2cc5" }}
                          message="METIER/VALEUR/MOTIVATION"
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>MA SELECTION METIERS </h5>
                      <Accordion
                        activeColor="info"
                        collapses={[
                          {
                            title: "Mes Métiers",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>

                                  {isMetierData.map((itemM) => (
                                    <>
                                      <div><h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>{itemM.metier}
                                        <a href={itemM.lien_metier}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        ><Button simple color="danger">
                                            <Subject /> Voir la fiche métier
                                          </Button>
                                        </a></h4>
                                      </div>
                                      <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Vos valeurs professionnelles retenues pour ce métier</h4>
                                      <ul>
                                      {isValeurproData.map((itemV) => (
                                        <>
                                          {itemM.id_met == itemV.id_met ?
                                            (<>
                                            <li>
                                              {itemV.texte_valeurpro}
                                              </li>
                                            </>) : (<></>)
                                          }
                                        </>
                                      ))}
                                      </ul>
                                      <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>Vos motivations retenues pour ce métier</h4>
                                      <ul>
                                      {isMotivationData.map((itemT) => (
                                        <>
                                          {itemM.id_met === itemT.id_met ?
                                            (<>
                                            <li>{itemT.texte_mtv}
                                            </li>
                                            </>) : (<></>)
                                          }
                                        </>
                                      ))}
                                       </ul>
                                      <hr></hr>
                                    </>
                                  ))}
                                </p>
                              )
                          }
                        ]}
                      />
                    </GridItem>
                  </GridContainer>


                  <div className={classes.space50} />

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Ci-dessous retrouvez la liste de vos compétences passées acquises (savoir-faire et savoir-être)

                        </h4>
                        <SnackbarContent
                          style={{ backgroundColor: "#e91e63" }}
                          message="VOS SAVOIR-FAIRE et SAVOIR-ETRE"
                        />
                      </div>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <h5 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>DETAIL DE VOS ACQUIS </h5>
                      <Accordion
                        activeColor="rose"
                        collapses={[
                          {
                            title: "Vos Savoir-faire",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>

                                  {isVerbeTitle.map((itemT) => (
                                    <>
                                      <h5 className={classNames(classes.titleWarning, classes.shadowForSmallText)}> {itemT.texte} </h5>

                                      {isVerbeAction.map((item) => (
                                        <>
                                          {item.id_cpf === itemT.id_cpf ?
                                            (<>
                                              <div> - {item.libelle} </div></>) : (<></>)
                                          }

                                        </>
                                      ))}
                                      <hr></hr>
                                    </>
                                  ))}
                                </p>
                              )
                          },
                          {
                            title: "Vos Savoir-être",
                            content:
                              (
                                <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>
                                  {isSavoirFaire.map((item) => (
                                    <>
                                      <div>- {item.libelle}</div>
                                      <hr></hr>
                                    </>
                                  ))}

                                </p>
                              )
                          }
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
               </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
         
        </div>        
        
  );
};


