import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ArrowForwardIconUp from '@material-ui/icons/ArrowUpwardOutlined';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardOutlined';
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Accordion from "components/Accordion/Accordion.js";


import SectionProfil from "views/PresentationPage/Sections/SectionDetailProfil.js";
import SectionProfilSecondaire from "views/PresentationPage/Sections/SectionDetailProfilSecondaire.js";

import SectionPricing from "views/PricingPage/Sections/SectionAbonnement.js";

import NavPills from "components/NavPills/NavPills.js";
//import styled from 'styled-components';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Check from "@material-ui/icons/Check";
//import Warning from "@material-ui/icons/Warning";
//import ArrowForwardIcon from '@material-ui/icons/ArrowDownwardOutlined';

//import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
//import AddIcon from "@material-ui/icons/Add";
//import Gesture from "@material-ui/icons/Gesture";
//import AccountCircle from "@material-ui/icons/AccountCircle";// core components
//import Clearfix from "components/Clearfix/Clearfix.js";
//import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";


//import Button from "components/CustomButtons/Button.js";
import image from "assets/img/bg7.jpg";
//const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(signupPageStyle);


export default function InteretsPro() {

  const classes = useStyles();
  const history = useHistory();

  const [checked, setChecked] = useState([]);//
  //console.log(checked);//le tableau qui collecte les idIpfSelect
  const [isMessage, setMessage] = useState('');

  const [isMessage1, setMessage1] = useState('');
  const [isMessage2, setMessage2] = useState('');
  const [isMessage3, setMessage3] = useState('');
  const [isMessage4, setMessage4] = useState('');
  const [isMessage5, setMessage5] = useState('');
  
  const [RecordCount, setRecordCount] = useState(0);//le compteur
  const [count, setCount] = useState(0);//le compteur

  const handleCheckboxAddCount = () => {
    setCount(count + 1);
  };

  const handleCheckBoxRemoveCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      handleCheckboxAddCount();
    } else {
      newChecked.splice(currentIndex, 1);
      handleCheckBoxRemoveCount();
    }
    setChecked(newChecked);
    // console.log(currentIndex)//montre le choix coché ou nom
    console.log(newChecked)//le tableau qui collecte les idIpfSelect
  };

  const recordAllIntPro = async () => {
    /*CATALOGUE*/
    console.log(checked)
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/SaveSelectionInteretProfessionnel.php`, {
        // idIpf: idIpf,
        arrayIdIpf: checked,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
             //console.log(res.data.query)
            //console.log('enregistré ok');
            //history.push("/valeurProfessionnelle-page");
            history.push("/catalogueProfessionnel-page");
            // smoothScroll('catalogue');
          }
          else {
            // console.log(res.data.query)

            console.log('erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  
  const checKSelection = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetSaveSelectionInteretProfessionnel.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
         /*  console.log(res.data)
          console.log(res.data.RecordCount); */
          if (res.data.success === true) {
            setMessage('');
             if (res.data.RecordCount === 5) {
              setRecordCount(res.data.RecordCount);
              setMessage("Vous avez déjà défini vos intérêts professionnels.");
              setMessage1(res.data.choice_s1);
              setMessage2(res.data.choice_s2);
              setMessage3(res.data.choice_s3);
              setMessage4(res.data.choice_s4);
              setMessage5(res.data.choice_s5);
            }
            else {
              setMessage("Définissez vos intérêts professionnels ci-dessous.")
            }
           return;
          }
          else {
            setMessage("Définissez vos intérêts professionnels ci-dessous.")
          }
          return;
        })
    } catch (error) { throw error; }
  };


  let nbrR = sessionStorage.getItem("R");
  let nbrI = sessionStorage.getItem("I");
  let nbrA = sessionStorage.getItem("A");
  let nbrS = sessionStorage.getItem("S");
  let nbrE = sessionStorage.getItem("E");
  let nbrC = sessionStorage.getItem("C");

  const [numbers, setNumbers] = useState([
    { letter: 'R', value: nbrR, color: "#ffa21a", qualite: "REALISTE" },
    { letter: 'I', value: nbrI, color: "#00d3ee", qualite: "INVESTIGATEUR" },
    { letter: 'A', value: nbrA, color: "#af2cc5", qualite: "ARTISTIQUE" },
    { letter: 'S', value: nbrS, color: "#5cb860", qualite: "SOCIAL" },
    { letter: 'E', value: nbrE, color: "#f55a4e", qualite: "ENTREPRENANT" },
    { letter: 'C', value: nbrC, color: "#CCCD", qualite: "CONVENTIONNEL" },
  ]);



  //const [isMemoriseProfil, setMemoriseProfil] = useState([]);

  const firstIndex = useMemo(() => numbers[0], [numbers]);

  const sortNumbers = () => {
    let sortedNumbers = [...numbers];
    sortedNumbers.sort((a, b) => b.value - a.value);
    setNumbers(sortedNumbers);
    sessionStorage.setItem("firstLetter", sortedNumbers[0].letter);
    sessionStorage.setItem("secondLetter", sortedNumbers[1].letter);

    resultatInteretPro(sortedNumbers[0].letter);
    resultatInteretPro2(sortedNumbers[1].letter);

  };

  const [isInteretProfessionnel, setInteretProfessionnel] = useState([]);
  const [isInteretProfessionnel2, setInteretProfessionnel2] = useState([]);

  const [isloadProfil, loadProfil] = useState(false);

  


  const resultatInteretPro = (idLetter) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInteretProfessionnel.php`, {
        idLetter: idLetter,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          console.log(res.data)
          if (res.data.success === true) {
           // console.log('trouve');
            //console.log(res.data.query);

            //console.log(res.data.interetProData);
            setInteretProfessionnel(res.data.interetProData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const loadId = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectUser.php`, {
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            //console.log('true')
            loadProfil(true);//A remetre
           // loadProfil(false);
          }
          else {
            //console.log('false')
            loadProfil(false);
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const resultatInteretPro2 = (idLetter) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInteretProfessionnel.php`, {
        idLetter: idLetter,
        session_id: sessionStorage.getItem("session_id"),
        id_ind: sessionStorage.getItem("id_ind"),
      })
        .then(res => {
          if (res.data.success === true) {
            setInteretProfessionnel2(res.data.interetProData);
          }
          else {
            console.log('Erreur');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  

  useEffect(() => {
    sortNumbers();
    loadId();
    checKSelection();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader_}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardSignup}>
                <NavPills
                  alignCenter
                  color="rose"
                  tabs={[
                    {
                      tabButton: "VOTRE PROFIL"
                    }
                  ]}
                />

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Vous êtes de type : {firstIndex.qualite}
 
                        </h4>
                        <SnackbarContent
                          style={{ backgroundColor: "#e91e63" }}
                          message=
                          {firstIndex.letter === "R" ? ("Réaliste : il est débrouillard et pragmatique. Le réaliste aime le concret, le terrain."
                          ) : firstIndex.letter === "I" ? ("Investigateur : c'est un intellectuel, il est curieux et analytique. L'investigateur aime résoudre des problèmes, a besoin de comprendre. Il est à l'aise avec les connaissances théoriques."
                          ) : firstIndex.letter === "A" ? ("Artiste : il agit par rapport à ce qu'il ressent, il a de l'intuition. L'artiste aime créer, est non conformiste. Il a besoin d’un environnement de travail différent et apprend par l expérimentation."
                          ) : firstIndex.letter === "S" ? ("Social : il est bienveillant, chaleureux. Il aime les relations et a besoin de communiquer, d'enseigner, d'aider. Le social aime apprendre par le travail d'équipe et la collaboration."
                          ) : firstIndex.letter === "E" ? ("Entrepreneur : il est ambitieux, convaincant. Il aime diriger, vendre, motiver. Il est à l'aise dans un environnement compétitif et apprend en situation."
                          ) : firstIndex.letter === "C" ? ("Conventionnel : il est ordonné, méticuleux. Il est structuré, procédurier, aime le travail de bureau. Le conventionnel a besoin de consignes et de méthodes pour apprendre."
                          ) : (<></>)
                          }
                        />
                      </div>
                      {/*                       <div className={classes.space30} />
 */}
                    </GridItem>
                    
                  </GridContainer>
                  <SectionProfil />
                  <SectionProfilSecondaire />
                  {/* SI FALSE PAS ABONNEE ON L4ABONNE */}

{isloadProfil===false?
  
                   <SectionPricing /> 
                  : <>
                  
                  
                 
                  <div id="topPage" />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      <div className={classes.title}>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Vous êtes de type : {firstIndex.qualite}
                        </h4>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Définissez jusqu'à 5 intérêts professionnels majeurs en fonction de vos affinités avec votre profil principal et secondaire, représentés par vos couleurs et correspondant à votre RIASEC.</p>
                        <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Cochez les cases pour conserver les intérêts selon vos préférences..

                        </p>
                        <div className={classes.title}>
                          <p className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Pour information, la couleur du haut représente votre profil principal, et celle du bas représente votre profil secondaire.</p>
                        </div>
                      </div>
                    </GridItem>
                    </GridContainer>


                   <GridContainer>
                    
                    <GridItem xs={12} sm={12} md={12}
                    >
                     
                      <div className={classes.spacePaddingS} />
                        <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}>{isMessage}</h4>
                        {RecordCount === 5 ? (
                        <>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>- {isMessage1}</h4>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>- {isMessage2}</h4>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>- {isMessage3}</h4>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>- {isMessage4}</h4>
                        <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>- {isMessage5}</h4>   
                        </>
                        ) : (<></>)
                      }                
                    </GridItem>
                   
                  </GridContainer>


                 
                    <GridContainer className={classes.pullRightButton}>
                    <Button round className={classes.buttonPrimary} size="sm"
                      endIcon={<ArrowDownwardIcon />}
                      onClick={() => { smoothScroll('bottomPage') }}
                    >
                    </Button>
                  </GridContainer>
                  
                  <br></br>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.space30} />
                      {numbers.map((number, index) => (
                        <>
                          {index <= 1 ? (<>

                            <SnackbarContent
                              key={index}
                              style={{ backgroundColor: number.color }}
                              message={`Profil ${number.qualite}`}
                            />
                            <div className={classes.space30} />
                            {index === 0 ? (
                              <> <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                {isInteretProfessionnel.map((item) => (
                                    <div
                                      className={
                                        classes.checkboxAndRadio +
                                        " " +
                                        classes.checkboxAndRadioHorizontal
                                      } key={item.idIpf}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            tabIndex={-1}
                                            onClick={() => handleToggle(item.idIpf)}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                              checked: classes.checked,
                                              root: classes.checkRoot
                                            }}
                                          />
                                        }
                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                        label={item.texteInteretpro}
                                      />
                                      <div className={classes.spacePaddingS} ></div>
                                    </div>
                                  ))}
                                </GridItem>
                              </GridContainer>
                              </>
                            )
                              : index === 1 ? (
                                <>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                    {isInteretProfessionnel2.map((item2) => (
                                        <div
                                          className={
                                            classes.checkboxAndRadio +
                                            " " +
                                            classes.checkboxAndRadioHorizontal
                                          } key={item2.idIpf}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                tabIndex={-1}
                                                onClick={() => handleToggle(item2.idIpf)}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                  checked: classes.checked,
                                                  root: classes.checkRoot
                                                }}
                                              />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label={item2.texteInteretpro}
                                          />
                                          <div className={classes.spacePaddingS} ></div>
                                        </div>
                                      ))}
                                    </GridItem>
                                  </GridContainer>
                                </>
                              )
                                : (
                                  <></>
                                )
                            }
                          </>) : (<></>)}
                        </>
                      ))}
                    </GridItem>
                  </GridContainer>
             
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}
                    >
                      <div className={classes.spacePaddingS} ><hr></hr></div>
                      <h4 className={classNames(classes.titleBlue, classes.shadowForSmallText)}>Nbr de sélection : {count}</h4>
                     {count === 5 ? (
                        <><Button
                            color="primary"
                            size="sm"
                            round
                            onClick={() => recordAllIntPro()}
                          >
                            J'enregistre ma sélection
                          </Button>
                        </>
                      ) : (
                        <>
                         <h4 className={classNames(classes.titleWarning, classes.shadowForSmallText)}> Sélectionnez 5 propositions qui correspondent à vos intérêts professionnels</h4>
                        </>)
                      }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                    </GridItem>
                  </GridContainer>
                  <GridContainer className={classes.pullRightButton}>
                    <Button round className={classes.buttonPrimary} size="sm"
                      endIcon={<ArrowForwardIconUp />}
                      onClick={() => { smoothScroll('topPage') }}
                    >
                    </Button>
                  </GridContainer>
                  <div id="bottomPage" />
                  </>
} 
                  <div id="ShowIntPro"></div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      BILAN DE COMPTENCES                  </a>
                  </ListItem>

                  {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem> */}
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "} <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tous droits réservés
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};


