
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import ConectPage from "views/ConectPage/ConectPage.js";
import AccountPage from "views/AccountPage/AccountPage.js";
import CataloguePage from "views/CataloguePage/CataloguePage.js";
import LogoutPage from "views/LogoutPage/LogoutPage.js";
//import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import ExportPage from "views/ExportPage/ExportPage.js";
import AddProductPage from "views/AddProductPage/AddProductPage.js";
import UpdateProductPage from "views/AddProductPage/UpdateProductPage.js";
import UpdateProductLocalisation from "views/AddProductPage/UpdateProductLocalisation.js";

import LocalisationPage from "views/LocalisationPage/LocalisationPage.js";

import BilanDeReorientation from "views/BilanPage/BilanDeReorientation.js";
import BilanDeEvolution from "views/BilanPage/BilanDeEvolution.js";
import ValidationDeProjet from "views/BilanPage/ValidationDeProjet.js";

import Riasec from "views/PresentationPage/Sections/SectionRiasecGroup.js";

import InteretProfessionnel from "views/InteretProfessionnelPage/InteretProfessionnel.js";
import ShowInteretProfessionnel from "views/InteretProfessionnelPage/ShowInteretProfessionnel.js";


import CatalogueProfessionnel from "views/InteretProfessionnelPage/CatalogueProfessionnel.js";

import ValeurProfessionnelle from "views/PresentationPage/ValeurProfessionnellePage.js";

import Motivation from "views/PresentationPage/MotivationPage.js";
import Synthese from "views/PresentationPage/SynthesePage";

import CapaciteProfessionnelle from "views/InteretProfessionnelPage/CapaciteProfessionnelle.js";

//import PortefeuilleProfessionnel from "views/PortefeuillePage/PortefeuilleProfessionnel.js"

import SyntheseMetierValeurMotivation from "views/PortefeuillePage/SyntheseMetierValeurMotivation.js";


import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";
import Videos from "views/PresentationPage/VideosPage.js";
import SyntheseGlobale from "views/InteretProfessionnelPage/SyntheseProfessionnel.js";

import FormationProfessionnelle from "views/InteretProfessionnelPage/FormationProfessionnelle.js";
import Financement from "views/InteretProfessionnelPage/Financement.js";
import PlanAction from "views/InteretProfessionnelPage/PlanAction.js";
import Conclusion from "views/InteretProfessionnelPage/Conclusion.js";




const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/components" component={ComponentsPage} />
      <Route path="/logout-page" component={LogoutPage} />
      <Route path="/account-page" component={AccountPage} />
      {/* <Route path="/contact-page" component={ContactUsPage} /> */}
      <Route path="/addProduct-page" component={AddProductPage} />
      <Route path="/updateProduct-page" component={UpdateProductPage} />
      <Route path="/updateProductLocalisation-page" component={UpdateProductLocalisation} />

      <Route path="/export-page" component={ExportPage} />
      <Route path="/catalogue-page" component={CataloguePage} />
      <Route path="/localisation-page" component={LocalisationPage} />
      <Route path="/conect-page" component={ConectPage} />

      <Route path="/bilanDeReorientation-page" component={BilanDeReorientation} />
      <Route path="/bilanDeEvolution-page" component={BilanDeEvolution} />
      <Route path="/validationDeProjet-page" component={ValidationDeProjet} />
      <Route path="/riasec-page" component={Riasec} />
      <Route path="/interetProfessionnel-page" component={InteretProfessionnel} />
      <Route path="/showInteretProfessionnel-page" component={ShowInteretProfessionnel} />
      <Route path="/valeurProfessionnelle-page" component={ValeurProfessionnelle} />
      <Route path="/catalogueProfessionnel-page" component={CatalogueProfessionnel} />
      <Route path="/motivation-page" component={Motivation} />
      <Route path="/synthese-page" component={Synthese} />
      <Route path="/capaciteProfessionnelle-page" component={CapaciteProfessionnelle} />

{/*       <Route path="/portefeuilleProfessionnel-page" component={PortefeuilleProfessionnel} />
 */}      
      <Route path="/syntheseMetierValeurMotivation-page" component={SyntheseMetierValeurMotivation} />
      <Route path="/video-page" component={Videos} />
      
      <Route path="/syntheseGlobale-page" component={SyntheseGlobale} />
      <Route path="/formationProfessionnelle-page" component={FormationProfessionnelle} />
      <Route path="/financement-page" component={Financement} />
      <Route path="/planAction-page" component={PlanAction} />

      <Route path="/Conclusion-page" component={Conclusion} />


      <Route path="/" component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
