/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons

import Assignment from "@material-ui/icons/Assignment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import GetAppIcon from '@material-ui/icons/GetApp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoomIcon from '@material-ui/icons/Room';

// core components

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const classes = useStyles();
  //let activeClassName = "underline";
  if (sessionStorage.getItem("quality") === '_AMB_') {
    return (
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          <Link to="/conect-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            CONNEXION
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/interetProfessionnel-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            Resultat riasec
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/catalogueProfessionnel-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            Catalogue Professionnel
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
                  <Link to="/components" className={classes.dropdownLink}>
              All components
            </Link>          
           </ListItem> 
         {/*   <ListItem className={classes.listItem}>
      <Link to="/video-page" className={classes.navLink}>
      <AccountCircle className={classes.dropdownIcons} />     
               VIDEOS
                  </Link>
                  </ListItem>  */} 
                 <ListItem className={classes.listItem}>
      <Link to="/syntheseGlobale-page" className={classes.navLink}>
      <AccountCircle className={classes.dropdownIcons} />     
      Synthese
                  </Link>
                  </ListItem>
                  <ListItem className={classes.listItem}>
      <Link to="/formationProfessionnelle-page" className={classes.navLink}>
      <AccountCircle className={classes.dropdownIcons} />     
      Formation
                  </Link>
                  </ListItem>
                  <ListItem className={classes.listItem}>
      <Link to="/financement-page" className={classes.navLink}>
      <AccountCircle className={classes.dropdownIcons} />     
      Financement
                  </Link>
                  </ListItem>
                  <ListItem className={classes.listItem}>
      <Link to="/Conclusion-page" className={classes.navLink}>
      <AccountCircle className={classes.dropdownIcons} />     
      Conclusion
                  </Link>
                  </ListItem>  
      </List>
    );
  }
  else if (sessionStorage.getItem("quality") === 'CLIENT') {
    return (
      <List className={classes.list + " " + classes.mlAuto}>
 <ListItem className={classes.listItem}>
          <Link to="/interetProfessionnel-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
           1-Intérêts Professionnels
          </Link>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Link to="/catalogueProfessionnel-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
           2-Catalogue Professionnel
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/synthese-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            3-Synthese
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/capaciteProfessionnelle-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            4-Compétences
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/formationProfessionnelle-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            5-Formation Professionnelle
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/planAction-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            6-Plan d'Action
          </Link>
        </ListItem>
      </List>
    )
  }
  else {
    return (
      <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
      <Link to="/conect-page" className={classes.navLink}>
          <AccountCircle className={classes.dropdownIcons} />
          CONNEXION
        </Link>
      </ListItem>
     {/*  <ListItem className={classes.listItem}>
          <Link to="/interetProfessionnel-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            Resultat riasec
          </Link>
        </ListItem> */}
    </List>
    )
  }
}


HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white"
  ])
};
